import { Box, CircularProgress, SxProps, Typography } from '@mui/material';
import { FunctionComponent } from 'react';

const loadingBoxSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  height: '180px',
};

const loaderTextSx: SxProps = {
  marginLeft: '0.875rem',
  fontWeight: 400,
  fontSize: '20px',
};

const Loading: FunctionComponent = () => {
  return (
    <Box sx={loadingBoxSx}>
      <CircularProgress size="7rem" sx={{ marginBottom: '40px' }} />
      <Typography sx={loaderTextSx} variant="h6">
        J+ Bidding is loading ...
      </Typography>
    </Box>
  );
};

export default Loading;
