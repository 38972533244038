import {
  Box,
  Button,
  Chip,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { CustomTab, StackSx } from '../../styles/elements';
import { BreadCrumb } from '../../components/BreadCrumb';
import { META_ADSETS_COLUMNS, META_CAMPAIGN_LIST_COLUMNS } from './CampaignListConfig';
import { DataTable } from './components/DataTable.tsx';
import { DataGridProProps, GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import React, { useEffect } from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useParams } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import { useMetaStore } from '../../../../core/meta/store/meta-store.ts';
import { AdSet, Campaign, CampaignObjective } from '../../../../core/meta/model/campaign.ts';
import {
  BidFormStatusType,
  useBidAdjustmentStore,
} from '../../../../core/meta/store/bid-adjustment-store.ts';
import { BidAdjustmentContainer } from './BidAdjustment/BidAdjustmentContainer.tsx';
import { TemplateModal } from './BidAdjustment/templates/TemplateModal.tsx';
import INJECTION_TOKENS from '../../../../core/generic/ioc/injection-tokens.ts';
import { AnalyticsPort } from '../../../../core/meta/port/analytics.port.ts';
import { useInjection } from '../../ioc/use-injection.ts';
import { ACCOUNTS_WHITE_LIST } from './hooks/accountsWhiteList.ts';

const StackStyle = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const MetaCampaignListPage = () => {
  const { clientId, accountId } = useParams();
  const client = useMetaStore((state) => state.clients.find((c) => c?.id === clientId));
  const account = client?.accounts?.find((a) => a?.id === accountId);
  const countSelectedAdsetsPerCampaign = useMetaStore(
    (state) => state.countSelectedAdsetsPerCampaign
  );
  const setSelectedAdsetsPerCampaign = useMetaStore((state) => state.setSelectedAdsetsPerCampaign);
  const clearSelectedAdsets = useMetaStore((state) => state.clearSelectedAdsets);
  const selectedAdsetsPerCampaign = useMetaStore((state) => state.selectedAdsetsPerCampaign);
  const campaigns = useMetaStore((state) => state.campaigns);
  const setCampaigns = useMetaStore((state) => state.setCampaigns);
  const setAudiences = useMetaStore((state) => state.setAudiences);
  const isCampaignsLoading = useMetaStore((state) => state.isCampaignsLoading);

  const isDrawerOpen = useBidAdjustmentStore((state) => state.isDrawerOpen);
  const closeDrawer = useBidAdjustmentStore((state) => state.closeDrawer);
  const isModalOpen = useBidAdjustmentStore((state) => state.isModalOpen);
  const openModal = useBidAdjustmentStore((state) => state.openModal);

  const adSets = useMetaStore((state) => state.adSets);
  const selectedAdsets = useMetaStore((state) => state.selectedAdsets);
  const countSelectedAdsets = useMetaStore((state) => state.countSelectedAdsets);
  const setSelectedAdsets = useMetaStore((state) => state.setSelectedAdsets);
  const setBidFormStatus = useBidAdjustmentStore((state) => state.setBidFormStatus);
  const setSnackBarProps = useBidAdjustmentStore((state) => state.setSnackBarProps);

  const analytics = useInjection<AnalyticsPort>(INJECTION_TOKENS.ANALYTICS_REPOSITORY);

  useEffect(() => {
    clearSelectedAdsets();
    try {
      setCampaigns(accountId ?? '');
    } catch (e) {
      setSnackBarProps({
        messages: [e as string],
        severity: 'error',
        visible: true,
      });
    }

    try {
      setAudiences(accountId ?? '');
    } catch (e) {
      setSnackBarProps({
        messages: [e as string],
        severity: 'error',
        visible: true,
      });
    }
  }, [clientId, accountId, setCampaigns, setAudiences, setSnackBarProps, clearSelectedAdsets]);

  const getDetailPanelContent = React.useCallback<
    NonNullable<DataGridProProps['getDetailPanelContent']>
  >(
    ({ row }) => {
      const onRowSelectionModelChange = (item: GridRowSelectionModel) => {
        setSelectedAdsetsPerCampaign(item as string[], row.id as string);
      };

      const isRowSelectable = (params: GridRowParams) =>
        params?.row?.campaign_objective !== CampaignObjective.OUTCOME_SALES ||
        (params?.row?.campaign_objective === CampaignObjective.OUTCOME_SALES &&
          ACCOUNTS_WHITE_LIST.includes(accountId ?? ''));

      return (
        <Stack sx={{ marginLeft: '100px' }}>
          <DataTable
            rows={row.adsets}
            columns={META_ADSETS_COLUMNS}
            checkboxSelection={true}
            isRowSelectable={isRowSelectable}
            onRowSelectionModelChange={onRowSelectionModelChange}
            rowSelectionModel={selectedAdsetsPerCampaign[row.id as string] || []}
            pinnedColumns={{ right: ['actions'] }}
            hasBorder={false}
            hasBidStatusFilter={true}
          />
        </Stack>
      );
    },
    [setSelectedAdsetsPerCampaign, selectedAdsetsPerCampaign, accountId]
  );

  const getDetailPanelHeight = React.useCallback(() => 'auto', []);

  const [value, setValue] = React.useState('1');

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const onRowSelectionModelChange = (item: GridRowSelectionModel) => {
    setSelectedAdsets(item as string[]);
  };

  const createBidMultipliers = () => {
    analytics.send({ name: 'create_bid', payload: {} });
    setBidFormStatus(BidFormStatusType.CREATE);
    openModal();
  };

  const isRowSelectable = (params: GridRowParams) =>
    params?.row?.campaign_objective !== CampaignObjective.OUTCOME_SALES ||
    (params?.row?.campaign_objective === CampaignObjective.OUTCOME_SALES &&
      ACCOUNTS_WHITE_LIST.includes(accountId ?? ''));

  return (
    <div>
      <BreadCrumb path={`/meta`} title="All clients"></BreadCrumb>
      <h2 style={{ margin: '0', display: 'flex', alignItems: 'center' }}>
        <img
          key="meta-all-campaigns"
          src="/assets/icons/meta.svg"
          alt="meta icon"
          width={'35px'}
          height={'35px'}
          style={{ margin: '8px' }}
        />
        {client?.name}{' '}
        <Box sx={{ padding: '0 8px', fontSize: '16px', color: 'rgba(0,0,0,0.12)' }}>|</Box>{' '}
        {account?.name}
        {ACCOUNTS_WHITE_LIST.includes(accountId ?? '') && (
          <Chip
            label="Bid Adjustment on ASC activated"
            variant="outlined"
            color={'success'}
            sx={{ marginRight: '5px', marginLeft: '5px', marginTop: '5px' }}
          ></Chip>
        )}
      </h2>

      <Stack
        spacing={2}
        direction="column"
        sx={{
          ...StackSx,
          backgroundColor: 'white',
          border: '1px solid rgba(224, 224, 224, 1)',
        }}
      >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <CustomTab
                label={`Campaigns (${accountId && campaigns[accountId] ? campaigns[accountId].length : 0})`}
                value="1"
              />
              <CustomTab
                label={`AdSets (${accountId && adSets[accountId] ? adSets[accountId].length : 0})`}
                value="2"
              />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ padding: 0 }}>
            {!!countSelectedAdsetsPerCampaign && (
              <StackStyle color="primary" padding={1} direction="row" alignItems={'center'}>
                <Typography sx={{ padding: '4px 0 4px 8px' }} variant="h6">
                  {countSelectedAdsetsPerCampaign} Selected
                </Typography>
                <Divider color="white" orientation="vertical" flexItem sx={{ margin: '0 10px' }} />
                <Button variant="contained" onClick={createBidMultipliers}>
                  Create Bid Multipliers
                </Button>
                <div style={{ flex: 1 }}></div>
                <Divider color="white" orientation="vertical" flexItem sx={{ margin: '0 10px' }} />
                <IconButton color="inherit" aria-label="delete" onClick={clearSelectedAdsets}>
                  <ClearIcon />
                </IconButton>
              </StackStyle>
            )}
            <DataTable<Campaign>
              rows={accountId && campaigns[accountId] ? campaigns[accountId] : []}
              columns={META_CAMPAIGN_LIST_COLUMNS}
              getDetailPanelContent={getDetailPanelContent}
              getDetailPanelHeight={getDetailPanelHeight}
              loading={isCampaignsLoading}
              hasBorder={false}
              hasBidStatusFilter={true}
              hasBidReviewFilter={true}
            ></DataTable>
          </TabPanel>
          <TabPanel value="2" sx={{ padding: 0 }}>
            {!!countSelectedAdsets && (
              <StackStyle color="primary" padding={1} direction="row" alignItems={'center'}>
                <Typography sx={{ padding: '4px 0' }} variant="h6">
                  {countSelectedAdsets} Selected
                </Typography>
                <Divider color="white" orientation="vertical" flexItem sx={{ margin: '0 10px' }} />
                <Button variant="contained" onClick={createBidMultipliers}>
                  Create Bid Multipliers
                </Button>
                <div style={{ flex: 1 }}></div>
                <Divider color="white" orientation="vertical" flexItem sx={{ margin: '0 10px' }} />
                <IconButton color="inherit" aria-label="delete" onClick={clearSelectedAdsets}>
                  <ClearIcon />
                </IconButton>
              </StackStyle>
            )}
            <DataTable<AdSet>
              hasBorder={false}
              rows={accountId && adSets[accountId] ? adSets[accountId] : []}
              columns={META_ADSETS_COLUMNS}
              checkboxSelection={true}
              isRowSelectable={isRowSelectable}
              onRowSelectionModelChange={onRowSelectionModelChange}
              getDetailPanelHeight={getDetailPanelHeight}
              rowSelectionModel={selectedAdsets || []}
              pinnedColumns={{ right: ['actions'] }}
              hasBorderRadius={false}
              hasBidStatusFilter={true}
            />
          </TabPanel>
        </TabContext>
      </Stack>
      <Drawer anchor="bottom" open={isDrawerOpen} onClose={closeDrawer}>
        <BidAdjustmentContainer></BidAdjustmentContainer>
      </Drawer>
      <TemplateModal isModalOpen={isModalOpen} />
    </div>
  );
};

export default MetaCampaignListPage;
