import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from '@mui/material';
import { useCustomBidStore } from '../../../../../core/dv360/store/custom-bid-store.ts';
import INJECTION_TOKENS from '../../../../../core/generic/ioc/injection-tokens.ts';
import { AnalyticsPort } from '../../../../../core/meta/port/analytics.port.ts';
import { useInjection } from '../../../ioc/use-injection.ts';

export const ModifyAlgoButton = ({
  advertiserId,
  algorithmId,
}: {
  advertiserId: string;
  algorithmId: string;
}) => {
  const analytics = useInjection<AnalyticsPort>(INJECTION_TOKENS.ANALYTICS_REPOSITORY);
  const openModificationAlgoDrawer = useCustomBidStore((state) => state.openModificationAlgoDrawer);
  const setAdvertiserId = useCustomBidStore((state) => state.setAdvertiserId);
  const setAlgorithmId = useCustomBidStore((state) => state.setAlgorithmId);

  const handleClick = async () => {
    analytics.send({
      name: 'modify_algorithm',
      payload: { advertiserId, algorithmId },
    });
    setAdvertiserId(advertiserId);
    setAlgorithmId(algorithmId);
    openModificationAlgoDrawer();
  };

  return (
    <Button
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        handleClick();
      }}
      endIcon={<ArrowForwardIcon fontSize="small" />}
    >
      Modify algorithm
    </Button>
  );
};
