import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import INJECTION_TOKENS from '../../../../../core/generic/ioc/injection-tokens.ts';
import { AnalyticsPort } from '../../../../../core/meta/port/analytics.port.ts';
import { useInjection } from '../../../ioc/use-injection.ts';

export const SelectPartnerButton = ({
  clientId,
  partnerId,
}: {
  clientId: string;
  partnerId: string;
}) => {
  const analytics = useInjection<AnalyticsPort>(INJECTION_TOKENS.ANALYTICS_REPOSITORY);
  const navigation = useNavigate();
  const navigate = (path: string) => {
    analytics.send({
      name: 'select_partner',
      payload: { partner_id: partnerId, client_id: clientId },
    });
    navigation(path);
  };
  return (
    <Button
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        navigate(`/dv360/${clientId}/partners/${partnerId}/advertisers`);
      }}
    >
      Select Partner
    </Button>
  );
};
