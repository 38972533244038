import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import { ButtonSx } from '../styles/elements';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const BreadCrumb = ({ path, title }: { path: string; title: string }) => {
  const navigation = useNavigate();
  const navigate = (path: string) => {
    navigation(path);
  };

  return (
    <Button
      onClick={() => {
        navigate(path);
      }}
      sx={{ ...ButtonSx, fontSize: '12px' }}
    >
      <ArrowBackIcon sx={{ mr: 0.5 }} fontSize="inherit" />
      {title}
    </Button>
  );
};
