import { inject, injectable } from 'inversify';
import INJECTION_TOKENS from '../../generic/ioc/injection-tokens.ts';
import type { LocationPort } from '../port/location.port.ts';
import { Country } from '../model/location.ts';

@injectable()
export class GetCountriesUseCase {
  constructor(
    @inject(INJECTION_TOKENS.LOCATION_REPOSITORY)
    private readonly locationRepository: LocationPort
  ) {}

  async execute(): Promise<Country[]> {
    return this.locationRepository.getCountries();
  }
}
