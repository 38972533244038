import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import { bidAdjustmentFieldStyle } from './styles';
import { NumberStep } from '../components/NumberStep';
import { TravelStartDateValues, FormProps } from '../../../../../../core/meta/model/bid-form.ts';
import { useMetaStore } from '../../../../../../core/meta/store/meta-store.ts';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { SelectedAdSetIdsWarning } from '../components/SelectedAdSetIdsWarning.tsx';
import usePromotedObject from './hooks/usePromotedObject.ts';

export const TravelStartDateForm: React.FC<FormProps<TravelStartDateValues>> = ({
  onChange,
  initialValues,
  disabled,
}) => {
  const adSetsSelected = useMetaStore((state) => state.adSetsSelected);
  const promotedObjectToDisplay = useMetaStore((state) => state.promotedObjectToDisplay);
  const totalCountSelectedAdsets = useMetaStore((state) => state.totalCountSelectedAdsets);

  const [travelStartDateValues, setTravelStartDateValues] = useState<TravelStartDateValues>(
    initialValues || { customEvents: [], options: [], value: 1 }
  );

  usePromotedObject();

  const handleChange = (
    e:
      | SelectChangeEvent<
          typeof travelStartDateValues.customEvents | typeof travelStartDateValues.options
        >
      | ChangeEvent<HTMLInputElement>
  ) => {
    const newValue =
      e.target instanceof HTMLInputElement ? parseFloat(e.target.value) : e.target.value;
    const newFormValue = { ...travelStartDateValues, [e.target.name]: newValue };
    setTravelStartDateValues(newFormValue);
    onChange(newFormValue);
  };

  const handleDateChange = (dateRange: DateRange<Dayjs>) => {
    const start = dayjs(dateRange[0]).format('YYYYMMDD').toString();
    const end = dayjs(dateRange[1]).format('YYYYMMDD').toString();
    if (start && end) {
      const dateRange = `${start}-${end}`;
      const newValue = { ...travelStartDateValues, options: [dateRange] };
      setTravelStartDateValues(newValue);
      onChange(newValue);
    }
  };

  const getDateRange = () => {
    if (travelStartDateValues.options.length) {
      const [start, end] = travelStartDateValues.options[0].split('-');
      return [dayjs(start) as Dayjs, dayjs(end) as Dayjs] as DateRange<Dayjs>;
    }
  };

  return (
    <>
      {!adSetsSelected?.promoted_object || totalCountSelectedAdsets > 1 ? (
        <SelectedAdSetIdsWarning totalCountSelectedAdsets={totalCountSelectedAdsets} />
      ) : (
        <>
          <FormControl sx={bidAdjustmentFieldStyle}>
            <InputLabel id="custom-event-label">Custom event</InputLabel>
            <Select
              labelId="custom-event-label"
              id="custom-event-select"
              name="customEvents"
              value={travelStartDateValues.customEvents}
              label="Custom event"
              multiple
              renderValue={(selected) => selected.join(', ')}
              onChange={handleChange}
            >
              <MenuItem value={promotedObjectToDisplay?.value}>
                <ListItemText primary={promotedObjectToDisplay?.valueToDisplay} />
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={bidAdjustmentFieldStyle}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker value={getDateRange()} onChange={handleDateChange} />
            </LocalizationProvider>
          </FormControl>
          <NumberStep
            disabled={disabled}
            name="value"
            value={travelStartDateValues.value}
            onChange={handleChange}
          ></NumberStep>
        </>
      )}
    </>
  );
};
