import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Box, SxProps } from '@mui/system';
import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AlgorithmFormValues } from '../../../../../core/dv360/model/bid-form.ts';
import { ALGORITHM_STRATEGY } from '../../../../../core/dv360/model/constant.ts';
import { useChangelogStore } from '../../../../../core/dv360/store/changelog-store.ts';
import { useCustomBidStore } from '../../../../../core/dv360/store/custom-bid-store.ts';
import { buildKeyPerClientPartner } from '../../../../../core/dv360/store/dv360-store.helper.ts';
import { useDV360Store } from '../../../../../core/dv360/store/dv360-store.ts';
import { useScriptStore } from '../../../../../core/dv360/store/script-store.ts';
import { AlgorithmScriptEditor } from './AlgorithmScriptEditor.tsx';
import { AlgorithmTopBar } from './AlgorithmTopBar.tsx';
import { BiddingScriptDialog } from './bidding-script/BiddingScriptDialog.tsx';
import { ChangelogDialog } from './changelog/ChangelogDialog.tsx';

const boxContainerSx: SxProps = {
  backgroundColor: 'rgb(250, 250, 250);',
};

const pageContainerSx: SxProps = {
  width: '100%',
  padding: '20px 40px',
  height: 'calc(100vh - 166px)',
};

const paperContainerSx: SxProps = {
  borderRadius: '8px',
  overflow: 'hidden',
};

const subContainerSx: SxProps = {
  minHeight: '400px',
  height: '100%',
  position: 'relative',
  overflow: 'hidden',
};

const contentTitleSx: SxProps = { margin: '10', display: 'flex', alignItems: 'center' };

const creationButtonSx: SxProps = {
  width: '209px',
};

const stackHeader: SxProps = {
  padding: '16px',
  height: '86px',
  minHeight: '86px',
  borderBottom: '1px solid',
  borderTopLeftRadius: '8px',
  borderColor: '#e9e9e9',
};

const formContainerSx: SxProps = {
  height: '80px',
  minHeight: '80px',
  maxHeight: '80px',
  borderBottom: '1px solid',
  borderColor: '#e9e9e9',
  padding: '0 16px',
};

const formInputContainerSx: SxProps = {
  marginTop: '16px',
};

const formInputSx: SxProps = {
  width: '100%',
};

const formHelperTextSx: SxProps = {
  marginLeft: '20px',
};

const LabelNameFieldSx: SxProps = {
  width: '180px',
  minWidth: '180px',
};

const LabelTypeFieldSx: SxProps = {
  width: '180px',
  minWidth: '180px',
  textAlign: 'end',
};

type CustomBidContainerProps = {
  clientId: string;
  clientName: string;
  partnerId: string;
  partnerName: string;
};

type OnChangeNameController = (value: string) => void;
type OnChangeTypeController = (value: string) => void;

export const AlgorithmContainer = ({
  clientId,
  clientName,
  partnerId,
  partnerName,
}: CustomBidContainerProps) => {
  // STORE CUSTOM BIDDING
  const advertiserIosPerClientPartner = useDV360Store((state) => state.iosPerAdvertiser);
  const advertiserFloodlightsPerClientPartner = useDV360Store(
    (state) => state.floodlightsPerAdvertiser
  );
  const advertiserId = useCustomBidStore((state) => state.advertiserId);
  const algorithmId = useCustomBidStore((state) => state.algorithmId);
  const isCreationAlgoReadyToSend = useCustomBidStore((state) => state.isCreationAlgoReadyToSend);
  const isInitEditAlgorithmInProgress = useCustomBidStore(
    (state) => state.isInitEditAlgorithmInProgress
  );
  const isModificationAlgoReadyToSend = useCustomBidStore(
    (state) => state.isModificationAlgoReadyToSend
  );
  const isInCreationAlgorithmMode = useCustomBidStore((state) => state.isInCreationAlgorithmMode);
  const isDrawerOpen = useCustomBidStore((state) => state.isDrawerOpen);
  const setNewAlgorithmName = useCustomBidStore((state) => state.setNewAlgorithmName);
  const setNewAlgorithmType = useCustomBidStore((state) => state.setNewAlgorithmType);
  const setDefaultScriptValue = useCustomBidStore((state) => state.setDefaultScriptValue);
  const setScriptValue = useCustomBidStore((state) => state.setScriptValue);
  const createAlgorithm = useCustomBidStore((state) => state.createAlgorithm);
  const closeDrawer = useCustomBidStore((state) => state.closeDrawer);
  const modifyAlgorithm = useCustomBidStore((state) => state.modifyAlgorithm);
  const resetEditAlgorithmValues = useCustomBidStore((state) => state.resetEditAlgorithmValues);
  const initModificationAlgoFormDefaultValues = useCustomBidStore(
    (state) => state.initModificationAlgoFormDefaultValues
  );
  const setAdvertiserId = useCustomBidStore((state) => state.setAdvertiserId);
  const setAlgorithmId = useCustomBidStore((state) => state.setAlgorithmId);
  const setAdvertiserIos = useCustomBidStore((state) => state.setAdvertiserIos);
  const setAdvertiserFloodlights = useCustomBidStore((state) => state.setAdvertiserFloodlights);

  // STORE CHANGELOG
  const isChangelogFromAlgoEditionOpen = useChangelogStore(
    (state) => state.isChangelogFromAlgoEditionOpen
  );
  const closeChangelogDialogFromAlgoEdition = useChangelogStore(
    (state) => state.closeChangelogDialogFromAlgoEdition
  );
  const resetChangelog = useChangelogStore((state) => state.resetChangelog);
  const openChangelogDialogFromAlgoEdition = useChangelogStore(
    (state) => state.openChangelogDialogFromAlgoEdition
  );

  // STORE SCRIPT
  const hideConversionWeightPreview = useScriptStore((state) => state.hideScriptPreview);
  const resetScriptValues = useScriptStore((state) => state.resetValues);
  const setFloodlightAutoSelectValues = useScriptStore(
    (state) => state.setFloodlightAutoSelectValues
  );

  // LOCAL STATE
  const [openWeightAlgoDialog, setOpenWeightAlgoDialog] = useState<boolean>(false);
  const [isDataInitialized, setIsDataInitialized] = useState<boolean>(false);

  // FORM
  const { control, formState, trigger, reset, watch, setValue } = useForm<AlgorithmFormValues>({
    mode: 'onChange',
  });
  const { errors } = formState;
  const watchName = watch('name');
  const watchType = watch('type');

  // EVENT METHODS
  const handleOpenWeightAlgoDialog = () => {
    hideConversionWeightPreview();
    setOpenWeightAlgoDialog(true);
  };

  const handleCloseWeightAlgoDialog = () => {
    setOpenWeightAlgoDialog(false);
    resetScriptValues();
  };

  const handleNameOnChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    onChange: OnChangeNameController
  ) => {
    const value = e.target.value;
    setNewAlgorithmName(e.target.value);
    onChange(value);
  };

  const handleTypeOnChange = (e: SelectChangeEvent<string>, onChange: OnChangeTypeController) => {
    const value = e.target.value;
    setNewAlgorithmType(value);
    onChange(value);
  };

  const onSuccessEdition = () => {
    closeDrawer();
    setDefaultScriptValue();
    resetEditAlgorithmValues();
  };

  const handleCreation = async () => {
    if (!isCreationAlgoReadyToSend) {
      trigger(); // Triggers form validation : name & type
    } else {
      const success = await createAlgorithm();
      if (success) {
        onSuccessEdition();
      }
    }
  };

  const handleModification = async () => {
    if (isModificationAlgoReadyToSend) {
      const success = await modifyAlgorithm();
      if (success) {
        onSuccessEdition();
      }
    }
  };

  const handleClickCancelButton = () => {
    reset();
    setDefaultScriptValue();
  };

  const openHistoryDialog = () => {
    resetChangelog();
    setAdvertiserId(advertiserId);
    setAlgorithmId(algorithmId);
    openChangelogDialogFromAlgoEdition();
  };

  const handleCloseChangelogDialog = () => {
    closeChangelogDialogFromAlgoEdition();
    resetChangelog();
  };

  useEffect(() => {
    const key = buildKeyPerClientPartner(clientId, partnerId);

    // Extract and store ios from clientId, partnerId and advertiserId
    const ios = advertiserIosPerClientPartner[key]?.[advertiserId] ?? [];
    setAdvertiserIos(ios);

    // Extract and store floodlights from clientId, partnerId and advertiserId
    const floodlights = advertiserFloodlightsPerClientPartner[key]?.[advertiserId] ?? [];
    setAdvertiserFloodlights(floodlights);
    setFloodlightAutoSelectValues(floodlights);

    const asyncInitData = async () => {
      const { name, type, script } = await initModificationAlgoFormDefaultValues(
        clientId,
        partnerId,
        advertiserId,
        algorithmId
      );
      setValue('name', name);
      setValue('type', type);
      setScriptValue(script);
      setIsDataInitialized(true);
    };

    if (!isInCreationAlgorithmMode && isDrawerOpen && !isDataInitialized) {
      asyncInitData();
    }
  }, [
    clientId,
    partnerId,
    advertiserId,
    algorithmId,
    advertiserIosPerClientPartner,
    advertiserFloodlightsPerClientPartner,
    setAdvertiserIos,
    setAdvertiserFloodlights,
    isInCreationAlgorithmMode,
    initModificationAlgoFormDefaultValues,
    setFloodlightAutoSelectValues,
    isDrawerOpen,
    setScriptValue,
    setValue,
    isDataInitialized,
    setIsDataInitialized,
  ]);

  return (
    <>
      <AlgorithmTopBar
        clientName={clientName}
        partnerName={partnerName}
        onClickSendButton={isInCreationAlgorithmMode ? handleCreation : handleModification}
        onClickCancelButton={handleClickCancelButton}
      ></AlgorithmTopBar>
      <Box
        flexDirection="row"
        flex="1"
        display="flex"
        sx={boxContainerSx}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Stack direction="column" alignItems="stretch" justifyContent="center" sx={pageContainerSx}>
          <Paper variant="outlined" sx={paperContainerSx}>
            <Stack sx={subContainerSx}>
              <Stack
                direction="row"
                alignItems="left"
                justifyContent="space-between"
                sx={stackHeader}
              >
                <div className={'custom-bid-header-squircle-left'} />
                <Stack
                  direction="column"
                  alignItems="left"
                  justifyContent="center"
                  sx={{ zIndex: 1 }}
                >
                  <Typography fontWeight={500} fontSize={20} sx={contentTitleSx}>
                    {isInCreationAlgorithmMode
                      ? 'Create your bidding script'
                      : 'Modify your bidding script'}
                    <InfoIcon sx={{ marginLeft: '5px' }} fontSize="small"></InfoIcon>
                  </Typography>
                  <Typography fontWeight={500} fontSize={14} variant="caption">
                    Use the blank space provided to craft a script that aligns with your specific
                    strategy and goals.{' '}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'right',
                    flex: 1,
                  }}
                >
                  <Button
                    sx={creationButtonSx}
                    onClick={handleOpenWeightAlgoDialog}
                    color="primary"
                    variant="contained"
                    endIcon={<AutoAwesomeIcon fontSize="small" />}
                    disabled={isInitEditAlgorithmInProgress}
                  >
                    Generate script
                  </Button>
                  {!isInCreationAlgorithmMode && (
                    <Button
                      sx={creationButtonSx}
                      onClick={(e) => {
                        e.stopPropagation();
                        openHistoryDialog();
                      }}
                      color="primary"
                      variant="outlined"
                      endIcon={<VisibilityIcon fontSize="small" />}
                      disabled={isInitEditAlgorithmInProgress}
                    >
                      View changelog
                    </Button>
                  )}
                </Stack>
              </Stack>
              <Stack
                direction="row"
                alignItems="start"
                justifyContent="center"
                sx={formContainerSx}
                useFlexGap
                gap="10px"
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-between"
                  flex="1 1 0"
                  sx={formInputContainerSx}
                >
                  <Stack direction="row">
                    <Typography sx={LabelNameFieldSx}>
                      Name your algorithm {isInCreationAlgorithmMode && '*'}:
                    </Typography>
                  </Stack>
                  <Stack flexGrow="1">
                    {isInCreationAlgorithmMode ? (
                      <FormControl required={true}>
                        <Controller
                          name="name"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: 'Name is required.',
                            },
                          }}
                          render={({ field: { onChange, ...field } }) => (
                            <TextField
                              {...field}
                              required
                              disabled={!isInCreationAlgorithmMode}
                              variant="outlined"
                              placeholder="ex. J+Bidding-year-month-strategy"
                              size="small"
                              onChange={(e) => handleNameOnChange(e, onChange)}
                              error={!!errors.name}
                              sx={formInputSx}
                            />
                          )}
                        />
                        <FormHelperText error sx={formHelperTextSx}>
                          {errors.name ? errors.name.message : ''}
                        </FormHelperText>
                      </FormControl>
                    ) : (
                      <Typography color="secondary" variant="body2">
                        {watchName}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={isInCreationAlgorithmMode ? 'flex-end' : 'flex-between'}
                  flex="1 1 0"
                  sx={formInputContainerSx}
                  useFlexGap
                  gap="8px"
                >
                  <Stack direction="row">
                    <Typography sx={LabelTypeFieldSx}>
                      Algorithm type {isInCreationAlgorithmMode && '*'}:
                    </Typography>
                  </Stack>
                  <Stack flexGrow="1">
                    {isInCreationAlgorithmMode ? (
                      <FormControl>
                        <Controller
                          name="type"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: 'Type is required.',
                            },
                          }}
                          render={({ field: { name, onChange, ...field } }) => (
                            <Select
                              {...field}
                              id={name}
                              required
                              disabled={!isInCreationAlgorithmMode}
                              variant="outlined"
                              error={!!errors.type}
                              size="small"
                              sx={formInputSx}
                              onChange={(e) => handleTypeOnChange(e, onChange)}
                            >
                              <MenuItem value=""></MenuItem>
                              {Object.values(ALGORITHM_STRATEGY).map((strategy) => (
                                <MenuItem key={strategy} value={strategy}>
                                  {strategy}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        <FormHelperText error sx={formHelperTextSx}>
                          {errors.type ? errors.type.message : ''}
                        </FormHelperText>
                      </FormControl>
                    ) : (
                      <Typography color="secondary" variant="body2">
                        {watchType}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </Stack>

              {/* SCRIPT INPUT EDITOR */}
              <AlgorithmScriptEditor />

              {/* WEIGHT ALGORITHM DIALOG */}
              <BiddingScriptDialog
                open={openWeightAlgoDialog}
                onClose={handleCloseWeightAlgoDialog}
              />

              {/* CHANGELOG DIALOG */}
              <ChangelogDialog
                open={isChangelogFromAlgoEditionOpen}
                onClose={handleCloseChangelogDialog}
                clientId={clientId ?? ''}
                partnerId={partnerId ?? ''}
                algorithmId={algorithmId}
                advertiserId={advertiserId}
              />
            </Stack>
          </Paper>
        </Stack>
      </Box>
    </>
  );
};
