import { injectable } from 'inversify';

@injectable()
export class InMemoryFileService {
  convertToCsvError = false;

  clearData() {
    this.convertToCsvError = false;
  }

  setConvertToCsvError(error: boolean) {
    this.convertToCsvError = error;
  }

  toCSV() {
    if (this.convertToCsvError) {
      throw new Error('Error occurred during CSV creation');
    }
  }
}
