import { Box, Button, Chip, Paper, Stack, styled, Typography } from '@mui/material';
import { Card } from './template-card-config.ts';
import {
  BidForm,
  useBidAdjustmentStore,
} from '../../../../../../core/meta/store/bid-adjustment-store.ts';
import { PrimaryContrast } from '@design-system/jts-design-system-react/variables/ts/semantic-data';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const CardItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : PrimaryContrast,
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  height: '250px',
  position: 'relative',
  boxShadow: 'none',
  border: '1px solid #E0E0E0',
}));

interface TemplateCardProps {
  card: Card;
}

export const TemplateCard = ({ card }: TemplateCardProps) => {
  const openDrawer = useBidAdjustmentStore((state) => state.openDrawer);
  const closeModal = useBidAdjustmentStore((state) => state.closeModal);

  const resetBidForm = useBidAdjustmentStore((state) => state.resetBidForm);
  const setBidForms = useBidAdjustmentStore((state) => state.setBidForms);
  const nextStep = useBidAdjustmentStore((state) => state.nextStep);
  const setBidMultiplierForm = (forms: BidForm[] | undefined) => {
    resetBidForm();
    closeModal();
    openDrawer();
    if (forms?.length) {
      setBidForms(forms);
      nextStep();
    }
  };

  return (
    <CardItem>
      <Box sx={{ p: 2 }}>
        <Typography variant="h6">{card.name}</Typography>
        <Typography variant="body2">{card.description}</Typography>
        <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1} sx={{ margin: '10px 0' }}>
          {card.chips.map((chip, index) => (
            <Chip key={index} label={chip} />
          ))}
        </Stack>
      </Box>
      <Button
        disabled={card.isDeactivated}
        variant="contained"
        onClick={() => setBidMultiplierForm(card.bidForms)}
        endIcon={<CheckCircleOutlineIcon />}
        sx={{
          width: '100%',
          height: '46px',
          boxShadow: 'none',
          position: 'absolute',
          bottom: 0,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        Select
      </Button>
    </CardItem>
  );
};
