import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import { AnalyticsPort } from '../../../../../core/meta/port/analytics.port.ts';
import INJECTION_TOKENS from '../../../../../core/generic/ioc/injection-tokens';
import { useInjection } from '../../../ioc/use-injection';

export const SelectAccountButton = ({
  clientId,
  accountId,
}: {
  clientId: string;
  accountId: string;
}) => {
  const analytics = useInjection<AnalyticsPort>(INJECTION_TOKENS.ANALYTICS_REPOSITORY);
  const navigation = useNavigate();
  const navigate = (path: string) => {
    navigation(path);
  };
  return (
    <Button
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        analytics.send({
          name: 'select_account',
          payload: { account_id: accountId, client_id: clientId },
        });
        navigate(`/meta/${clientId}/accounts/${accountId}/campaigns`);
      }}
    >
      Select Account
    </Button>
  );
};
