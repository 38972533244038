import './App.css';
import { RouterProvider } from 'react-router-dom';
import { IocProvider } from './adapters/driving/ioc/IocProvider.tsx';
import { router } from './router.tsx';
import { container } from './core/generic/ioc/container.ts';
import ReactGA from 'react-ga4';

import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENCE_KEY ?? '');

const App = () => {
  const gaId = process.env.REACT_GOOGLE_ANALYTIC_ID || '';
  ReactGA.initialize(gaId);
  return (
    <IocProvider container={container}>
      <RouterProvider router={router} />
    </IocProvider>
  );
};

export default App;
