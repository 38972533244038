import { inject, injectable } from 'inversify';
import { Client } from '../../../../core/dv360/model/client.ts';
import { Dv360ClientPort } from '../../../../core/dv360/port/dv360-client.port.ts';
import INJECTION_TOKENS from '../../../../core/generic/ioc/injection-tokens.ts';
import type SimpleHttpService from '../../../../core/generic/simple-http-service.port.ts';

@injectable()
export class Dv360ClientRepository implements Dv360ClientPort {
  constructor(
    @inject(INJECTION_TOKENS.DV360_API_HTTP_SERVICE) private readonly httpService: SimpleHttpService
  ) {}

  getClients(): Promise<Client[]> {
    return this.httpService.get<Client[]>('/v1/clients');
  }
}
