import { injectable } from 'inversify/lib/annotation/injectable';
import { Client } from '../../../../core/dv360/model/client';

@injectable()
export class InMemoryDv360ClientRepository {
  clients: Client[] = [];

  clearData() {
    this.clients = [];
  }

  setClients(clients: Client[]) {
    this.clients = clients;
  }

  async getClients(): Promise<Client[]> {
    return this.clients;
  }
}
