import { injectable } from 'inversify';
import { Audience } from '../../../../core/meta/model/audience.ts';
import { AudiencePort } from '../../../../core/meta/port/audience.port.ts';

@injectable()
export class InMemoryAudienceRepository implements AudiencePort {
  audiences: Audience[] = [];

  setAudiences(audiences: Audience[]): void {
    this.audiences = audiences;
  }
  async getAudiences(): Promise<Audience[]> {
    return this.audiences;
  }
}
