import { inject, injectable } from 'inversify';
import INJECTION_TOKENS from '../../generic/ioc/injection-tokens.ts';
import type { Dv360AlgorithmPort } from '../port/dv360-algorithm.port.ts';
import { CreateAlgorithmCommand } from './create-algorithm.command.ts';

@injectable()
export class CreateAlgorithmUsecase {
  constructor(
    @inject(INJECTION_TOKENS.DV360_ALGORITHM_REPOSITORY)
    private readonly algorithmRepository: Dv360AlgorithmPort
  ) {}

  async execute(command: CreateAlgorithmCommand) {
    const { clientId, partnerId, advertiserId, name, script, type } = command;
    // Creates the algorithm onto DV360
    const algorithm = await this.algorithmRepository.createCustomBiddingAlgorithm(
      clientId,
      partnerId,
      advertiserId,
      name,
      type
    );

    // Pushes the script onto DV360 and links it to the algorithm
    const algorithmId = algorithm?.id ?? '';
    await this.algorithmRepository.linkScriptToAlgorithm(
      clientId,
      partnerId,
      advertiserId,
      algorithmId,
      script
    );
  }
}
