import { Box, Drawer, Stack, Typography } from '@mui/material';
import { DataGridProProps } from '@mui/x-data-grid-pro';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Advertiser } from '../../../../core/dv360/model/advertiser.ts';
import { useChangelogStore } from '../../../../core/dv360/store/changelog-store.ts';
import { useCustomBidStore } from '../../../../core/dv360/store/custom-bid-store.ts';
import { useDV360Store } from '../../../../core/dv360/store/dv360-store.ts';
import { BreadCrumb } from '../../components/BreadCrumb.tsx';
import { StackSx } from '../../styles/elements.tsx';
import { DataTable } from '../components/DataTable.tsx';
import { AlgorithmContainer } from './algorithm/AlgorithmContainer.tsx';
import { ChangelogDialog } from './algorithm/changelog/ChangelogDialog.tsx';
import {
  DV360_ADVERTISER_ALGO_LIST_COLUMNS,
  DV360_ADVERTISER_LIST_COLUMNS,
  DV360_ADVERTISER_LIST_INITIAL_STATE,
} from './DV360AdvertiserListConfig.tsx';

const DV360AdvertiserListPage = () => {
  const { clientId, partnerId } = useParams();

  // STORE DV360
  const advertisersPerPartner = useDV360Store((state) => state.advertisersPerPartner);
  const client = useDV360Store((state) => state.clients.find((client) => client?.id === clientId));
  const partner = client?.partners.find((partner) => partner.id === partnerId);
  const isLoading = useDV360Store((state) => state.isAdvertisersLoading);
  const setAdvertisers = useDV360Store((state) => state.setAdvertisers);
  const setFloodlights = useDV360Store((state) => state.setFloodlights);
  const setInsertionOrders = useDV360Store((state) => state.setInsertionOrders);

  // STORE CUSTOM BID
  const isDrawerOpen = useCustomBidStore((state) => state.isDrawerOpen);
  const closeDrawer = useCustomBidStore((state) => state.closeDrawer);
  const setCustomBidClientId = useCustomBidStore((state) => state.setClientId);
  const setCustomBidPartnerId = useCustomBidStore((state) => state.setPartnerId);
  const advertiserId = useCustomBidStore((state) => state.advertiserId);
  const algorithmId = useCustomBidStore((state) => state.algorithmId);

  // STORE CHANGELOG
  const isChangelogDialogOpen = useChangelogStore((state) => state.isChangelogDialogOpen);
  const closeChangelogDialog = useChangelogStore((state) => state.closeChangelogDialog);
  const resetChangelog = useChangelogStore((state) => state.resetChangelog);

  // EFFECT
  useEffect(() => {
    setAdvertisers(clientId ?? '', partnerId ?? '');
    setFloodlights(clientId ?? '', partnerId ?? '');
    setInsertionOrders(clientId ?? '', partnerId ?? '');
    setCustomBidClientId(clientId ?? '');
    setCustomBidPartnerId(partnerId ?? '');
  }, [
    clientId,
    partnerId,
    setAdvertisers,
    setFloodlights,
    setInsertionOrders,
    setCustomBidClientId,
    setCustomBidPartnerId,
  ]);

  // EVENT METHODS
  const handleCloseChangelogDialog = () => {
    closeChangelogDialog();
    resetChangelog();
  };

  const getDetailPanelContent = React.useCallback<
    NonNullable<DataGridProProps['getDetailPanelContent']>
  >(({ row }) => {
    return row.algorithms.length !== 0 ? (
      <Stack sx={{ marginLeft: '100px' }}>
        <DataTable
          rows={row.algorithms}
          columns={DV360_ADVERTISER_ALGO_LIST_COLUMNS}
          pinnedColumns={{ right: ['actions'] }}
          hasBorder={false}
        />
      </Stack>
    ) : null;
  }, []);

  const getDetailPanelHeight = React.useCallback(() => 'auto', []);

  return (
    <div>
      <BreadCrumb path={`/dv360`} title="All clients"></BreadCrumb>
      <h2 style={{ margin: '0', display: 'flex', alignItems: 'center' }}>
        <img
          key="meta-all-advertisers"
          src="/assets/icons/dv360.svg"
          alt="dv360 icon"
          width={'35px'}
          height={'35px'}
          style={{ margin: '8px' }}
        />
        {client?.name}{' '}
        <Box sx={{ padding: '0 8px', fontSize: '16px', color: 'rgba(0,0,0,0.12)' }}>|</Box>{' '}
        {partner?.name}
        {' -  '}
        Advertisers
      </h2>

      <Typography variant="caption">
        View all algorithms associated with their respective Advertisers.{' '}
      </Typography>

      <Stack spacing={2} direction="row" sx={StackSx}>
        <DataTable<Advertiser>
          initialState={DV360_ADVERTISER_LIST_INITIAL_STATE}
          rows={
            partnerId && advertisersPerPartner[partnerId] ? advertisersPerPartner[partnerId] : []
          }
          columns={DV360_ADVERTISER_LIST_COLUMNS}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
          loading={isLoading}
          pinnedColumns={{ right: ['actions'] }}
          hasBorderRadius={false}
        ></DataTable>
      </Stack>

      <Drawer anchor="bottom" open={isDrawerOpen} onClose={closeDrawer}>
        <AlgorithmContainer
          clientId={client?.id ?? ''}
          clientName={client?.name ?? ''}
          partnerId={partner?.id ?? ''}
          partnerName={partner?.name ?? ''}
        ></AlgorithmContainer>
      </Drawer>

      {/* CHANGELOG DIALOG */}
      <ChangelogDialog
        open={isChangelogDialogOpen}
        onClose={handleCloseChangelogDialog}
        clientId={clientId ?? ''}
        partnerId={partnerId ?? ''}
        algorithmId={algorithmId}
        advertiserId={advertiserId}
      />
    </div>
  );
};

export default DV360AdvertiserListPage;
