import { Box, LinearProgress, SxProps } from '@mui/material';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useChangelogStore } from '../../../core/dv360/store/changelog-store.ts';
import { useCustomBidStore } from '../../../core/dv360/store/custom-bid-store.ts';
import { useDV360Store } from '../../../core/dv360/store/dv360-store.ts';
import { useScriptStore } from '../../../core/dv360/store/script-store.ts';
import { useBidAdjustmentStore } from '../../../core/meta/store/bid-adjustment-store.ts';
import TopBar from '../components/TopBar.tsx';
import GameArea from '../konami/GameArea.tsx';

import useKonamiCode from '../konami/hooks/konami.hook.ts';

const linearProgressSx: SxProps = { width: '100%', display: 'none' };

const boxContainer: SxProps = { display: 'flex', flexDirection: 'row', width: '100%' };

const pageContainer: SxProps = {
  padding: '20px 40px',
  overflowY: 'scroll',
  height: 'calc(100vh - 94px)',
  width: '100%',
};
const Layout = () => {
  const [konami, setKonami] = useState(false);

  useKonamiCode(() => {
    setKonami(!konami);
  });

  const snackBarProps = useBidAdjustmentStore((state) => state.snackBarProps);
  const dv360SnackBarProps = useDV360Store((state) => state.snackBarProps);
  const customBidSnackBarProps = useCustomBidStore((state) => state.snackBarProps);
  const changelogSnackBarProps = useChangelogStore((state) => state.snackBarProps);
  const scriptSnackBarProps = useScriptStore((state) => state.snackBarProps);

  useEffect(() => {
    if (!snackBarProps.visible || !snackBarProps.messages) return;
    for (const message in snackBarProps.messages) {
      enqueueSnackbar(snackBarProps.messages[message], { variant: snackBarProps.severity });
    }
  }, [snackBarProps]);

  useEffect(() => {
    if (!dv360SnackBarProps.visible || !dv360SnackBarProps.messages) return;
    for (const message in dv360SnackBarProps.messages) {
      enqueueSnackbar(dv360SnackBarProps.messages[message], {
        variant: dv360SnackBarProps.severity,
      });
    }
  }, [dv360SnackBarProps]);

  useEffect(() => {
    if (!customBidSnackBarProps.visible || !customBidSnackBarProps.messages) return;
    for (const message in customBidSnackBarProps.messages) {
      enqueueSnackbar(customBidSnackBarProps.messages[message], {
        variant: customBidSnackBarProps.severity,
      });
    }
  }, [customBidSnackBarProps]);

  useEffect(() => {
    if (!changelogSnackBarProps.visible || !changelogSnackBarProps.messages) return;
    for (const message in changelogSnackBarProps.messages) {
      enqueueSnackbar(changelogSnackBarProps.messages[message], {
        variant: changelogSnackBarProps.severity,
      });
    }
  }, [changelogSnackBarProps]);

  useEffect(() => {
    if (!scriptSnackBarProps.visible || !scriptSnackBarProps.messages) return;
    for (const message in scriptSnackBarProps.messages) {
      enqueueSnackbar(scriptSnackBarProps.messages[message], {
        variant: scriptSnackBarProps.severity,
      });
    }
  }, [scriptSnackBarProps]);

  return (
    <>
      <SnackbarProvider
        maxSnack={10}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {konami && <GameArea></GameArea>}
        <Box className="Home">
          <TopBar />
          <LinearProgress sx={linearProgressSx} />
          <Box sx={boxContainer}>
            <Box sx={pageContainer}>
              <Outlet />
            </Box>
          </Box>
        </Box>
      </SnackbarProvider>
    </>
  );
};

export default Layout;
