import { useEffect, useState } from 'react';
import './Game.css';
import Item from './Item';
import { useShotgun } from './hooks/shotgun.hook';
import { dominating, firstBlood, monsterKill, unstoppable } from './audio';

export interface Item {
  id: number;
  alive: boolean;
}

const GameArea = () => {
  const [items, setItems] = useState<Item[]>([]);
  const [counter, setCounter] = useState(0);
  const [paused, setPaused] = useState(false);
  const [health, setHealth] = useState<number>(100); // Initial health

  useShotgun(paused);

  useEffect(() => {
    if (paused) return;
    // Add event lister for KeyG
    document.addEventListener('keydown', (event) => {
      if (event.code === 'KeyG') {
        setItems([]);
      }
    });

    const interval = setInterval(() => {
      const newItems = [...items, { id: Date.now(), alive: true }];
      setItems(newItems);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [setItems, items, paused]);

  const onClick = (item: Item) => {
    item.alive = false;
    setCounter(counter + 1);
    soundsEffect(counter + 1);
  };

  const soundsEffect = (counter: number) => {
    if (counter === 1) firstBlood.play();
    if (counter === 5) dominating.play();
    if (counter === 10) monsterKill.play();
    if (counter === 20) unstoppable.play();
  };

  const handlePause = () => {
    setPaused(!paused);
  };

  const handleSetHealth = (item: Item) => {
    item.alive = false;
    setHealth((prevHealth) => Math.max(prevHealth - 10, 0));
    if (health === 0) setPaused(true);
  };

  return (
    <div className={`custom-cursor game-area ${paused ? 'paused' : ''}`}>
      <div className="health-bar">
        <div className="health" style={{ width: `${health}%` }}></div>
      </div>
      {paused && health === 0 && <div className="game-over">You are dead</div>}
      <button onClick={handlePause}>{paused ? 'Resume' : 'Pause'} </button>
      <div className="game-counter">{counter}</div>
      {items
        .filter((item) => item.alive)
        .map((item) => (
          <Item
            key={item.id}
            onClick={() => onClick(item)}
            paused={paused}
            setHealth={() => handleSetHealth(item)}
          ></Item>
        ))}
    </div>
  );
};

export default GameArea;
