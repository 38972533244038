import { AppBar, Box, Container, SxProps, Toolbar, Typography } from '@mui/material';
import AvatarButtonMenu from './AvatarButtonMenu';
import LogoWithSantaHat from './santa/LogoWithSantaHat';

const TopBar = () => {
  const headerContainerSx: SxProps = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'stretch',
  };

  const appBarSx: SxProps = { height: '63px', backgroundColor: '#3B30A2' };

  const textSx: SxProps = { marginLeft: '0.875rem', fontWeight: 600 };

  const containerStyle = {
    display: 'contents',
    height: '100%',
    alignItems: 'center',
  };

  return (
    <AppBar position="static" color="primary" sx={appBarSx}>
      <Toolbar variant="dense">
        <Box sx={headerContainerSx}>
          <LogoWithSantaHat logoSrc="/assets/jplus-media-logo.png" altText="j+ bidding" />

          <Typography sx={textSx} variant="h6">
            Bidding
          </Typography>
        </Box>
        <Container sx={containerStyle}>
          <AvatarButtonMenu></AvatarButtonMenu>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
