import { CodeiumEditor } from '@codeium/react-code-editor';
import { Button, debounce, Stack, SxProps } from '@mui/material';
import { useCallback, useState } from 'react';
import { useChangelogStore } from '../../../../../../core/dv360/store/changelog-store.ts';
import { useScriptStore } from '../../../../../../core/dv360/store/script-store.ts';

const containerSx: SxProps = {
  display: 'block',
  height: '500px',
  padding: '16px',
  overflowY: 'scroll',
};

const belowActionButtonContainerSx: SxProps = {
  minHeight: '16px',
  width: '97%',
  padding: '16px',
  borderTop: '1px solid #e0e0e0',
};

const previousButtonSx: SxProps = { marginRight: '10px' };

export const BiddingScriptPreviewForm = ({
  script,
  onClose,
}: {
  script: string;
  onClose: () => void;
}) => {
  // STORE CHANGELOG
  const sendSuccessSnackMessage = useChangelogStore(
    (state) => state.sendSuccessMsgForCopyScriptInClipboard
  );
  const sendErrorSnackMessage = useChangelogStore(
    (state) => state.sendErrorMsgForCopyScriptInClipboard
  );

  // STORE SCRIPT
  const goToForm = useScriptStore((state) => state.hideScriptPreview);

  // LOCAL STATE
  const [scriptValue, setScriptValue] = useState(script);

  const handlePreviousClick = () => {
    goToForm();
  };

  const handleCopyScriptClick = () => {
    navigator.clipboard
      .writeText(scriptValue)
      .then(() => {
        onClose();
        sendSuccessSnackMessage();
      })
      .catch(() => {
        sendErrorSnackMessage();
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(
    debounce((value) => {
      console.log('on change after debounced', value);
      setScriptValue(value);
    }, 100),
    []
  );

  const handleChange = (value: string = '') => {
    debouncedChangeHandler(value);
  };

  return (
    <Stack>
      <Stack direction="column" alignItems="left" justifyContent="start" sx={containerSx}>
        {/* PREVIEW EDITOR */}
        <CodeiumEditor
          language="python"
          theme="light"
          height={'95%'}
          value={scriptValue}
          onChange={handleChange}
        />
      </Stack>

      {/* FORM ACTION BUTTONS */}
      <Stack
        direction="row"
        alignItems={'center'}
        justifyContent={'end'}
        sx={belowActionButtonContainerSx}
      >
        <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
          {/* Clear Button */}
          <Button
            size="small"
            sx={previousButtonSx}
            onClick={handlePreviousClick}
            color="primary"
            variant="text"
          >
            Previous
          </Button>

          {/* Generate Button */}
          <Button color="primary" variant="contained" onClick={handleCopyScriptClick}>
            Copy script
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
