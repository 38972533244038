/* eslint-disable react-refresh/only-export-components */
import { SxProps, Tab, Typography, styled } from '@mui/material';

export const StackSx: SxProps = {
  margin: '20px 0 20px 0',
  justifyContent: 'center',
  borderRadius: '8px',
};
export const BoxSx: SxProps = { minWidth: 275, maxWidth: 1200 };
export const ButtonSx: SxProps = { textTransform: 'none !important' };
export const fullWidthSx: SxProps = { width: '100%' };
export const noMarginTopSx: SxProps = { marginTop: '0px !important' };
export const marginTopSx: SxProps = { marginTop: '20px' };
export const marginBottom16Sx: SxProps = { marginBottom: '16px' };
export const justifyContentCenterSx: SxProps = { justifyContent: 'center' };

export const StyledTypo = styled(Typography)(() => ({
  display: 'block',
}));

export const TitleTypo = styled(StyledTypo)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}));

export const ContentTypo = styled(StyledTypo)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const CustomTab = styled(Tab)(() => ({
  textTransform: 'none',
}));
