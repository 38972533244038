import { injectable } from 'inversify';
import { ChangeEntry } from '../../../../core/dv360/model/change-entry.ts';

export type ChangelogEntryProps = {
  date: string;
  changes: string;
  description: string;
};

@injectable()
export class Dv360ChangelogMapper {
  toDeserialized({ date, changes, description }: ChangelogEntryProps): ChangeEntry {
    return {
      date: new Date(date),
      changes: changes ?? '',
      description: description ?? '',
    };
  }
}
