import { TextField } from '@mui/material';
import { bidAdjustmentValueFieldStyle } from '../categories/styles';
import { ChangeEvent, useState } from 'react';

const validator = (value: number) => {
  return value < 0.1 || value > 1 ? 'Value must be between 0.1 and 1' : false;
};

type NumberStepProps = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  value: number;
  disabled: boolean;
};

export const NumberStep: React.FC<NumberStepProps> = ({
  onChange,
  value,
  name,
  disabled = false,
}) => {
  const [valueLocal, setValueLocal] = useState(value);
  const [error, setError] = useState<string | boolean>('');
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const newValue = parseFloat(e.target.value);
      const errorMessage = validator(newValue);
      setValueLocal(newValue);
      setError(errorMessage);
      onChange(e);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <TextField
      disabled={disabled}
      id="outlined-number"
      sx={bidAdjustmentValueFieldStyle}
      name={name}
      label="Bid"
      type="number"
      onChange={handleChange}
      error={!!error}
      value={valueLocal}
      helperText={error}
      InputProps={{ inputProps: { min: 0.1, max: 1, step: 0.1 } }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};
