import { injectable } from 'inversify';
import { FloodlightPriorities } from '../../../../core/dv360/model/algorithm.ts';
import { BiddingScriptGenerationData } from '../../../../core/dv360/model/script.ts';

type WeightScriptGenerationInputProps = {
  client: string;
  insertion_order_ids: string[];
  floodlights: string[];
  primary_floodlight: string;
  start_date: string;
  end_date: string;
  k_value: number;
  is_priority: boolean;
  priorities: FloodlightPriorities;
  aggressivity?: number;
  max_coef?: number;
  goal?: string;
};

@injectable()
export class Dv360WeightScriptGenerationMapper {
  toSerialized(data: BiddingScriptGenerationData): WeightScriptGenerationInputProps {
    // Common + Conversion weight
    const props: WeightScriptGenerationInputProps = {
      client: data?.clientId ?? '',
      insertion_order_ids: data?.insertionOrderIds ?? [],
      floodlights: data?.conversionWeightData?.floodLightIds ?? [],
      primary_floodlight: '',
      start_date: data?.startDate ? data.startDate.toISOString().split('T')[0] : '',
      end_date: data?.endDate ? data.endDate.toISOString().split('T')[0] : '',
      k_value: data?.conversionWeightData?.kValue ?? 0,
      is_priority: data?.conversionWeightData?.isFloodlightPriority ?? false,
      priorities: data?.conversionWeightData?.floodlightPriorities ?? {},
    };

    // Domain scoring
    if (data?.domainScoringData?.aggressivity) {
      props.aggressivity = data.domainScoringData.aggressivity;
    }
    if (data?.domainScoringData?.maxCoef) {
      props.max_coef = data.domainScoringData.maxCoef;
    }
    if (data?.domainScoringData?.goal) {
      props.goal = data.domainScoringData.goal;
    }

    return props;
  }
}
