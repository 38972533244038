import INJECTION_TOKENS from "../generic/ioc/injection-tokens.ts";
import type { AuthPort } from "../port/auth.port.ts";
import { inject, injectable } from "inversify";
import "reflect-metadata";


@injectable()
export class CanUserAccessUsecase {
    constructor(
        @inject(INJECTION_TOKENS.AUTH_REPOSITORY) private readonly auth: AuthPort
    ) {}

    async execute(): Promise<boolean> {
        return this.auth.canAccess();
    }
}