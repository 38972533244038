export const CATEGORIES = [
  'age',
  'gender',
  'home_location',
  'device_platform',
  'publisher_platform',
  'user_os',
  'user_device',
  'position_type',
  'automated_bid',
  'custom_audience',
  'locale',
  'booking_window',
  'length_of_stay',
  'travel_start_date',
  'travel_start_day_of_week',
  'user_recency',
];

export type Bid = {
  [key: string]: number | Bid | string[];
};

export type BidApi = {
  user_groups: Bid;
};

export type BidApiHistory = {
  adset_id: number;
  payload: BidApi;
  status: string;
  timestamp: string;
  user_email: string;
  username: string;
};
