import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { DialogTransition } from '../../../components/DialogTransition.tsx';
import { ChangelogList } from './ChangelogList.tsx';

const dialogSx: SxProps = {
  '.MuiDialogContent-root': {
    padding: '0px',
  },
};

const dialogTitleContainerSx: SxProps = {
  borderBottom: '1px solid #e9e9e9',
};

const dialogTitleSx: SxProps = { marginLeft: '0.875rem', fontWeight: 600 };

export const ChangelogDialog = ({
  open,
  onClose,
  clientId,
  partnerId,
  advertiserId,
  algorithmId,
}: {
  open: boolean;
  onClose: () => void;
  clientId: string;
  partnerId: string;
  advertiserId: string;
  algorithmId: string;
}) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={open}
      sx={dialogSx}
      TransitionComponent={DialogTransition}
    >
      <DialogTitle sx={dialogTitleContainerSx}>
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            justifyContent: 'left',
          }}
        >
          <IconButton color="inherit" aria-label="delete" onClick={onClose}>
            <ArrowBackIcon></ArrowBackIcon>
          </IconButton>
          <Typography sx={dialogTitleSx} variant="h6">
            View changelog
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <ChangelogList
          clientId={clientId}
          partnerId={partnerId}
          advertiserId={advertiserId}
          algorithmId={algorithmId}
        />
      </DialogContent>
    </Dialog>
  );
};
