import { Stack, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import GoogleSignInButton from './GoogleSignInButton.tsx';

const SignIn: FunctionComponent = () => {
  return (
   <Stack
       direction="column"
       justifyContent="center"
       alignItems="center"
       spacing={2}
   >
      <Typography variant="h4">Welcome back</Typography>
      <GoogleSignInButton />
   </Stack>
  );
};

export default SignIn;
