import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { bidAdjustmentFieldStyle } from './styles';
import { NumberStep } from '../components/NumberStep';
import { ChangeEvent, useState } from 'react';
import { FormProps, PublisherPlatformFormValues } from '../../../../../../core/meta/model/bid-form.ts';

export const PublisherPlatformForm: React.FC<FormProps<PublisherPlatformFormValues>> = ({
  onChange,
  initialValues,
  disabled,
  excludeValues,
}) => {
  const [genderFormValues, setGenderFormValues] = useState<PublisherPlatformFormValues>(
    initialValues || { publisher: '', value: 1 }
  );
  const handleChange = (e: SelectChangeEvent<string> | ChangeEvent<HTMLInputElement>) => {
    const newValue =
      e.target instanceof HTMLInputElement ? parseFloat(e.target.value) : e.target.value;
    const newFormValue = { ...genderFormValues, [e.target.name]: newValue };
    setGenderFormValues(newFormValue);
    onChange(newFormValue);
  };

  const filteredPublisherPlatform = [
    'facebook',
    'instagram',
    'audience_network',
    'messenger',
    'default',
  ].filter((device) => !excludeValues?.includes(device));

  return (
    <>
      <FormControl sx={bidAdjustmentFieldStyle}>
        <InputLabel>Publisher Platform</InputLabel>
        <Select
          name="publisher"
          label="Publisher Platform"
          onChange={handleChange}
          value={genderFormValues.publisher}
        >
          {filteredPublisherPlatform.map((device) => (
            <MenuItem key={device} value={device}>
              {device.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <NumberStep
        disabled={disabled}
        name="value"
        value={genderFormValues.value}
        onChange={handleChange}
      ></NumberStep>
    </>
  );
};
