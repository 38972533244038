import { LoadingButton } from '@mui/lab';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';

import { Controller, FieldErrors, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import {
  ConversionWeightFormValues,
  ConversionWeightValues,
  DomainScoringValues,
} from '../../../../../../core/dv360/model/bid-form.ts';
import {
  CONVERSION_WEIGHT_FORM_DEFAULT_VALUES,
  DOMAIN_SCORING_GOAL,
  WEIGHT_PERIOD,
} from '../../../../../../core/dv360/model/constant.ts';
import {
  FloodlightPriority,
  FloodlightValue,
} from '../../../../../../core/dv360/model/floodlight.ts';
import { useCustomBidStore } from '../../../../../../core/dv360/store/custom-bid-store.ts';
import { useScriptStore } from '../../../../../../core/dv360/store/script-store.ts';
import { TextHelper } from '../../../../../../core/helper/text.helper.ts';
import { FloodlightAutoCompleteForm } from '../../components/FloodlightAutoCompleteForm.tsx';
import { InsertionOrderAutoCompleteForm } from '../../components/InsertionOrderAutoCompleteForm.tsx';

type OnChangeSwitchController = (value: boolean) => void;

const containerSx: SxProps = {
  display: 'block',
  height: 'auto',
  maxHeight: '500px',
  padding: '16px',
  overflowY: 'scroll',
};

const prioFloodlightSwitchFieldBoxSx: SxProps = {
  width: '220px',
};

const floodlightPrioContainerSx: SxProps = {
  width: '100%',
  gap: '15px',
  marginBottom: '10px',
};

const floodlightPrioContainerTitleSx: SxProps = {
  marginLeft: '15px',
  height: '10px',
};

const floodlightPrioFieldContainerSx: SxProps = {
  width: '100%',
};

const floodlightPrioLabelSx: SxProps = {
  marginLeft: '15px',
};

const belowFieldContainerSx: SxProps = {
  width: '100%',
};

const belowActionButtonContainerSx: SxProps = {
  minHeight: '16px',
  width: '97%',
  padding: '16px',
  borderTop: '1px solid #e0e0e0',
};

const domainScoringSwitchContainerSx: SxProps = {
  marginTop: '30px',
};

const radioSx: SxProps = { marginRight: '80px' };

const clearButtonSx: SxProps = { marginRight: '10px' };

export const BiddingScriptForm = ({
  defaultValues,
}: {
  defaultValues: ConversionWeightFormValues;
}) => {
  // STORE SCRIPT
  const isGenerationLoading = useScriptStore((state) => state.isGenerationLoading);
  const isFloodlightPriorityActive = useScriptStore((state) => state.isFloodlightPriorityActive);
  const floodlightAutoSelectValues = useScriptStore((state) => state.floodlightAutoSelectValues);
  const showPreview = useScriptStore((state) => state.showScriptPreview);
  const generateScript = useScriptStore((state) => state.generateScript);
  const setFormDefaultValues = useScriptStore((state) => state.setFormDefaultValues);
  const resetValues = useScriptStore((state) => state.resetValues);

  // STORE CUSTOM BIDDING
  const clientId = useCustomBidStore((state) => state.clientId);
  const ioAutoSelectValues = useCustomBidStore((state) => state.advertiserIos);
  const setNewAlgorithmConversionWeightData = useCustomBidStore(
    (state) => state.setNewAlgorithmConversionWeightData
  );

  // LOCAL STATE
  const defineDefaultValueForShowFloodlightPrioSection = () => {
    const { floodlightPriorities } = defaultValues;
    return floodlightPriorities.length > 0;
  };

  const [showFloodlightPrioSection, setShowFloodlightPrioSection] = useState<boolean>(
    defineDefaultValueForShowFloodlightPrioSection
  );
  const [showDomainScoringSection, setShowDomainScoringSection] = useState<boolean>(
    defaultValues.isDomainScoringActivated
  );

  // FORM
  const form = useForm<ConversionWeightFormValues>({
    defaultValues,
    mode: 'onChange',
  });
  const { control, handleSubmit, watch, setValue, reset, formState } = form;
  const { isValid, errors } = formState;
  const { fields, remove, append } = useFieldArray({ control, name: 'floodlightPriorities' });
  const watchFloodlightIds = watch('floodlightIds');

  const onSubmit: SubmitHandler<ConversionWeightFormValues> = async (data) => {
    console.log(data);
    setNewAlgorithmConversionWeightData(data);

    const { insertionOrderIds, period } = data;
    const conversionWeightValues: ConversionWeightValues = {
      floodlightIds: data.floodlightIds,
      isFloodlightPriority: data.isFloodlightPriority,
      floodlightPriorities: data.floodlightPriorities,
      kValue: data.kValue ? Number(data.kValue) : 1.3,
    };
    const domainScoringValues: DomainScoringValues = {
      isDomainScoringActivated: data.isDomainScoringActivated,
      goal: data.goal,
      maxCoefficient: data.maxCoefficient ? Number(data.maxCoefficient) : undefined,
      aggressivity: data.aggressivity ? Number(data.aggressivity) : undefined,
    };

    const generationInSuccess = await generateScript(
      clientId,
      insertionOrderIds,
      period,
      conversionWeightValues,
      domainScoringValues
    );
    if (generationInSuccess) {
      setFormDefaultValues(data);
      showPreview();
    }
  };

  // EVENT FUNCTIONS
  const handleResetForm = () => {
    reset(CONVERSION_WEIGHT_FORM_DEFAULT_VALUES); // reset form with default values
    setShowDomainScoringSection(false); // Hide domain scoring section
    resetValues(); // reset store props
  };

  // Floodlight priority dynamic Fields management methods
  const convertToFloodlightPriority = (floodlight: FloodlightValue): FloodlightPriority => {
    return { floodlightId: floodlight.id, floodlightName: floodlight.name, value: 1 };
  };

  const handleDeleteFloodlight = (id: string) => {
    const index = fields.findIndex(({ floodlightId }) => floodlightId === id);
    if (index >= 0 && showFloodlightPrioSection) {
      remove(index);
    }
  };

  const handleAddFloodlight = (id: string) => {
    const floodlightValue = floodlightAutoSelectValues.find(({ id: itemId }) => itemId === id);
    const fieldBePresent = fields.some(({ floodlightId }) => floodlightId === id);
    if (floodlightValue && !fieldBePresent && showFloodlightPrioSection) {
      append(convertToFloodlightPriority(floodlightValue));
    }
  };

  const buildPriorityFieldsFromSelectedIds = () => {
    const selectedFloodlights = floodlightAutoSelectValues.filter(({ id }) =>
      watchFloodlightIds.includes(id)
    );
    selectedFloodlights.forEach((floodlightValue) =>
      append(convertToFloodlightPriority(floodlightValue))
    );
  };

  const handleOnChangeFloodlightSwitch = (
    e: ChangeEvent<HTMLInputElement>,
    onChange: OnChangeSwitchController
  ) => {
    const value = e.target.checked;
    setShowFloodlightPrioSection(value);
    if (!value) {
      setValue('floodlightPriorities', []);
    } else {
      buildPriorityFieldsFromSelectedIds();
    }
    onChange(value);
  };

  const isFloodlightPrioFieldInError = (
    errors: FieldErrors<ConversionWeightFormValues>,
    index: number
  ): boolean => {
    return !!errors?.floodlightPriorities?.[index];
  };

  const getFloodlightPrioFieldErrorMessage = (
    errors: FieldErrors<ConversionWeightFormValues>,
    index: number
  ): string => {
    return errors?.floodlightPriorities?.[index]?.value?.message ?? '';
  };

  const handleOnChangeDomainScoringSwitch = (
    e: ChangeEvent<HTMLInputElement>,
    onChange: OnChangeSwitchController
  ) => {
    const value = e.target.checked;
    setShowDomainScoringSection(value);

    // Reset domain scoring form values
    setValue('goal', '');
    setValue('maxCoefficient', undefined);
    setValue('aggressivity', undefined);
    onChange(value);
  };

  const isGoalInError = (): boolean => {
    return !!errors?.goal;
  };

  const getGoalErrorMessage = (): string => {
    return errors?.goal?.message ? errors.goal.message : '';
  };

  useEffect(() => {
    const { floodlightPriorities } = defaultValues;
    if (fields.length === 0) {
      floodlightPriorities.forEach((priority) => append(priority));
    }
  }, [defaultValues, append, fields]);

  useEffect(() => {
    if (!isFloodlightPriorityActive) {
      setShowFloodlightPrioSection(false);
      setValue('isFloodlightPriority', false);
    }
  }, [isFloodlightPriorityActive, setShowFloodlightPrioSection, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" alignItems="left" justifyContent="start" sx={containerSx}>
        {/* FORM INPUT FIELDS */}
        <Stack gap="20px">
          {/* Block of field : Select insertion orders */}
          <InsertionOrderAutoCompleteForm form={form} initialValues={ioAutoSelectValues} />

          {/* Block of field : Prioritise floodlights */}
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <FormControl>
              <Controller
                name="isFloodlightPriority"
                control={control}
                render={({ field: { name, value, onChange, ...field } }) => (
                  <Stack
                    direction="row"
                    flexWrap="nowrap"
                    justifyContent="space-around"
                    alignItems="center"
                    sx={prioFloodlightSwitchFieldBoxSx}
                  >
                    <Switch
                      {...field}
                      id={name}
                      value={value}
                      checked={value}
                      disabled={!isFloodlightPriorityActive}
                      onChange={(e) => handleOnChangeFloodlightSwitch(e, onChange)}
                    />
                    <Typography fontWeight={400} fontSize={16}>
                      Prioritise floodlights
                    </Typography>
                  </Stack>
                )}
              />
            </FormControl>
          </Stack>

          {/* Block of field : Select floodlights */}
          <FloodlightAutoCompleteForm
            control={control}
            initialValues={floodlightAutoSelectValues}
            onDeleteItem={handleDeleteFloodlight}
            onAddItem={handleAddFloodlight}
          />

          {/* Block of dynamic fields : Floodlight priorities */}
          {showFloodlightPrioSection && (
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={floodlightPrioContainerSx}
            >
              <Typography fontSize={12} color="secondary" sx={floodlightPrioContainerTitleSx}>
                Prioritise floodlights
              </Typography>
              {fields.map((priorityField, index) => (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={floodlightPrioFieldContainerSx}
                >
                  <Typography sx={floodlightPrioLabelSx}>
                    {`${priorityField.floodlightId} - ${priorityField.floodlightName}`}
                  </Typography>
                  <FormControl required>
                    <Controller
                      key={priorityField.id}
                      name={`floodlightPriorities.${index}.value`}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Priority is required.',
                        },
                      }}
                      render={({ field: { ...field } }) => (
                        <TextField
                          {...field}
                          required
                          label="Priority"
                          type="number"
                          variant="outlined"
                          size="small"
                          error={isFloodlightPrioFieldInError(errors, index)}
                          helperText={getFloodlightPrioFieldErrorMessage(errors, index)}
                          inputProps={{
                            step: '0.1',
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Stack>
              ))}
            </Stack>
          )}

          {/* Block of fields : Period of analysis & K value global */}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="20px"
            sx={belowFieldContainerSx}
          >
            {/* Block of field : Period of analysis */}
            <FormControl sx={{ flex: 1 }}>
              <InputLabel id="period-label">Period of analysis</InputLabel>
              <Controller
                name="period"
                control={control}
                render={({ field: { name, ...field } }) => (
                  <Select
                    {...field}
                    id={name}
                    name={name}
                    labelId="period-label"
                    label="Period of analysis"
                    variant="outlined"
                  >
                    <MenuItem value=""></MenuItem>
                    {Object.values(WEIGHT_PERIOD).map((period) => (
                      <MenuItem key={period} value={period}>
                        {period}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>

            {/* Block of field : K value */}
            <FormControl sx={{ flex: 1 }}>
              <Controller
                name="kValue"
                control={control}
                render={({ field: { name, ...field } }) => (
                  <TextField
                    {...field}
                    id={name}
                    name={name}
                    type="number"
                    variant="outlined"
                    label="K value global"
                    inputProps={{
                      step: '0.1',
                    }}
                  />
                )}
              />
            </FormControl>
          </Stack>

          {/**
           *
           * SECTION DOMAIN SCORING
           *
           */}

          {/* Block of field : Enable Domain Scoring */}
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={domainScoringSwitchContainerSx}
          >
            <Controller
              name="isDomainScoringActivated"
              control={control}
              render={({ field: { name, value, onChange, ...field } }) => (
                <Stack
                  direction="row"
                  flexWrap="nowrap"
                  justifyContent="space-around"
                  alignItems="center"
                  sx={prioFloodlightSwitchFieldBoxSx}
                >
                  <Switch
                    {...field}
                    id={name}
                    value={value}
                    checked={value}
                    onChange={(e) => handleOnChangeDomainScoringSwitch(e, onChange)}
                  />
                  <Typography fontWeight={400} fontSize={16}>
                    Enable domain scoring
                  </Typography>
                </Stack>
              )}
            />
          </Stack>

          {showDomainScoringSection && (
            <Stack gap="20px">
              {/* Block of field : Goal */}
              <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                <Controller
                  name="goal"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'A selection is required.',
                    },
                  }}
                  render={({ field: { name, ...field } }) => (
                    <FormControl error={isGoalInError()} required={true}>
                      <FormLabel id={`${name}-label`}>Maximize or minimize this KPI</FormLabel>
                      <RadioGroup {...field} name={`${name}-group`} row>
                        {Object.values(DOMAIN_SCORING_GOAL).map((value) => (
                          <FormControlLabel
                            key={value}
                            value={value}
                            control={<Radio />}
                            label={TextHelper.capitalizeFirstLetter(value)}
                            sx={radioSx}
                          />
                        ))}
                      </RadioGroup>
                      <FormHelperText>{getGoalErrorMessage()}</FormHelperText>
                    </FormControl>
                  )}
                />
              </Stack>

              {/* Block of field : Maximiser coefficient & Aggressiveness coefficient */}
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="start"
                gap="20px"
                sx={belowFieldContainerSx}
              >
                {/* Block of field : Maximiser coefficient */}
                <FormControl sx={{ flex: 1 }}>
                  <Controller
                    name="maxCoefficient"
                    control={control}
                    render={({ field: { name, ...field } }) => (
                      <TextField
                        {...field}
                        id={name}
                        name={name}
                        type="number"
                        variant="outlined"
                        label="Maximiser coefficient"
                        inputProps={{
                          step: '0.1',
                        }}
                      />
                    )}
                  />
                </FormControl>

                {/* Block of field : Aggressiveness coefficient */}
                <FormControl sx={{ flex: 1 }}>
                  <Controller
                    name="aggressivity"
                    control={control}
                    render={({ field: { name, ...field } }) => (
                      <TextField
                        {...field}
                        id={name}
                        name={name}
                        type="number"
                        variant="outlined"
                        label="Aggressiveness coefficient"
                        inputProps={{
                          step: '0.1',
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>

      {/* FORM ACTION BUTTONS */}
      <Stack
        direction="row"
        alignItems={'center'}
        justifyContent={'end'}
        sx={belowActionButtonContainerSx}
      >
        <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
          {/* Clear Button */}
          <Button
            size="small"
            sx={clearButtonSx}
            onClick={() => handleResetForm()}
            color="primary"
            variant="text"
          >
            Clear
          </Button>

          {/* Generate Button */}
          <LoadingButton
            loading={isGenerationLoading}
            color="primary"
            variant="contained"
            type="submit"
            disabled={!isValid}
          >
            Generate
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
};
