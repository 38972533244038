import { useCallback } from 'react';
import { AgeForm } from './AgeForm';
import { GenderForm } from './GenderForm';
import { LocationForm } from './location/LocationForm.tsx';
import {
  AgeFormValues,
  AudienceFormValues,
  BookingWindowFormValues,
  DevicePlatformFormValues,
  GenderFormValues,
  LengthOfStayFormValues,
  LocaleFormValues,
  LocationFormValues,
  PositionTypeFormValues,
  PublisherPlatformFormValues,
  TravelStartDateValues,
  TravelStartDayOfWeekValues,
  UserDeviceFormValues,
  UserOSFormValues,
  UserRecencyValues,
  ValuesForm,
} from '../../../../../../core/meta/model/bid-form.ts';
import { DevicePlatformForm } from './DevicePlatformForm.tsx';
import { PublisherPlatformForm } from './PublisherPlatformForm.tsx';
import { UserOSForm } from './UserOSForm.tsx';
import { UserDeviceForm } from './UserDeviceForm.tsx';
import { PositionTypeForm } from './PositionTypeForm.tsx';
import { LocaleForm } from './locale/LocaleForm.tsx';
import { AudienceForm } from './AudienceForm.tsx';
import { BookingWindowForm } from './BookingWindowForm.tsx';
import { LengthOfStayForm } from './LengthOfStayForm.tsx';
import { TravelStartDateForm } from './TravelStartDateForm.tsx';
import { TravelStartDayOfWeekForm } from './TravelStartDayOfWeekForm.tsx';
import { UserRecencyForm } from './UserRecencyForm.tsx';

function debounce<T>(func: (...param: T[]) => void, timeout = 3000) {
  let timer: number;

  return (...args: T[]) => {
    window.clearTimeout(timer);
    timer = window.setTimeout(func, timeout, ...args);
  };
}

type RenderFormProps = {
  category: string;
  onChange: (values: ValuesForm) => void;
  disabled: boolean;
  values?: ValuesForm;
  excludeValues?: string[];
};

export const RenderForm: React.FC<RenderFormProps> = ({
  category,
  onChange,
  disabled,
  values,
  excludeValues,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(debounce(onChange, 500), [onChange]);

  const handleChange = (values: ValuesForm) => {
    debouncedChangeHandler(values);
  };

  switch (category) {
    case 'device_platform':
      return (
        <DevicePlatformForm
          initialValues={values as DevicePlatformFormValues}
          onChange={handleChange}
          disabled={disabled}
          excludeValues={excludeValues}
        />
      );
    case 'publisher_platform':
      return (
        <PublisherPlatformForm
          initialValues={values as PublisherPlatformFormValues}
          onChange={handleChange}
          disabled={disabled}
          excludeValues={excludeValues}
        />
      );
    case 'user_os':
      return (
        <UserOSForm
          initialValues={values as UserOSFormValues}
          onChange={handleChange}
          disabled={disabled}
          excludeValues={excludeValues}
        />
      );
    case 'user_device':
      return (
        <UserDeviceForm
          initialValues={values as UserDeviceFormValues}
          onChange={handleChange}
          disabled={disabled}
          excludeValues={excludeValues}
        />
      );
    case 'position_type':
      return (
        <PositionTypeForm
          initialValues={values as PositionTypeFormValues}
          onChange={handleChange}
          disabled={disabled}
          excludeValues={excludeValues}
        />
      );
    case 'home_location':
      return (
        <LocationForm
          initialValues={values as LocationFormValues}
          onChange={handleChange}
          disabled={disabled}
          excludeValues={excludeValues}
        />
      );
    case 'age':
      return (
        <AgeForm
          initialValues={values as AgeFormValues}
          onChange={handleChange}
          disabled={disabled}
          excludeValues={excludeValues}
        />
      );
    case 'gender':
      return (
        <GenderForm
          initialValues={values as GenderFormValues}
          onChange={handleChange}
          disabled={disabled}
          excludeValues={excludeValues}
        />
      );
    case 'custom_audience':
      return (
        <AudienceForm
          initialValues={values as AudienceFormValues}
          onChange={handleChange}
          disabled={disabled}
          excludeValues={excludeValues}
        />
      );
    case 'locale':
      return (
        <LocaleForm
          initialValues={values as LocaleFormValues}
          onChange={handleChange}
          disabled={disabled}
          excludeValues={excludeValues}
        />
      );
    case 'booking_window':
      return (
        <BookingWindowForm
          initialValues={values as BookingWindowFormValues}
          onChange={handleChange}
          disabled={disabled}
          excludeValues={excludeValues}
        />
      );
    case 'length_of_stay':
      return (
        <LengthOfStayForm
          initialValues={values as LengthOfStayFormValues}
          onChange={handleChange}
          disabled={disabled}
          excludeValues={excludeValues}
        />
      );
    case 'travel_start_date':
      return (
        <TravelStartDateForm
          initialValues={values as TravelStartDateValues}
          onChange={handleChange}
          disabled={disabled}
          excludeValues={excludeValues}
        />
      );
    case 'travel_start_day_of_week':
      return (
        <TravelStartDayOfWeekForm
          initialValues={values as TravelStartDayOfWeekValues}
          onChange={handleChange}
          disabled={disabled}
          excludeValues={excludeValues}
        />
      );
    case 'user_recency':
      return (
        <UserRecencyForm
          initialValues={values as UserRecencyValues}
          onChange={handleChange}
          disabled={disabled}
          excludeValues={excludeValues}
        />
      );
    default:
      return null;
  }
};
