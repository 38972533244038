import { inject, injectable } from 'inversify';
import { FloodlightsPerAdvertiser } from '../../../../core/dv360/model/floodlight.ts';
import { Dv360FloodlightPort } from '../../../../core/dv360/port/dv360-floodlight.port.ts';
import INJECTION_TOKENS from '../../../../core/generic/ioc/injection-tokens.ts';
import type SimpleHttpService from '../../../../core/generic/simple-http-service.port';
import {
  Dv360FloodlightMapper,
  Dv360FloodlightsPerAdvertiserProps,
} from './dv360-floodlight.mapper.ts';

@injectable()
export class Dv360FloodlightRepository implements Dv360FloodlightPort {
  constructor(
    @inject(INJECTION_TOKENS.DV360_API_HTTP_SERVICE)
    private readonly httpService: SimpleHttpService,
    @inject(INJECTION_TOKENS.DV360_FLOODLIGHT_MAPPER) private readonly mapper: Dv360FloodlightMapper
  ) {}

  async getFloodlights(clientId: string, partnerId: string): Promise<FloodlightsPerAdvertiser> {
    const response = await this.httpService.get<Dv360FloodlightsPerAdvertiserProps>(
      `/v1/clients/${clientId}/partners/${partnerId}/floodlights`
    );
    const floodlightsPerAdvertiser = response.advertisers;
    return Object.entries(floodlightsPerAdvertiser).reduce(
      (acc: FloodlightsPerAdvertiser, [advertiserId, advertiserFloodlights]) => {
        acc[advertiserId] = advertiserFloodlights.map((floodlightProps) =>
          this.mapper.toDeserialized(floodlightProps)
        );
        return acc;
      },
      {} as FloodlightsPerAdvertiser
    );
  }
}
