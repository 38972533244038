import { inject, injectable } from 'inversify';
import { AdSet, Campaign } from '../../../../core/meta/model/campaign.ts';
import { CampaignPort } from '../../../../core/meta/port/campaign.port.ts';
import type HttpService from '../../../../core/generic/http-service.port.ts';
import INJECTION_TOKENS from '../../../../core/generic/ioc/injection-tokens.ts';

type CampaignFromAPI = {
  id: string;
  name: string;
  start_time: string;
  stop_time: string;
  status: string;
  objective: string;
};

@injectable()
export class CampaignRepository implements CampaignPort {
  url: string = '/v1/platforms/meta';
  constructor(
    @inject(INJECTION_TOKENS.META_API_HTTP_SERVICE) private readonly httpService: HttpService
  ) {}
  async getCampaigns(accountId: string): Promise<Campaign[]> {
    const campaigns = await this.httpService.get<CampaignFromAPI[]>(
      `${this.url}/accounts/${accountId}/campaigns?page_size=1000&page_id=1`
    );

    const adsetsByCampaigns = await this.httpService.get<{ [key: string]: AdSet[] }>(
      `${this.url}/accounts/${accountId}/adsets?page_size=1000&page_id=1&with_bid_adjustments=true`
    );

    return campaigns.map((campaign) => {
      const campaignObjective = campaign?.objective || '';
      const adsets = (adsetsByCampaigns[campaign?.id] || []).map((adset) => ({
        ...adset,
        campaign_objective: campaignObjective,
        accountId: accountId,
      }));
      return {
        id: campaign?.id || '',
        name: campaign?.name || '',
        startDate: campaign?.start_time?.split('T')[0] || 'None',
        endDate: campaign?.stop_time?.split('T')[0] || 'None',
        status: campaign?.status || '',
        objective: campaignObjective,
        reviewNeeded: adsets.some((adset) => adset?.bid_status === 'draft') ? 'Review needed' : '',
        adsets: adsets,
        accountId: accountId,
      };
    });
  }
}
