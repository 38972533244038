import { injectable } from 'inversify';
import { Client } from '../../../../core/meta/model/client.ts';
import { ClientPort } from '../../../../core/meta/port/client.port.ts';

@injectable()
export class InMemoryClientRepository implements ClientPort {
  clients: Client[] = [];

  setClients(clients: Client[]): void {
    this.clients = clients;
  }
  async getClients(): Promise<Client[]> {
    return this.clients;
  }
}
