import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { bidAdjustmentFieldStyle } from './styles';
import { NumberStep } from '../components/NumberStep';
import { ChangeEvent, useState } from 'react';
import { FormProps, UserOSFormValues } from '../../../../../../core/meta/model/bid-form.ts';

export const UserOSForm: React.FC<FormProps<UserOSFormValues>> = ({
  onChange,
  initialValues,
  disabled,
  excludeValues,
}) => {
  const [genderFormValues, setGenderFormValues] = useState<UserOSFormValues>(
    initialValues || { userOS: '', value: 1 }
  );
  const handleChange = (e: SelectChangeEvent<string> | ChangeEvent<HTMLInputElement>) => {
    const newValue =
      e.target instanceof HTMLInputElement ? parseFloat(e.target.value) : e.target.value;
    const newFormValue = { ...genderFormValues, [e.target.name]: newValue };
    setGenderFormValues(newFormValue);
    onChange(newFormValue);
  };

  const filteredUserOS = ['Android', 'Windows', 'Windows Phone', 'iOS', 'default'].filter(
    (userOS) => !excludeValues?.includes(userOS)
  );

  return (
    <>
      <FormControl sx={bidAdjustmentFieldStyle}>
        <InputLabel>OS</InputLabel>
        <Select name="userOS" label="OS" onChange={handleChange} value={genderFormValues.userOS}>
          {filteredUserOS.map((userOS) => (
            <MenuItem key={userOS} value={userOS}>
              {userOS}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <NumberStep
        disabled={disabled}
        name="value"
        value={genderFormValues.value}
        onChange={handleChange}
      ></NumberStep>
    </>
  );
};
