import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { useScriptStore } from '../../../../../../core/dv360/store/script-store.ts';
import { DialogTransition } from '../../../components/DialogTransition.tsx';
import { BiddingScriptForm } from './BiddingScriptForm.tsx';
import { BiddingScriptPreviewForm } from './BiddingScriptPreviewForm.tsx';

const textSx: SxProps = { marginLeft: '0.875rem', fontWeight: 600 };

export const BiddingScriptDialog = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  // STORE SCRIPT
  const isScriptPreview = useScriptStore((state) => state.isScriptPreview);
  const previewScript = useScriptStore((state) => state.previewScript);
  const formDefaultValues = useScriptStore((state) => state.formDefaultValues);

  return (
    <Dialog fullWidth={true} maxWidth="lg" open={open} TransitionComponent={DialogTransition}>
      <DialogTitle>
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            justifyContent: 'left',
          }}
        >
          <IconButton color="inherit" aria-label="delete" onClick={onClose}>
            <ArrowBackIcon></ArrowBackIcon>
          </IconButton>
          <Typography sx={textSx} variant="h6">
            Auto generate conversion weight
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {isScriptPreview ? (
          <BiddingScriptPreviewForm script={previewScript} onClose={onClose} />
        ) : (
          <BiddingScriptForm defaultValues={formDefaultValues} />
        )}
      </DialogContent>
    </Dialog>
  );
};
