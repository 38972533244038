import { createBrowserRouter } from 'react-router-dom';
import PrivateRoute from './adapters/driving/auth/PrivateRoute.tsx';
import { WrapAuthProvider } from './adapters/driving/auth/WrapAuthProvider.tsx';
import DV360AdvertiserListPage from './adapters/driving/private/dv360/DV360AdvertiserList.tsx';
import { DV360ClientListPage } from './adapters/driving/private/dv360/DV360ClientList.tsx';
import { DV360Layout } from './adapters/driving/private/dv360/DV360Layout.tsx';
import HomePage from './adapters/driving/private/HomePage.tsx';
import Layout from './adapters/driving/private/Layout.tsx';
import MetaCampaignListPage from './adapters/driving/private/Meta/CampaignList.tsx';
import MetaClientListPage from './adapters/driving/private/Meta/ClientList.tsx';
import { MetaLayout } from './adapters/driving/private/Meta/MetaLayout.tsx';
import Resources from './adapters/driving/private/Resources.tsx';
import NotAuthorized from './adapters/driving/public/not-authorized/NotAuthorized.tsx';
import PublicLayoutPage from './adapters/driving/public/PublicLayoutPage.tsx';
import SignIn from './adapters/driving/public/sign-in/SignIn.tsx';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <WrapAuthProvider />,
    children: [
      {
        path: 'sign-in',
        element: <PublicLayoutPage component={SignIn} />,
      },
      {
        path: 'not-authorized',
        element: <PublicLayoutPage component={NotAuthorized} />,
      },
      {
        path: '',
        element: <PrivateRoute component={Layout} />,
        children: [
          { path: '', element: <HomePage /> },
          { path: 'resources', element: <Resources /> },
          {
            path: '/meta',
            Component: MetaLayout,
            children: [
              {
                path: '',
                Component: MetaClientListPage,
              },
              {
                path: ':clientId/accounts/:accountId/campaigns',
                Component: MetaCampaignListPage,
              },
            ],
          },
          {
            path: '/dv360',
            Component: DV360Layout,
            children: [
              {
                path: '',
                Component: DV360ClientListPage,
              },
              {
                path: ':clientId/partners/:partnerId/advertisers',
                Component: DV360AdvertiserListPage,
              },
            ],
          },
        ],
      },
    ],
  },
]);
