import { Chip } from '@mui/material';
import { EntityStatus } from '../../../../../core/dv360/model/constant.ts';

export const AlgoStatusChip = ({ status }: { status: EntityStatus }) => {
  const statusLabel = (status: EntityStatus) => {
    switch (status) {
      case 'ENTITY_STATUS_SCHEDULED_FOR_DELETION':
        return 'Scheduled for deletion';
      case 'ENTITY_STATUS_ACTIVE':
        return 'Active';
      case 'ENTITY_STATUS_ARCHIVED':
        return 'Archived';
      case 'ENTITY_STATUS_DRAFT':
        return 'Draft';
      case 'ENTITY_STATUS_PAUSED':
        return 'Paused';
      default:
        return 'Unspecified';
    }
  };

  const statusToColor = (status: EntityStatus) => {
    switch (status) {
      case 'ENTITY_STATUS_SCHEDULED_FOR_DELETION':
        return '#F44336';
      case 'ENTITY_STATUS_ACTIVE':
        return '#4CAF50';
      case 'ENTITY_STATUS_PAUSED':
        return '#2196F3';
      default:
        return '#757575';
    }
  };

  const statusToBorderColor = (status: EntityStatus) => {
    switch (status) {
      case 'ENTITY_STATUS_SCHEDULED_FOR_DELETION':
        return '#F44336';
      case 'ENTITY_STATUS_ACTIVE':
        return '#4CAF50';
      case 'ENTITY_STATUS_PAUSED':
        return '#2196F3';
      default:
        return '#757575';
    }
  };

  return (
    <Chip
      label={statusLabel(status)}
      variant={'outlined'}
      style={{
        // backgroundColor: statusToBackgroundColor(status),
        color: statusToColor(status),
        borderColor: statusToBorderColor(status),
        fontWeight: 'bold',
      }}
    />
  );
};
