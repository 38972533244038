import { Locale } from './LocaleForm.tsx';

export const LocaleData: Locale[] = [
  {
    key: 6,
    name: 'English (US)',
  },
  {
    key: 1,
    name: 'Catalan',
  },
  {
    key: 2,
    name: 'Czech',
  },
  {
    key: 56,
    name: 'Cebuano',
  },
  {
    key: 3,
    name: 'Welsh',
  },
  {
    key: 4,
    name: 'Danish',
  },
  {
    key: 5,
    name: 'German',
  },
  {
    key: 59,
    name: 'Basque',
  },
  {
    key: 23,
    name: 'Spanish',
  },
  {
    key: 7,
    name: 'Spanish (Spain)',
  },
  {
    key: 66,
    name: 'Guarani',
  },
  {
    key: 8,
    name: 'Finnish',
  },
  {
    key: 9,
    name: 'French (France)',
  },
  {
    key: 65,
    name: 'Galician',
  },
  {
    key: 30,
    name: 'Hungarian',
  },
  {
    key: 10,
    name: 'Italian',
  },
  {
    key: 11,
    name: 'Japanese',
  },
  {
    key: 12,
    name: 'Korean',
  },
  {
    key: 13,
    name: 'Norwegian (bokmal)',
  },
  {
    key: 84,
    name: 'Norwegian (nynorsk)',
  },
  {
    key: 14,
    name: 'Dutch',
  },
  {
    key: 63,
    name: 'Frisian',
  },
  {
    key: 15,
    name: 'Polish',
  },
  {
    key: 16,
    name: 'Portuguese (Brazil)',
  },
  {
    key: 31,
    name: 'Portuguese (Portugal)',
  },
  {
    key: 32,
    name: 'Romanian',
  },
  {
    key: 17,
    name: 'Russian',
  },
  {
    key: 33,
    name: 'Slovak',
  },
  {
    key: 34,
    name: 'Slovenian',
  },
  {
    key: 18,
    name: 'Swedish',
  },
  {
    key: 35,
    name: 'Thai',
  },
  {
    key: 19,
    name: 'Turkish',
  },
  {
    key: 76,
    name: 'Northern Kurdish (Kurmanji)',
  },
  {
    key: 20,
    name: 'Simplified Chinese (China)',
  },
  {
    key: 21,
    name: 'Traditional Chinese (Hong Kong)',
  },
  {
    key: 22,
    name: 'Traditional Chinese (Taiwan)',
  },
  {
    key: 36,
    name: 'Afrikaans',
  },
  {
    key: 87,
    name: 'Albanian',
  },
  {
    key: 68,
    name: 'Armenian',
  },
  {
    key: 53,
    name: 'Azerbaijani',
  },
  {
    key: 54,
    name: 'Belarusian',
  },
  {
    key: 45,
    name: 'Bengali',
  },
  {
    key: 55,
    name: 'Bosnian',
  },
  {
    key: 37,
    name: 'Bulgarian',
  },
  {
    key: 38,
    name: 'Croatian',
  },
  {
    key: 83,
    name: 'Flemish',
  },
  {
    key: 24,
    name: 'English (UK)',
  },
  {
    key: 57,
    name: 'Esperanto',
  },
  {
    key: 58,
    name: 'Estonian',
  },
  {
    key: 62,
    name: 'Faroese',
  },
  {
    key: 44,
    name: 'French (Canada)',
  },
  {
    key: 72,
    name: 'Georgian',
  },
  {
    key: 39,
    name: 'Greek',
  },
  {
    key: 67,
    name: 'Gujarati',
  },
  {
    key: 46,
    name: 'Hindi',
  },
  {
    key: 69,
    name: 'Icelandic',
  },
  {
    key: 25,
    name: 'Indonesian',
  },
  {
    key: 64,
    name: 'Irish',
  },
  {
    key: 71,
    name: 'Javanese',
  },
  {
    key: 75,
    name: 'Kannada',
  },
  {
    key: 73,
    name: 'Kazakh',
  },
  {
    key: 78,
    name: 'Latvian',
  },
  {
    key: 40,
    name: 'Lithuanian',
  },
  {
    key: 79,
    name: 'Macedonian',
  },
  {
    key: 41,
    name: 'Malay',
  },
  {
    key: 81,
    name: 'Marathi',
  },
  {
    key: 80,
    name: 'Mongolian',
  },
  {
    key: 82,
    name: 'Nepali',
  },
  {
    key: 47,
    name: 'Punjabi',
  },
  {
    key: 42,
    name: 'Serbian',
  },
  {
    key: 88,
    name: 'Swahili',
  },
  {
    key: 26,
    name: 'Filipino',
  },
  {
    key: 48,
    name: 'Tamil',
  },
  {
    key: 49,
    name: 'Telugu',
  },
  {
    key: 50,
    name: 'Malayalam',
  },
  {
    key: 52,
    name: 'Ukrainian',
  },
  {
    key: 91,
    name: 'Uzbek',
  },
  {
    key: 27,
    name: 'Vietnamese',
  },
  {
    key: 74,
    name: 'Khmer',
  },
  {
    key: 89,
    name: 'Tajik',
  },
  {
    key: 28,
    name: 'Arabic',
  },
  {
    key: 29,
    name: 'Hebrew',
  },
  {
    key: 90,
    name: 'Urdu',
  },
  {
    key: 60,
    name: 'Persian',
  },
  {
    key: 85,
    name: 'Pashto',
  },
  {
    key: 86,
    name: 'Sinhala',
  },
  {
    key: 70,
    name: 'Japanese (Kansai)',
  },
  {
    key: 1001,
    name: 'English (All)',
  },
  {
    key: 1002,
    name: 'Spanish (All)',
  },
  {
    key: 1003,
    name: 'French (All)',
  },
  {
    key: 1004,
    name: 'Chinese (All)',
  },
  {
    key: 1005,
    name: 'Portuguese (All)',
  },
];
