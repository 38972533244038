import CopyAllIcon from '@mui/icons-material/CopyAll';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Button, Stack, SxProps } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import { ChangeEntryTable } from '../../../../../../core/dv360/model/change-entry.ts';
import { useChangelogStore } from '../../../../../../core/dv360/store/changelog-store.ts';
import { DataTable } from '../../../components/DataTable.tsx';
import { DV360_ALGORITHM_CHANGELOG_COLUMNS } from './ChangelogListConfig.tsx';

const changelogListSx: SxProps = {
  minHeight: '500px',
  height: '500px',
  maxHeight: '500px',
  border: '1px solid #e9e9e9',
  borderRadius: '5px',
  margin: '16px',
};

const clickButtonSx: SxProps = {
  marginRight: '16px',
};

export const ChangelogList = ({
  clientId,
  partnerId,
  advertiserId,
  algorithmId,
}: {
  clientId: string;
  partnerId: string;
  advertiserId: string;
  algorithmId: string;
}) => {
  // STORE CHANGELOG
  const setChangelog = useChangelogStore((state) => state.setChangelog);
  const changelog = useChangelogStore((state) => state.changelogTable);
  const isChangelogLoading = useChangelogStore((state) => state.isChangelogLoading);
  const copyChangelogToClipboard = useChangelogStore((state) => state.copyChangelogToClipboard);
  const exportChangelogToCsv = useChangelogStore((state) => state.exportChangelogToCsv);

  // STATE
  const [selectedRows, setSelectedRows] = useState<ChangeEntryTable[]>([]);

  // EFFECT
  useEffect(() => {
    setChangelog(clientId, partnerId, advertiserId, algorithmId);
  }, [clientId, partnerId, advertiserId, algorithmId, setChangelog]);

  // EVENT METHOD
  const handleClickCopy = () => {
    copyChangelogToClipboard(selectedRows);
  };

  const handleClickExport = () => {
    exportChangelogToCsv(selectedRows, clientId, partnerId, advertiserId, algorithmId);
  };

  const onSelectionChange = (item: GridRowSelectionModel) => {
    const selectedIDs = new Set(item as string[]);
    const selectedRows = changelog.filter((row) => selectedIDs.has(row.id));
    setSelectedRows(selectedRows);
  };

  const buildToolBar = () => {
    return (
      <Stack padding={1} direction="row" justifyContent={'end'}>
        <Button
          size="small"
          color="secondary"
          variant="text"
          sx={clickButtonSx}
          disabled={!selectedRows?.length}
          startIcon={<CopyAllIcon />}
          onClick={() => handleClickCopy()}
        >
          Copy
        </Button>
        <Button
          size="small"
          color="secondary"
          variant="text"
          disabled={!selectedRows?.length}
          startIcon={<FileDownloadIcon />}
          onClick={() => handleClickExport()}
        >
          Export
        </Button>
      </Stack>
    );
  };

  return (
    <Stack display="column" alignItems="stretch" justifyContent="center" sx={changelogListSx}>
      <Stack></Stack>
      <DataTable<ChangeEntryTable>
        checkboxSelection={true}
        rows={changelog ?? []}
        columns={DV360_ALGORITHM_CHANGELOG_COLUMNS}
        loading={isChangelogLoading}
        hasBorderRadius={true}
        hasBorder={false}
        rowHeight={'auto'}
        autoHeight={false}
        scrollMainContent={true}
        toolBarElement={buildToolBar()}
        onRowSelectionModelChange={onSelectionChange}
      ></DataTable>
    </Stack>
  );
};
