import { inject, injectable } from 'inversify';
import INJECTION_TOKENS from '../../../../core/generic/ioc/injection-tokens.ts';
import { Client } from '../../../../core/meta/model/client.ts';
import { ClientPort } from '../../../../core/meta/port/client.port.ts';
import type HttpService from '../../../../core/generic/http-service.port.ts';

@injectable()
export class ClientRepository implements ClientPort {
  url: string = '/v1/platforms/meta';
  constructor(
    @inject(INJECTION_TOKENS.META_API_HTTP_SERVICE) private readonly httpService: HttpService
  ) {}

  async getClients(): Promise<Client[]> {
    return this.httpService.get<Client[]>(`${this.url}/clients`);
  }
}
