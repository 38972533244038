import { Checkbox, TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { LocationFormValueToSend, RenderLocationFormProps } from './location-form-props.ts';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Country } from '../../../../../../../core/meta/model/location.ts';
import { GroupHeader, GroupItems } from './location-form-style.tsx';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export const CountryForm = ({
  subcategory,
  options,
  onChange,
  excludeValues,
  locationFormValues,
}: RenderLocationFormProps) => {
  const [values, setValues] = useState<Country[]>([]);
  useEffect(() => {
    setValues(options as Country[]);
  }, [values, options]);

  const countries: Country[] = values.map((country) => {
    const firstLetter = country.name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...country,
    };
  });

  const handleAutocompleteChange = (
    _event: SyntheticEvent<Element, Event>,
    values: Country[] | null
  ) => {
    const valueToSend: LocationFormValueToSend = {
      name: 'countries',
      values: values?.map((value) => value.key),
    };
    if (valueToSend) onChange(valueToSend);
  };

  const filteredCountries = countries.filter((country) => !excludeValues?.includes(country.key));

  const initialAutoCompleteValues = filteredCountries.filter((country) => {
    return locationFormValues?.countries?.find((data) => data === country.key);
  });

  return (
    <Autocomplete
      id="location-category-country"
      options={filteredCountries.sort(
        (a, b) => -(b?.firstLetter ?? '').localeCompare(a?.firstLetter || '')
      )}
      value={initialAutoCompleteValues}
      limitTags={1}
      multiple
      disableCloseOnSelect
      isOptionEqualToValue={(option, value) => option.key === value.key}
      groupBy={(option) => option?.firstLetter || ''}
      getOptionLabel={(option) => option.name}
      onChange={handleAutocompleteChange}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            name={option.name}
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      renderInput={(params) => <TextField {...params} label={subcategory} />}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
    />
  );
};
