import { AlgorithmType, EntityStatus, WEIGHT_PERIOD } from './constant.ts';
import { FloodlightPriority } from './floodlight.ts';

export type Algorithm = {
  id: string;
  name: string;
  status: EntityStatus;
  type: AlgorithmType;
  advertiserId: string;
  strategy?: string;
  script?: string;
};

export type AlgorithmScript = {
  id: string;
  algorithmId: string;
};

export type FloodlightPriorities = {
  [floodlightId: string]: number;
};

export const giveStartDateFromPeriod = (period: string): Date => {
  const today = new Date();
  const daysAgo = new Date();
  if (period === WEIGHT_PERIOD.PERIOD_30_DAYS) {
    daysAgo.setDate(today.getDate() - 30);
  } else {
    daysAgo.setDate(today.getDate() - 90);
  }
  return daysAgo;
};

export const convertToFloodlightPriorities = (
  values: FloodlightPriority[]
): FloodlightPriorities => {
  return values.reduce((acc: FloodlightPriorities, { floodlightId, value }: FloodlightPriority) => {
    acc[floodlightId] = value;
    return acc;
  }, {});
};
