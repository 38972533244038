import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { bidAdjustmentFieldStyle } from './styles';
import { NumberStep } from '../components/NumberStep';
import { ChangeEvent, useState } from 'react';
import { FormProps, PositionTypeFormValues } from '../../../../../../core/meta/model/bid-form.ts';
const platforms: string[] = [
  'facebook_feed',
  'facebook_marketplace',
  'facebook_suggested_video',
  'facebook_right_hand_column',
  'facebook_biz_disco_feed',
  'instagram_stream',
  'instagram_profile_feed',
  'instagram_explore',
  'instagram_explore_home',
  'messenger_messenger_home',
  'instagram_story',
  'facebook_story',
  'messenger_story',
  'instagram_reels',
  'facebook_facebook_reels',
  'facebook_instream_video',
  'facebook_facebook_reels_overlay',
  'facebook_search',
  'instagram_ig_search',
  'audience_network_classic',
  'audience_network_rewarded_video',
  'default',
];
export const PositionTypeForm: React.FC<FormProps<PositionTypeFormValues>> = ({
  onChange,
  initialValues,
  disabled,
  excludeValues,
}) => {
  const [genderFormValues, setGenderFormValues] = useState<PositionTypeFormValues>(
    initialValues || { positionType: '', value: 1 }
  );
  const handleChange = (e: SelectChangeEvent<string> | ChangeEvent<HTMLInputElement>) => {
    const newValue =
      e.target instanceof HTMLInputElement ? parseFloat(e.target.value) : e.target.value;
    const newFormValue = { ...genderFormValues, [e.target.name]: newValue };
    setGenderFormValues(newFormValue);
    onChange(newFormValue);
  };

  const filteredPositionType = platforms.filter((platform) => !excludeValues?.includes(platform));

  return (
    <>
      <FormControl sx={bidAdjustmentFieldStyle}>
        <InputLabel>Position Type</InputLabel>
        <Select
          name="positionType"
          label="Position Type"
          onChange={handleChange}
          value={genderFormValues.positionType}
        >
          {filteredPositionType.map((platform) => (
            <MenuItem value={platform}>{platform}</MenuItem>
          ))}
          <MenuItem value="default">Default</MenuItem>
        </Select>
      </FormControl>
      <NumberStep
        disabled={disabled}
        name="value"
        value={genderFormValues.value}
        onChange={handleChange}
      ></NumberStep>
    </>
  );
};
