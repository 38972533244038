import { inject, injectable } from 'inversify';
import INJECTION_TOKENS from '../generic/ioc/injection-tokens';
import type { UserPort } from '../port/user.port';
import { UserCreationError } from '../model/user-error';

@injectable()
export class CreateUserUsecase {
  constructor(
    @inject(INJECTION_TOKENS.USER_REPOSITORY)
    private readonly userRepository: UserPort
  ) {}
  async execute() {
    try {
      await this.userRepository.createUser();
    } catch {
      throw new UserCreationError('Error creating user');
    }
  }
}
