import { inject, injectable } from 'inversify';
import { BidPort, BidResponse } from '../../../../core/meta/port/bid.port.ts';
import { Bid, BidApi, BidApiHistory } from '../../../../core/meta/model/bid.ts';
import type HttpService from '../../../../core/generic/http-service.port.ts';
import INJECTION_TOKENS from '../../../../core/generic/ioc/injection-tokens.ts';

@injectable()
export class BidRepository implements BidPort {
  url: string = '/v1/platforms/meta';
  constructor(
    @inject(INJECTION_TOKENS.META_API_HTTP_SERVICE) private readonly httpService: HttpService
  ) {}

  setBids(adsetIds: string[], bidAdjustment: Bid): Promise<BidResponse> {
    return this.httpService.post<BidResponse>(`${this.url}/adsets/bid_adjustments`, {
      adset_ids: adsetIds,
      payload: { user_groups: bidAdjustment },
    });
  }

  saveDraftBidAdjustment(adsetIds: string[], bidAdjustment: Bid): Promise<BidResponse> {
    return this.httpService.post<BidResponse>(`${this.url}/adsets/bid_adjustments/draft`, {
      adset_ids: adsetIds,
      payload: { user_groups: bidAdjustment },
    });
  }

  async getBidHistory(adsetId: string): Promise<BidApi> {
    const response = await this.httpService.get<BidApiHistory[]>(
      `${this.url}/adsets/${adsetId}/bid_adjustments/history`
    );
    const sortedHistory = response.sort(
      (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
    );
    const bid = sortedHistory[sortedHistory.length - 1];

    return {
      user_groups: bid.payload.user_groups,
    };
  }
}
