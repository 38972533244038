import { useEffect } from 'react';

export const useShotgun = (paused: boolean) => {
  const playShotgun = () => {
    const audio = new Audio('/assets/mp3/shotgun.mp3');
    audio.play();
  };

  useEffect(() => {
    if (paused) return;

    // Add event listener for clicks
    document.addEventListener('click', playShotgun);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('click', playShotgun);
    };
  }, [paused]);
};
