import { Chip, Tooltip, Typography } from '@mui/material';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridColDef } from '@mui/x-data-grid-pro';
import CircleIcon from '@mui/icons-material/Circle';
import { CustomDetailPanelToggle } from '../components/CustomDetailPanel.tsx';
import { EditBidButton } from './components/EditBidButton';
import { Stack } from '@mui/system';
import { CampaignObjective } from '../../../../core/meta/model/campaign.ts';
import { ACCOUNTS_WHITE_LIST } from './hooks/accountsWhiteList.ts';

export const META_ADSETS_COLUMNS: GridColDef[] = [
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 100,
    renderCell: (params) => {
      return (
        <CircleIcon
          sx={{ color: statusColors[params.row.status], width: '20px', height: '20px' }}
        ></CircleIcon>
      );
    },
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'name',
    headerName: 'Ad Set',
    minWidth: 400,
  },
  {
    field: 'bid_adjustments',
    headerName: 'Bid Multiplier',
    minWidth: 200,
    renderCell: (params) => {
      const color = params?.row?.bid_adjustments ? 'success' : 'default';
      const label = params?.row?.bid_adjustments ? 'Published' : 'Not published';
      return <Chip label={label} variant="outlined" color={color}></Chip>;
    },
  },
  {
    field: 'bid_status',
    headerName: 'Bid Status',
    minWidth: 200,
    renderCell: (params) => {
      const color = !params?.row?.bid_status
        ? 'default'
        : params?.row?.bid_status === 'published'
          ? 'success'
          : 'warning';
      const label = !params?.row?.bid_status
        ? 'Not set'
        : params?.row?.bid_status === 'published'
          ? 'Published'
          : 'Draft';
      return (
        <>
          {(params.row.bid_status === 'draft' || params.row.bid_status === '') && (
            <Chip label={label} variant="outlined" color={color}></Chip>
          )}
        </>
      );
    },
  },
  {
    field: 'campaign_objective',
    headerName: 'Objective',
    minWidth: 200,
  },
  {
    field: 'actions',
    headerName: '',
    sortable: false,
    minWidth: 220,
    renderCell: (params) => {
      const isOutcomeSales =
        params.row.campaign_objective === CampaignObjective.OUTCOME_SALES &&
        !ACCOUNTS_WHITE_LIST.includes(params.row.accountId ?? '');
      return (
        <Tooltip title={isOutcomeSales ? 'You cannot create or edit bid for this adset' : ''}>
          <Stack
            flexDirection={'row'}
            justifyContent={'end'}
            alignItems={'center'}
            sx={{ width: '100%' }}
          >
            {params.row.bid_status === 'draft' && (
              <Stack sx={{ marginRight: '10px' }}>
                <EditBidButton
                  id={`${params.id}`}
                  campaignId={params.row.campaign_id}
                  bidAdjustments={params.row.bid_adjustments}
                  isReviewEdit={true}
                  disabled={isOutcomeSales}
                ></EditBidButton>
              </Stack>
            )}
            <EditBidButton
              id={`${params.id}`}
              campaignId={params.row.campaign_id}
              bidAdjustments={params.row.bid_adjustments}
              disabled={isOutcomeSales}
            ></EditBidButton>
          </Stack>
        </Tooltip>
      );
    },
  },
];

export const statusColors: { [key: string]: string } = {
  ACTIVE: '#4CAF50',
  PAUSED: '#BDBDBD',
};

export const META_CAMPAIGN_LIST_COLUMNS: GridColDef[] = [
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 100,
    renderCell: (params) => {
      return (
        <CircleIcon
          sx={{ color: statusColors[params.row.status], width: '20px', height: '20px' }}
        ></CircleIcon>
      );
    },
    align: 'center',
    headerAlign: 'center',
  },
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: (params) => <CustomDetailPanelToggle id={params.id} value={params.value} />,
  },
  {
    field: 'name',
    headerName: 'Campaign',
    minWidth: 500,
    flex: 1,
    renderCell: (params) => (
      <div>
        <Typography>{params.row.name}</Typography>
        <Typography color="secondary" variant="caption">
          Start: {params.row.startDate} - End: {params.row.endDate}{' '}
        </Typography>
      </div>
    ),
  },
  {
    field: 'reviewNeeded',
    headerName: 'Review needed',
    minWidth: 300,
    renderCell: (params) => {
      return (
        <>
          {params.row.reviewNeeded && (
            <Chip
              label={params.row.reviewNeeded}
              variant="outlined"
              color={'warning'}
              sx={{ marginRight: '5px' }}
            ></Chip>
          )}
        </>
      );
    },
  },
  {
    field: 'objective',
    headerName: 'Objective',
    minWidth: 200,
  },
  {
    field: 'adsets',
    headerName: 'Ad Sets',
    minWidth: 150,
    valueGetter: (params) => `${params?.row?.adsets?.length || 0} Ad Sets`,
    align: 'right',
    headerAlign: 'right',
  },
];
