import { ChangeEvent, useCallback } from 'react';
import { CountryForm } from './CountryForm.tsx';
import { LocationFormValueToSend, RenderLocationFormProps } from './location-form-props.ts';
import { useMetaStore } from '../../../../../../../core/meta/store/meta-store.ts';
import { SelectChangeEvent } from '@mui/material';
import { RegionForm } from './RegionForm.tsx';
import { CityForm } from './CitiesForm.tsx';

function debounce<T>(func: (...param: T[]) => void, timeout = 3000) {
  let timer: number;

  return (...args: T[]) => {
    window.clearTimeout(timer);
    timer = window.setTimeout(func, timeout, ...args);
  };
}

export const RenderLocationForm: React.FC<RenderLocationFormProps> = ({
  subcategory,
  onChange,
  excludeValues,
  locationFormValues,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(debounce(onChange, 500), [onChange]);

  const handleChange = (
    e:
      | SelectChangeEvent<string>
      | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | LocationFormValueToSend
  ) => {
    debouncedChangeHandler(e);
  };

  const countries = useMetaStore((state) => state.countries);
  const regions = useMetaStore((state) => state.regions);

  switch (subcategory) {
    case 'countries':
      return (
        <CountryForm
          subcategory={subcategory}
          onChange={handleChange}
          options={countries}
          excludeValues={excludeValues}
          locationFormValues={locationFormValues}
        />
      );
    case 'regions':
      return (
        <RegionForm
          subcategory={subcategory}
          onChange={handleChange}
          options={regions}
          excludeValues={excludeValues}
          locationFormValues={locationFormValues}
        />
      );
    case 'cities':
      return (
        <CityForm
          subcategory={subcategory}
          onChange={handleChange}
          excludeValues={excludeValues}
          locationFormValues={locationFormValues}
        />
      );
    default:
      return null;
  }
};
