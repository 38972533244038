import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Checkbox, createFilterOptions, FormControl, TextField } from '@mui/material';
import { FC, SyntheticEvent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { ConversionWeightFormValues } from '../../../../../core/dv360/model/bid-form.ts';
import { FloodlightValue } from '../../../../../core/dv360/model/floodlight.ts';
import { useScriptStore } from '../../../../../core/dv360/store/script-store.ts';

type FloodlightFormInputProps = {
  control: Control<ConversionWeightFormValues, unknown>;
  initialValues: FloodlightValue[];
};

type FloodlightFormOutputProps = {
  onDeleteItem: (floodlightId: string) => void;
  onAddItem: (floodlightId: string) => void;
};

type OnChangeController = (ids: string[]) => void;

export const FloodlightAutoCompleteForm: FC<
  FloodlightFormInputProps & FloodlightFormOutputProps
> = ({ initialValues, control, onDeleteItem, onAddItem }) => {
  // STORE SCRIPT
  const floodlightIdsValues = useScriptStore((state) => state.floodlightIdsValues);
  const setFloodlightIdsValues = useScriptStore((state) => state.setFloodlightIdsValues);

  const handleOnChange = (values: FloodlightValue[], onChangeCtrl: OnChangeController) => {
    const floodlightIds = values.map((item) => item.id);

    // Get the ID of the last selected floodlight to define the one we need to add
    const diff = floodlightIds.filter((item) => !floodlightIdsValues.includes(item));
    const isAddingCase = !!diff.length;
    if (isAddingCase) {
      const lastSelectedFloodlightId = diff[0];
      onAddItem(lastSelectedFloodlightId);
    } else {
      const diff = floodlightIdsValues.filter((item) => !floodlightIds.includes(item));
      if (diff.length) {
        const floodlightToBeDeleted = diff[0];
        onDeleteItem(floodlightToBeDeleted);
      }
    }

    setFloodlightIdsValues(floodlightIds);
    onChangeCtrl(floodlightIds);
  };

  const filterOptions = createFilterOptions({
    stringify: (option: FloodlightValue) => `${option.id} - ${option.name}`,
  });

  return (
    <FormControl>
      <Controller
        name="floodlightIds"
        control={control}
        render={({ field: { name, onChange: onChangeCtrl, value, ...field } }) => (
          <Autocomplete
            {...field}
            id={name}
            value={initialValues.filter((item) => value.includes(item.id))}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={initialValues}
            multiple
            filterOptions={filterOptions}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.id}>
                <Checkbox
                  name={`${option.id} - ${option.name}`}
                  key={option.id}
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.id} - {option.name}
              </li>
            )}
            renderInput={(params) => <TextField {...params} fullWidth label="Select floodlights" />}
            onChange={(_event: SyntheticEvent<Element, Event>, values: FloodlightValue[]) => {
              handleOnChange(values, onChangeCtrl);
            }}
          ></Autocomplete>
        )}
      />
    </FormControl>
  );
};
