import { injectable } from 'inversify';
import { InsertionOrdersPerAdvertiser } from '../../../../core/dv360/model/insertion-order.ts';
import { Dv360IoPort } from '../../../../core/dv360/port/dv360-io.port.ts';

@injectable()
export class InMemoryDv360IoRepository implements Dv360IoPort {
  ios: InsertionOrdersPerAdvertiser = {};
  getIOsError: boolean = false;

  clearData() {
    this.ios = {};
    this.getIOsError = false;
  }

  setIOs(ios: InsertionOrdersPerAdvertiser) {
    this.ios = ios;
  }

  setGetIOsError(error: boolean) {
    this.getIOsError = error;
  }

  async getIOs(clientId: string, partnerId: string): Promise<InsertionOrdersPerAdvertiser> {
    if (this.getIOsError) {
      throw new Error(`Error fetching IOs for client ${clientId} and partner ${partnerId}`);
    }
    return this.ios;
  }
}
