import { Stack, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import GoogleSignInButton from '../sign-in/GoogleSignInButton.tsx';

const NotAuthorized: FunctionComponent = () => {
  return (
    <Stack direction="column" justifyContent="space-around" alignItems="center" spacing={2}>
      <Typography variant="body1">
        You don’t have access to this product. Please contact a tenant administrator.
      </Typography>
      <GoogleSignInButton />
    </Stack>
  );
};

export default NotAuthorized;
