import { injectable } from 'inversify';
import { AnalyticsEvent, AnalyticsPort } from '../../../../core/meta/port/analytics.port.ts';

@injectable()
export class InMemoryAnalyticsRepository implements AnalyticsPort {
  events: AnalyticsEvent[] = [];
  send(event: AnalyticsEvent): void {
    this.events.push(event);
  }
}
