import { Box, Typography } from '@mui/material';
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { DateHelper } from '../../../../../../core/helper/date.helper.ts';

export const DV360_ALGORITHM_CHANGELOG_COLUMNS: GridColDef[] = [
  {
    field: 'date',
    headerName: 'Date',
    minWidth: 200,
    valueFormatter: (params: GridValueFormatterParams<Date>) =>
      DateHelper.formatISODate(params.value),
  },
  {
    field: 'changes',
    headerName: 'Changes',
    flex: 1,
    valueGetter: (params) => params?.row?.changes,
    renderCell: (params) => {
      return (
        <div style={{ width: '100%' }}>
          <Box
            sx={{
              padding: '10px 0px',
            }}
          >
            <Typography align={'left'} fontSize={'14px'} lineHeight={1.75}>
              {params?.row?.changes}
            </Typography>
          </Box>
        </div>
      );
    },
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
    valueGetter: (params) => params?.row?.description,
    renderCell: (params) => {
      return (
        <div style={{ width: '100%' }}>
          <Box
            sx={{
              padding: '16px 0px',
            }}
          >
            <Typography align={'justify'} fontSize={'14px'} lineHeight={1.75}>
              {params?.row?.description}
            </Typography>
          </Box>
        </div>
      );
    },
  },
];
