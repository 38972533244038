import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button } from '@mui/material';
import { useChangelogStore } from '../../../../../core/dv360/store/changelog-store.ts';
import { useCustomBidStore } from '../../../../../core/dv360/store/custom-bid-store.ts';
import INJECTION_TOKENS from '../../../../../core/generic/ioc/injection-tokens.ts';
import { AnalyticsPort } from '../../../../../core/meta/port/analytics.port.ts';
import { useInjection } from '../../../ioc/use-injection.ts';

export const AlgoViewChangelogButton = ({
  advertiserId,
  algorithmId,
}: {
  advertiserId: string;
  algorithmId: string;
}) => {
  const analytics = useInjection<AnalyticsPort>(INJECTION_TOKENS.ANALYTICS_REPOSITORY);

  // STORE CUSTOM BID
  const setAdvertiserId = useCustomBidStore((state) => state.setAdvertiserId);
  const setAlgorithmId = useCustomBidStore((state) => state.setAlgorithmId);

  // STORE CHANGELOG
  const openChangelogDialog = useChangelogStore((state) => state.openChangelogDialog);
  const resetChangelog = useChangelogStore((state) => state.resetChangelog);

  const openDialog = () => {
    analytics.send({
      name: 'show_algorithm_version_history',
      payload: { algorithmId: algorithmId },
    });
    resetChangelog();
    setAdvertiserId(advertiserId);
    setAlgorithmId(algorithmId);
    openChangelogDialog();
  };

  return (
    <Button
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        openDialog();
      }}
      endIcon={<VisibilityIcon />}
    >
      View changelog
    </Button>
  );
};
