import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, styled, SxProps, Typography } from '@mui/material';
import { FC } from 'react';
import INJECTION_TOKENS from '../../../../core/generic/ioc/injection-tokens';
import { AnalyticsPort } from '../../../../core/meta/port/analytics.port.ts';
import { useInjection } from '../../ioc/use-injection';

interface GoogleSignInButtonProps {
  className?: string;
  sx?: SxProps;
}

const googleButtonBoxSx: SxProps = {
  maxWidth: '360px',
  width: '100%',
};

const textSx: SxProps = {
  margin: '8px',
  fontSize: '16px',
  fontWeight: 500,
  color: '#000000',
};

const GoogleButton = styled(Button)`
  font-weight: 700;
  border-radius: 32px;
  border-color: #b8b8b8;
  height: 56px;
  width: 360px;
  background: #ffffff;
`;

const GoogleSignInButton: FC<GoogleSignInButtonProps> = ({ className, sx }) => {
  const { loginWithRedirect } = useAuth0();
  const analytics = useInjection<AnalyticsPort>(INJECTION_TOKENS.ANALYTICS_REPOSITORY);

  const login = () => {
    analytics.send({ name: 'user_login', payload: {} });
    loginWithRedirect();
  };

  return (
    <Box sx={googleButtonBoxSx}>
      <GoogleButton variant="outlined" sx={sx} fullWidth className={className} onClick={login}>
        <img
          src="/assets/icons/google-signin-logo-icon.svg"
          alt="google loging logo"
          width={'19.6px'}
          height={'19.6px'}
        />{' '}
        <Typography variant="body1" sx={textSx}>
          Connect with Google
        </Typography>
      </GoogleButton>
    </Box>
  );
};

export default GoogleSignInButton;
