import CircleIcon from '@mui/icons-material/Circle';
import { Algorithm } from '../../../../../core/dv360/model/algorithm.ts';

export const AlgoStatusCircleIcon = ({ algorithms }: { algorithms: Algorithm[] }) => {
  const algorithmsStatusToColor = (algorithms: Algorithm[]): string => {
    const hasActiveAlgorithms = algorithms.some(
      (algorithm: Algorithm) => algorithm.status === 'ENTITY_STATUS_ACTIVE'
    );
    return hasActiveAlgorithms ? '#4CAF50' : '#BDBDBD';
  };

  return (
    <CircleIcon
      sx={{ color: algorithmsStatusToColor(algorithms), width: '20px', height: '20px' }}
    ></CircleIcon>
  );
};
