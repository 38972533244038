import { injectable } from 'inversify';
import { FloodlightsPerAdvertiser } from '../../../../core/dv360/model/floodlight.ts';
import { Dv360FloodlightPort } from '../../../../core/dv360/port/dv360-floodlight.port.ts';

@injectable()
export class InMemoryDv360FloodlightRepository implements Dv360FloodlightPort {
  floodlights: FloodlightsPerAdvertiser = {};
  getFloodlightsError: boolean = false;

  clearData() {
    this.floodlights = {};
    this.getFloodlightsError = false;
  }

  setfloodlights(floodlights: FloodlightsPerAdvertiser) {
    this.floodlights = floodlights;
  }

  setGetFloodlightsError(error: boolean) {
    this.getFloodlightsError = error;
  }

  async getFloodlights(clientId: string, partnerId: string): Promise<FloodlightsPerAdvertiser> {
    if (this.getFloodlightsError) {
      throw new Error(`Error fetching floodlights for client ${clientId} and partner ${partnerId}`);
    }
    return this.floodlights;
  }
}
