import { Box, Skeleton, Stack, TextField } from '@mui/material';
import {
  DataGridPro,
  GridCallbackDetails,
  gridClasses,
  GridColDef,
  GridControlledStateReasonLookup,
  GridFilterModel,
  GridPinnedColumns,
  GridRowHeightReturnValue,
  GridRowParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import React, { useState } from 'react';

const getBorderRadiusValue = (hasBorderRadius: boolean) => (hasBorderRadius ? '8px' : '0px');
const getBorder = (hasBorder: boolean) =>
  !hasBorder ? 'none' : '1px solid rgba(224, 224, 224, 1)';

interface DataTableProps<T> {
  initialState?: GridInitialStatePro;
  rows: T[];
  columns: GridColDef[];
  getDetailPanelContent?: (params: GridRowParams) => React.ReactNode;
  getDetailPanelHeight?: (params: GridRowParams) => number | 'auto';
  checkboxSelection?: boolean;
  onRowSelectionModelChange?: (
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails<keyof GridControlledStateReasonLookup>
  ) => void;
  rowSelectionModel?: GridRowSelectionModel;
  loading?: boolean;
  hasBorder?: boolean;
  hasBorderRadius?: boolean;
  pinnedColumns?: GridPinnedColumns;
  isRowSelectable?: (params: GridRowParams) => boolean;
  searchLabel?: string;
  rowHeight?: GridRowHeightReturnValue;
  autoHeight?: boolean;
  scrollMainContent?: boolean;
  toolBarElement?: React.JSX.Element;
}

const LoadingSkeleton = () => (
  <Box height={400}>
    {[...Array(15)].map((_, index) => (
      <Skeleton key={index} variant="rectangular" sx={{ my: 4, mx: 1, height: '32px' }} />
    ))}
  </Box>
);

export function DataTable<T>({
  initialState,
  rows,
  columns,
  getDetailPanelContent,
  getDetailPanelHeight,
  checkboxSelection = false,
  onRowSelectionModelChange,
  rowSelectionModel,
  loading = false,
  hasBorder = true,
  hasBorderRadius = true,
  pinnedColumns,
  isRowSelectable,
  searchLabel,
  rowHeight,
  autoHeight,
  scrollMainContent,
  toolBarElement,
}: DataTableProps<T>) {
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    quickFilterValues: [''],
  });

  const uniqueId = Math.floor(Math.random() * 100);

  function QuickSearchToolbar() {
    return (
      <Stack padding={1} direction="row" justifyContent={'end'}>
        <TextField
          sx={{ width: '300px' }}
          size="small"
          id={'search' + uniqueId}
          label={searchLabel ?? 'Search'}
          variant="outlined"
          type="search"
          value={filterModel?.quickFilterValues?.[0] || ''}
          inputRef={(input) => input && filterModel?.quickFilterValues?.[0] !== '' && input.focus()}
          onChange={(event) => {
            setFilterModel({
              items: [],
              quickFilterValues: [event.target.value],
            });
          }}
        />
      </Stack>
    );
  }

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <DataGridPro
        initialState={initialState}
        pagination
        rows={rows}
        columns={columns}
        checkboxSelection={checkboxSelection}
        disableRowSelectionOnClick
        onRowSelectionModelChange={onRowSelectionModelChange}
        autoHeight={autoHeight ?? true}
        isRowSelectable={isRowSelectable}
        rowSelectionModel={rowSelectionModel}
        loading={loading}
        pinnedColumns={pinnedColumns}
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        filterModel={filterModel}
        slots={{
          toolbar: toolBarElement ? () => toolBarElement : QuickSearchToolbar,
          loadingOverlay: LoadingSkeleton,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: false,
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: true },
          },
        }}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        rowThreshold={0}
        getRowHeight={() => rowHeight}
        sx={(theme) => ({
          borderRadius: getBorderRadiusValue(hasBorderRadius),
          border: getBorder(hasBorder),
          backgroundColor: theme.palette.background.paper,
          [`.${gridClasses.root}`]: {
            borderTop: 'none',
          },
          [`.${gridClasses.detailPanel}`]: {
            backgroundColor: theme.palette.background.paper,
          },
          [`.${gridClasses.main}`]: {
            overflow: scrollMainContent ? 'scroll' : 'unset',
            backgroundColor: theme.palette.background.paper,
            borderRadius: getBorderRadiusValue(hasBorderRadius),
            height: loading ? '500px' : 'auto',
          },
          [`.${gridClasses.columnHeaders}`]: {
            color: theme.palette.text.secondary,
            position: 'sticky',
            top: -19,
            backgroundColor: theme.palette.background.paper,
            zIndex: 1,
            borderTopRightRadius: getBorderRadiusValue(hasBorderRadius),
            borderTopLeftRadius: getBorderRadiusValue(hasBorderRadius),
          },
          [`.${gridClasses.panelFooter}`]: {
            backgroundColor: theme.palette.background.paper,
          },
          [`.${gridClasses.footerCell}`]: {
            backgroundColor: theme.palette.background.paper,
          },
          [`.${gridClasses.pinnedColumnHeaders}`]: {
            boxShadow: 'none',
            backgroundColor: theme.palette.background.paper,
          },
          [`.${gridClasses.pinnedColumns}`]: {
            boxShadow: 'none',
            backgroundColor: theme.palette.background.paper,
          },
          [`.${gridClasses.row}.Mui-hovered`]: {
            backgroundColor: 'transparent',
          },
          [`.${gridClasses.row}:hover`]: {
            backgroundColor: 'transparent',
          },
        })}
      />
    </div>
  );
}
