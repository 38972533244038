export const ACCOUNTS_WHITE_LIST = [
  '141109922936572',
  '2216014178639373',
  '692585431126358',
  '3236963323266166',
  '1541872446592000',
  '1206371429519333',
  '1431135783600529',
  '1431137733600334',
  '608534880033276',
];
