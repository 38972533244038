import { Outlet } from 'react-router-dom';
import { useMetaStore } from '../../../../core/meta/store/meta-store.ts';
import { useEffect } from 'react';

export const MetaLayout = () => {
  const setClients = useMetaStore((state) => state.setClients);
  const setLocation = useMetaStore((state) => state.setLocation);

  useEffect(() => {
    setClients();
    setLocation();
  }, [setClients, setLocation]);

  return <Outlet />;
};
