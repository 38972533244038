import { inject, injectable } from 'inversify';
import { UserPort } from '../../../core/port/user.port';
import type HttpService from '../../../core/generic/http-service.port';
import INJECTION_TOKENS from '../../../core/generic/ioc/injection-tokens';

@injectable()
export class UserRepository implements UserPort {
  url: string = '/v1/users';
  constructor(
    @inject(INJECTION_TOKENS.META_API_HTTP_SERVICE) private readonly httpService: HttpService
  ) {}

  async createUser(): Promise<void> {
    return this.httpService.post(`${this.url}`);
  }
}
