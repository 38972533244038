import { Button, Paper, Typography } from '@mui/material';
import { Box, Stack, SxProps } from '@mui/system';
import { BidAdjustmentTopBar } from './BidAdjustmentTopBar';
import { useBidAdjustmentStore } from '../../../../../core/meta/store/bid-adjustment-store.ts';
import { BidAdjustmentForm } from './BidAdjustmentForm';
import { marginBottom16Sx } from '../../../styles/elements';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const boxContainer: SxProps = {
  backgroundColor: 'rgb(250, 250, 250);',
};

const pageContainer: SxProps = {
  padding: '20px 40px',
  overflowY: 'scroll',
  height: 'calc(100vh - 166px)',
};

export const BidAdjustmentContainer = () => {
  const currentStep = useBidAdjustmentStore((state) => state.currentStep);
  const bidFormStatus = useBidAdjustmentStore((state) => state.bidFormStatus);
  return (
    <>
      <BidAdjustmentTopBar></BidAdjustmentTopBar>
      <Box
        flexDirection="row"
        flex="1"
        display="flex"
        sx={boxContainer}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Box sx={pageContainer}>
          <Typography sx={marginBottom16Sx}>{bidFormStatus} Bid Multiplier</Typography>
          <Paper variant="outlined">
            {currentStep === 'landing' && <LandingForm></LandingForm>}
            {currentStep === 'form' && <BidAdjustmentForm></BidAdjustmentForm>}
          </Paper>
        </Box>
      </Box>
    </>
  );
};

const LandingForm = () => {
  const nextStep = useBidAdjustmentStore((state) => state.nextStep);
  return (
    <Stack
      justifyContent={'center'}
      sx={{ minWidth: '800px', padding: '16px', minHeight: '400px' }}
      alignItems={'center'}
    >
      <Typography sx={marginBottom16Sx} color="secondary" variant="h5">
        Create your first bid multiplier
      </Typography>
      <Typography sx={marginBottom16Sx} color="secondary" variant="body1">
        Set your first bid multiplier now and kickstart your bidding strategy with confidence!
      </Typography>
      <Button onClick={nextStep} variant="outlined" endIcon={<ArrowForwardIcon />}>
        Set Bid Multiplier
      </Button>
    </Stack>
  );
};
