import { Container } from 'inversify';
import { InMemoryAuthRepository } from '../../../adapters/driven/auth/in-memory-auth-repository';
import { Dv360AdvertiserMapper } from '../../../adapters/driven/dv360/advertiser/dv360-advertiser.mapper.ts';
import { InMemoryDv360AdvertiserRepository } from '../../../adapters/driven/dv360/advertiser/in-memory-dv360-advertiser.repository.ts';
import { InMemoryDv360Algorithm } from '../../../adapters/driven/dv360/algorithm/in-memory-dv360-algorithm.repository.ts';
import { InMemoryChangelogRepository } from '../../../adapters/driven/dv360/changelog/in-memory-changelog.repository.ts';
import { InMemoryDv360ClientRepository } from '../../../adapters/driven/dv360/client/in-memory-dv360-client.repository.ts';
import { InMemoryDv360FloodlightRepository } from '../../../adapters/driven/dv360/floodlight/in-memory-dv360-floodlight.repository.ts';
import { InMemoryDv360IoRepository } from '../../../adapters/driven/dv360/io/in-memory-dv360-io.repository.ts';
import { AxiosMetaHttpService } from '../../../adapters/driven/generic/http/axios-meta-http-service.ts';
import { InMemoryAnalyticsRepository } from '../../../adapters/driven/meta/analytics/in-memory-analytics.repository.ts';
import { InMemoryAudienceRepository } from '../../../adapters/driven/meta/audience/in-memory-audience-repository.ts';
import { InMemoryBidRepository } from '../../../adapters/driven/meta/bid/in-memory-bid-repository';
import { InMemoryCampaignRepository } from '../../../adapters/driven/meta/campaign/in-memory-campaign-repository';
import { InMemoryClientRepository } from '../../../adapters/driven/meta/client/in-memory-client-repository';
import { InMemoryLocationRepository } from '../../../adapters/driven/meta/location/in-memory-location-repository.ts';
import { InMemoryUserRepository } from '../../../adapters/driven/user/in-memory-user-repository';
import { Dv360AdvertiserPort } from '../../dv360/port/dv360-advertiser.port.ts';
import { Dv360AlgorithmPort } from '../../dv360/port/dv360-algorithm.port.ts';
import { ChangelogPort } from '../../dv360/port/changelog.port.ts';
import { Dv360ClientPort } from '../../dv360/port/dv360-client.port.ts';
import { Dv360FloodlightPort } from '../../dv360/port/dv360-floodlight.port.ts';
import { Dv360IoPort } from '../../dv360/port/dv360-io.port.ts';
import { CreateAlgorithmUsecase } from '../../dv360/usecase/create-algorithm.usecase.ts';
import { AnalyticsPort } from '../../meta/port/analytics.port.ts';
import { BidPort } from '../../meta/port/bid.port.ts';
import { CampaignPort } from '../../meta/port/campaign.port.ts';
import { ClientPort } from '../../meta/port/client.port.ts';
import { LocationPort } from '../../meta/port/location.port.ts';
import { GetAudiencesUseCase } from '../../meta/usecase/get-audiences-usecase.ts';
import { GetCitiesByIdsUseCase } from '../../meta/usecase/get-cities-by-ids-usecase.ts';
import { GetCitiesUseCase } from '../../meta/usecase/get-cities-usecase.ts';
import { GetClientsUseCase } from '../../meta/usecase/get-clients-usecase.ts';
import { GetCountriesUseCase } from '../../meta/usecase/get-countries-usecase.ts';
import { GetRegionsUseCase } from '../../meta/usecase/get-regions-usecase.ts';
import { AuthPort } from '../../port/auth.port';
import { UserPort } from '../../port/user.port';
import { FileService } from '../../service/file.service.ts';
import { InMemoryFileService } from '../../service/in-memory-file.service.ts';
import { CanUserAccessUsecase } from '../../usecase/can-user-access-usecase';
import { CreateUserUsecase } from '../../usecase/create-user-usecase';
import HttpService from '../http-service.port';
import INJECTION_TOKENS from './injection-tokens';

const containerTest = new Container();

/** SERVICES */
containerTest
  .bind<HttpService>(INJECTION_TOKENS.META_API_HTTP_SERVICE)
  .to(AxiosMetaHttpService)
  .inSingletonScope();

containerTest
  .bind<Dv360AdvertiserMapper>(INJECTION_TOKENS.DV360_ADVERTISER_MAPPER)
  .to(Dv360AdvertiserMapper)
  .inSingletonScope();

containerTest
  .bind<FileService>(INJECTION_TOKENS.FILE_SERVICE)
  .to(InMemoryFileService)
  .inSingletonScope();

/** REPOSITORIES */
containerTest
  .bind<AuthPort>(INJECTION_TOKENS.AUTH_REPOSITORY)
  .to(InMemoryAuthRepository)
  .inSingletonScope();
containerTest
  .bind<ClientPort>(INJECTION_TOKENS.CLIENT_REPOSITORY)
  .to(InMemoryClientRepository)
  .inSingletonScope();
containerTest
  .bind<CampaignPort>(INJECTION_TOKENS.CAMPAIGN_REPOSITORY)
  .to(InMemoryCampaignRepository)
  .inSingletonScope();
containerTest
  .bind<UserPort>(INJECTION_TOKENS.USER_REPOSITORY)
  .to(InMemoryUserRepository)
  .inSingletonScope();
containerTest
  .bind<BidPort>(INJECTION_TOKENS.BID_REPOSITORY)
  .to(InMemoryBidRepository)
  .inSingletonScope();
containerTest
  .bind<LocationPort>(INJECTION_TOKENS.LOCATION_REPOSITORY)
  .to(InMemoryLocationRepository)
  .inSingletonScope();
containerTest
  .bind(INJECTION_TOKENS.AUDIENCE_REPOSITORY)
  .to(InMemoryAudienceRepository)
  .inSingletonScope();
containerTest
  .bind<AnalyticsPort>(INJECTION_TOKENS.ANALYTICS_REPOSITORY)
  .to(InMemoryAnalyticsRepository)
  .inSingletonScope();
containerTest
  .bind<Dv360ClientPort>(INJECTION_TOKENS.DV360_CLIENT_REPOSITORY)
  .to(InMemoryDv360ClientRepository)
  .inSingletonScope();
containerTest
  .bind<Dv360AdvertiserPort>(INJECTION_TOKENS.DV360_ADVERTISER_REPOSITORY)
  .to(InMemoryDv360AdvertiserRepository)
  .inSingletonScope();
containerTest
  .bind<Dv360FloodlightPort>(INJECTION_TOKENS.DV360_FLOODLIGHT_REPOSITORY)
  .to(InMemoryDv360FloodlightRepository)
  .inSingletonScope();
containerTest
  .bind<Dv360IoPort>(INJECTION_TOKENS.DV360_IO_REPOSITORY)
  .to(InMemoryDv360IoRepository)
  .inSingletonScope();
containerTest
  .bind<Dv360AlgorithmPort>(INJECTION_TOKENS.DV360_ALGORITHM_REPOSITORY)
  .to(InMemoryDv360Algorithm)
  .inSingletonScope();
containerTest
  .bind<ChangelogPort>(INJECTION_TOKENS.DV360_CHANGELOG_REPOSITORY)
  .to(InMemoryChangelogRepository)
  .inSingletonScope();

/** USECASES */
containerTest
  .bind(INJECTION_TOKENS.CAN_USER_ACCESS_USECASE)
  .to(CanUserAccessUsecase)
  .inSingletonScope();
containerTest.bind(INJECTION_TOKENS.GET_CLIENTS_USECASE).to(GetClientsUseCase).inSingletonScope();
containerTest.bind(INJECTION_TOKENS.CREATE_USER_USECASE).to(CreateUserUsecase).inSingletonScope();
containerTest
  .bind(INJECTION_TOKENS.GET_COUNTRIES_USECASE)
  .to(GetCountriesUseCase)
  .inSingletonScope();
containerTest.bind(INJECTION_TOKENS.GET_CITIES_USECASE).to(GetCitiesUseCase).inSingletonScope();
containerTest
  .bind(INJECTION_TOKENS.GET_CITIES_BY_IDS_USECASE)
  .to(GetCitiesByIdsUseCase)
  .inSingletonScope();
containerTest.bind(INJECTION_TOKENS.GET_REGIONS_USECASE).to(GetRegionsUseCase).inSingletonScope();
containerTest
  .bind(INJECTION_TOKENS.GET_AUDIENCES_USECASE)
  .to(GetAudiencesUseCase)
  .inSingletonScope();
containerTest
  .bind(INJECTION_TOKENS.DV360_CREATE_ALGO_USECASE)
  .to(CreateAlgorithmUsecase)
  .inSingletonScope();

export { containerTest };
