import { inject, injectable } from 'inversify';
import INJECTION_TOKENS from '../../generic/ioc/injection-tokens.ts';
import type { ClientPort } from '../port/client.port.ts';
import { Client } from '../model/client.ts';

@injectable()
export class GetClientsUseCase {
  constructor(
    @inject(INJECTION_TOKENS.CLIENT_REPOSITORY)
    private readonly clientRepository: ClientPort
  ) {}

  async execute(): Promise<Client[]> {
    return this.clientRepository.getClients();
  }
}
