import { inject, injectable } from 'inversify';
import { ChangelogPort } from '../../../../core/dv360/port/changelog.port.ts';
import INJECTION_TOKENS from '../../../../core/generic/ioc/injection-tokens.ts';
import type SimpleHttpService from '../../../../core/generic/simple-http-service.port.ts';
import { ChangeEntry } from '../../../../core/dv360/model/change-entry.ts';
import { ChangelogEntryProps, Dv360ChangelogMapper } from './dv360-changelog.mapper.ts';

@injectable()
export class Dv360ChangelogRepository implements ChangelogPort {
  constructor(
    @inject(INJECTION_TOKENS.DV360_API_HTTP_SERVICE)
    private readonly httpService: SimpleHttpService,
    @inject(INJECTION_TOKENS.DV360_CHANGELOG_MAPPER)
    private readonly mapper: Dv360ChangelogMapper
  ) {}

  async generateChangelog(
    clientId: string,
    partnerId: string,
    advertiserId: string,
    algorithmId: string
  ): Promise<ChangeEntry[]> {
    const response = await this.httpService.post<ChangelogEntryProps[]>(
      `/v1/generate-scripts-changelog`,
      {
        client_id: clientId,
        partner_id: partnerId,
        advertiser_id: advertiserId,
        algorithm_id: algorithmId,
      }
    );
    return response.map(this.mapper.toDeserialized);
  }
}
