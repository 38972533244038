import {
  FormControl,
  Select,
  MenuItem,
  Button,
  SelectChangeEvent,
  Stack,
  InputLabel,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { BidForm, useBidAdjustmentStore } from '../../../../../../core/meta/store/bid-adjustment-store.ts';
import { RenderForm } from './RenderForm';
import { DeleteForm } from './DeleteForm';
import {
  AgeFormValues,
  DevicePlatformFormValues,
  GenderFormValues,
  LocationFormValues,
  mappingOptions,
  PositionTypeFormValues,
  PublisherPlatformFormValues,
  UserOSFormValues,
  ValuesForm,
} from '../../../../../../core/meta/model/bid-form.ts';
import { isEqual } from 'lodash';

export const CategoryForm = ({
  indexes,
  bidForm,
  shouldDisplayAddButton,
  displayArrow = false,
  parentBidForm,
  currentLevel = 0,
  remainingCategories,
  disableCategorySelect = false,
}: {
  indexes: number[];
  bidForm: BidForm;
  shouldDisplayAddButton: boolean;
  displayArrow?: boolean;
  parentBidForm?: BidForm;
  currentLevel?: number;
  remainingCategories: string[];
  disableCategorySelect: boolean;
}) => {
  const updateBidForm = useBidAdjustmentStore((state) => state.updateBidForm);
  const setNewBidForm = useBidAdjustmentStore((state) => state.setNewBidForm);
  const deleteBidForm = useBidAdjustmentStore((state) => state.deleteBidForm);
  const bidForms = useBidAdjustmentStore((state) => state.bidForms);
  const deleteAllExceptFirstBidForm = useBidAdjustmentStore(
    (state) => state.deleteAllExceptFirstBidForm
  );

  const handleValueChange = (values: ValuesForm) => {
    bidForm.values = values;
    updateBidForm(indexes, bidForm);
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    if (parentBidForm) {
      parentBidForm.subBidForm = [
        {
          category: event.target.value,
          subBidForm: [],
        },
      ];
      const newIndexes = [...indexes];
      newIndexes.splice(-1, 1);
      updateBidForm(newIndexes, parentBidForm);
    }

    if (currentLevel === 0) {
      updateBidForm(indexes, {
        category: event.target.value, // bidForm.category === '' ? event.target.value : bidForm.category,
        subBidForm: [],
      });
      deleteAllExceptFirstBidForm();
    }
  };

  const addNewBidForm = () => {
    if (currentLevel === 0) {
      setNewBidForm({ category: bidForm.category, subBidForm: [] });
    } else if (parentBidForm) {
      parentBidForm.subBidForm.push({
        category: bidForm.category,
        subBidForm: [],
      });
      const newIndexes = [...indexes];
      newIndexes.splice(-1, 1);
      updateBidForm(newIndexes, parentBidForm);
    }
  };
  const updateBidFormLocal = () => {
    bidForm.subBidForm.push({ category: '', subBidForm: [] });
    updateBidForm(indexes, bidForm);
  };

  const deleteBidFormLocal = () => {
    deleteBidForm(indexes);
  };

  const getExcludeValues = () => {
    let excludeValues: string[] = [];
    const bidFormsToBeFiltered = parentBidForm ? parentBidForm.subBidForm : bidForms;
    excludeValues = bidFormsToBeFiltered
      .filter((form) => !isEqual(form, bidForm))
      .filter((form) => form.category === bidForm.category && form.values)
      .reduce((acc: string[], form) => {
        const relevantKey =
          form.category !== 'home_location'
            ? mappingOptions?.[bidForm.category] || bidForm.category
            : (bidForm?.values as LocationFormValues)?.subcategory;

        if (relevantKey) {
          const values = form.values as unknown as { [key: string]: string[] | string };
          if (values[relevantKey]) {
            return acc.concat(values[relevantKey] as string[]);
          }
        }
        const { custom_events, options } = form.values as unknown as {
          [key: string]: string[] | string;
        };
        if (custom_events) {
          acc = acc.concat(custom_events);
        }
        if (options) {
          acc = acc.concat(options);
        }
        return acc;
      }, []);

    return excludeValues;
  };

  const checkIfDefaultValueIsOn = ({ category, values }: BidForm) => {
    return (
      values &&
      ((category === 'home_location' &&
        (values as LocationFormValues)?.subcategory === 'default') ||
        (category === 'age' && (values as AgeFormValues)?.ageRanges.includes('default')) ||
        (category === 'gender' && (values as GenderFormValues)?.gender === 'default') ||
        (category === 'device_platform' &&
          (values as DevicePlatformFormValues)?.device === 'default') ||
        (category === 'publisher_platform' &&
          (values as PublisherPlatformFormValues)?.publisher === 'default') ||
        (category === 'user_os' && (values as UserOSFormValues)?.userOS === 'default') ||
        (category === 'position_type' &&
          (values as PositionTypeFormValues)?.positionType === 'default') ||
        false)
    );
  };

  return (
    <div style={{ marginBottom: '32px' }}>
      <Stack direction="row" alignItems={'center'} gap={2}>
        {displayArrow && <SubdirectoryArrowRightIcon />}
        <FormControl sx={{ width: '180px' }}>
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            labelId="category-label"
            id="category"
            value={bidForm.category}
            label="Category"
            onChange={handleChange}
            disabled={disableCategorySelect}
          >
            {remainingCategories.map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {bidForm.category && (
          <RenderForm
            category={bidForm.category}
            values={bidForm.values}
            onChange={handleValueChange}
            disabled={bidForm.subBidForm.length > 0}
            excludeValues={getExcludeValues()}
          ></RenderForm>
        )}
        {bidForm.category && <DeleteForm onClick={deleteBidFormLocal}></DeleteForm>}
      </Stack>

      <Stack direction="row" justifyContent={'start'} sx={{ minHeight: '16px' }}>
        {shouldDisplayAddButton && bidForm.category !== '' && (
          <Button
            disabled={bidForm.category === ''}
            size="small"
            sx={{ width: '200px', fontSize: '0.7125rem' }}
            onClick={() => addNewBidForm()}
            startIcon={<AddIcon />}
          >
            Add another {bidForm.category}
          </Button>
        )}
        {bidForm.category !== '' &&
          bidForm.subBidForm.length === 0 &&
          !checkIfDefaultValueIsOn(bidForm) && (
            <Stack direction="row" justifyContent={'start'}>
              <Button
                disabled={bidForm.category === ''}
                size="small"
                sx={{ width: '200px', fontSize: '0.7125rem' }}
                onClick={updateBidFormLocal}
                startIcon={<SubdirectoryArrowRightIcon />}
              >
                Add a sub-category
              </Button>
            </Stack>
          )}
      </Stack>

      {!!bidForm.subBidForm.length &&
        bidForm.subBidForm.map((subBidForm: BidForm, index: number) => {
          const newIndexes = [...indexes, index];
          const key = subBidForm.category + index + currentLevel;
          return (
            <div key={key} style={{ marginLeft: '48px' }}>
              <CategoryForm
                key={key}
                indexes={newIndexes}
                bidForm={subBidForm}
                shouldDisplayAddButton={index === bidForm.subBidForm.length - 1}
                displayArrow={true}
                parentBidForm={bidForm}
                currentLevel={currentLevel + 1}
                remainingCategories={remainingCategories.filter(
                  (category) => category !== bidForm.category
                )}
                disableCategorySelect={index !== 0}
              ></CategoryForm>
            </div>
          );
        })}
    </div>
  );
};
