import { Checkbox, CircularProgress, TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { LocationFormValueToSend, RenderLocationFormProps } from './location-form-props.ts';
import { SyntheticEvent, useEffect, useState } from 'react';
import { City } from '../../../../../../../core/meta/model/location.ts';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { container } from '../../../../../../../core/generic/ioc/container.ts';
import { GetCitiesUseCase } from '../../../../../../../core/meta/usecase/get-cities-usecase.ts';
import INJECTION_TOKENS from '../../../../../../../core/generic/ioc/injection-tokens.ts';
import { GetCitiesByIdsUseCase } from '../../../../../../../core/meta/usecase/get-cities-by-ids-usecase.ts';
import { useBidAdjustmentStore } from '../../../../../../../core/meta/store/bid-adjustment-store.ts';

export const CityForm = ({
  subcategory,
  onChange,
  excludeValues,
  locationFormValues,
}: RenderLocationFormProps) => {
  // Local state for values
  const [values, setValues] = useState<City[]>([]);
  // Local state for values
  const [selectedCities, setSelectedCities] = useState<City[]>([]);
  // Local state from input value
  const [inputValue, setInputValue] = useState('');
  // Local state for loading
  const [loading, setLoading] = useState(false);

  const isEditMode = useBidAdjustmentStore((state) => state.isEditMode);
  const notEditMode = useBidAdjustmentStore((state) => state.notEditMode);

  const getCitiesUsecase = container.get<GetCitiesUseCase>(INJECTION_TOKENS.GET_CITIES_USECASE);
  const getCitiesByIdsUsecase = container.get<GetCitiesByIdsUseCase>(
    INJECTION_TOKENS.GET_CITIES_BY_IDS_USECASE
  );

  /**
   * This useEffect is used to fetch the cities that are already selected in the parent component.
   * This is done to display the selected cities in the autocomplete list.
   * This is only done when the user is in edit mode.
   * This is done to avoid making a request for cities that are already selected.
   */
  useEffect(() => {
    if (isEditMode && locationFormValues?.cities?.length) {
      setLoading(true);

      const debounceTimer = setTimeout(async () => {
        try {
          const cities = await getCitiesByIdsUsecase.execute(locationFormValues.cities || []);
          // set selected cities in the state
          if (cities) setSelectedCities(cities);
          notEditMode();
        } catch (error) {
          console.error('Error fetching cities:', error);
        } finally {
          setLoading(false);
        }
      }, 500);

      return () => clearTimeout(debounceTimer);
    }
  }, [isEditMode, getCitiesByIdsUsecase, locationFormValues, notEditMode]);

  useEffect(() => {
    /**
     * When the user selects a city from the autocomplete list, the request is triggered because of the
     * excluded list variable. Since a useEffect works with the states it calls. Whenever the excluded list
     * change it triggers the useEffect therefore the request is triggered.
     *
     * The input is systematically empty when the user request has been made.
     * Therefore, we can avoid the request by checking if the input value is empty.
     */
    if (inputValue.trim() === '') {
      return;
    }

    setLoading(true);

    // Fetch cities from store with debounce to avoid having to many requests
    const debounceTimer = setTimeout(async () => {
      try {
        const cities = await getCitiesUsecase.execute(inputValue);
        // Reinitialize input value
        setInputValue('');
        const filteredCities = cities.filter((city) => !excludeValues?.includes(city.key));
        if (cities) setValues(filteredCities);
      } catch (error) {
        console.error('Error fetching cities:', error);
      } finally {
        setLoading(false);
      }
    }, 500);

    return () => clearTimeout(debounceTimer);
  }, [inputValue, getCitiesUsecase, excludeValues]);

  /**
   * When the user is in edit mode, we need to merge the selected cities from the parent component
   * with the cities that are already in the store.
   */
  useEffect(() => {
    if (selectedCities.length && isEditMode) {
      /**
       * Create a map to store unique cities by key.
       * This is done to avoid duplicates in the list.
       *
       * It's needed because if selectedCities contains cities that are already present in values,
       * the spread operator will merge them, resulting in duplicate keys.
       */
      setValues([
        ...new Map([...values, ...selectedCities].map((item) => [item.key, item])).values(),
      ]);
    }
  }, [values, selectedCities, isEditMode]);

  const handleAutocompleteChange = (
    _event: SyntheticEvent<Element, Event>,
    values: City[] | null
  ) => {
    // Send values to parent component
    const valueToSend: LocationFormValueToSend = {
      name: 'cities',
      values: values?.map((value) => value.key),
    };
    if (valueToSend) {
      onChange(valueToSend);
      setSelectedCities(values || []);
    }
  };

  // Handle input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Update local state with input value
    setInputValue(e.target.value);
  };

  return (
    <>
      {isEditMode && loading && locationFormValues?.cities?.length ? (
        <CircularProgress />
      ) : (
        <Autocomplete
          id="location-category-country"
          options={values}
          value={selectedCities}
          limitTags={1}
          loading={loading}
          multiple
          disableCloseOnSelect
          isOptionEqualToValue={(option, value) => option.key === value.key}
          getOptionLabel={(option) => option.name}
          onChange={handleAutocompleteChange}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.key}>
              <Checkbox
                key={option.key}
                name={option.name}
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name} - ({option.country_code})
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label={subcategory} onChange={handleInputChange} />
          )}
        />
      )}
    </>
  );
};
