import { useEffect, useState } from 'react';
import { BiddingUser } from '../../../../../core/meta/model/bidding-user.ts';
import { User } from '@auth0/auth0-spa-js';
import { useAuth0 } from '@auth0/auth0-react';

export function useBiddingUser() {
  const { user } = useAuth0();
  const [biddingUser, setBiddingUser] = useState<User | null>(null);
  const [isUserBiddingPublisher, setIsUserBiddingPublisher] = useState<boolean>(false);

  useEffect(() => {
    const biddingUserFromAuth0 = new BiddingUser();
    if (!user) return;
    setBiddingUser(user);
    setIsUserBiddingPublisher(biddingUserFromAuth0.isPublisher(user));
  }, [biddingUser, user, setIsUserBiddingPublisher]);

  return { biddingUser, isUserBiddingPublisher };
}
