import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMetaStore } from '../../../../../../../core/meta/store/meta-store.ts';

const usePromotedObject = () => {
  const setAdSetAndPromotedObject = useMetaStore((state) => state.setAdSetAndPromotedObject);

  const { accountId } = useParams();
  useEffect(() => {
    setAdSetAndPromotedObject(accountId as string);
  }, [setAdSetAndPromotedObject, accountId]);
};

export default usePromotedObject;
