import { injectable } from 'inversify';
import { Bid, BidApi } from '../../../../core/meta/model/bid.ts';
import { BidPort, BidResponse } from '../../../../core/meta/port/bid.port.ts';

@injectable()
export class InMemoryBidRepository implements BidPort {
  bid: BidApi = { user_groups: {} };

  setBid(bid: BidApi): void {
    this.bid = bid;
  }

  async setBids(_adsetIds: string[], bidAdjustment: Bid): Promise<BidResponse> {
    this.bid = { user_groups: bidAdjustment };
    return {
      error: [],
      success: [],
      success_no_history: [],
      error_messages: [],
    };
  }

  async saveDraftBidAdjustment(_adsetIds: string[], bidAdjustment: Bid): Promise<BidResponse> {
    this.bid = { user_groups: bidAdjustment };
    return {
      error: [],
      success: [],
      success_no_history: [],
      error_messages: [],
    };
  }

  async getBidHistory(): Promise<BidApi> {
    return this.bid;
  }
}
