import { Box, Button, Card, CardContent, Stack, Typography, styled } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { BoxSx, ButtonSx, ContentTypo, StackSx, TitleTypo } from '../styles/elements';
import { useNavigate } from 'react-router-dom';

const H5 = styled('h5')(({ theme }) => ({
  marginTop: 0,
  marginBottom: theme.spacing(4),
}));

const Resources = () => {
  const navigation = useNavigate();

  const navigate = (url: string) => {
    navigation(url);
  };
  return (
    <div>
      <Button
        onClick={() => {
          navigate('/');
        }}
        sx={{ ...ButtonSx, fontSize: '12px' }}
      >
        <ArrowBackIcon sx={{ mr: 0.5 }} fontSize="inherit" />
        Home
      </Button>
      <h2 style={{ margin: '0' }}>Resource Library</h2>
      <Typography variant="caption">Understand the Meta Bid Adjustements</Typography>

      <Stack spacing={2} direction="row" sx={StackSx}>
        <Box sx={BoxSx}>
          <Card variant="outlined">
            <CardContent>
              <H5>Something</H5>
              <ContentTypo variant="caption">
                blabla blabla blablabla blabla blablabla blabla blablabla blabla blablabla blabla
                blablabla blabla bla
              </ContentTypo>
              <TitleTypo variant="caption">Awesome Title:</TitleTypo>
              
            </CardContent>
          </Card>
        </Box>
      </Stack>
    </div>
  );
};

export default Resources;
