import { Button } from '@mui/material';
import { useMetaStore } from '../../../../../core/meta/store/meta-store.ts';
import {
  BidFormStatusType,
  useBidAdjustmentStore,
} from '../../../../../core/meta/store/bid-adjustment-store.ts';
import { BidApi } from '../../../../../core/meta/model/bid.ts';
import INJECTION_TOKENS from '../../../../../core/generic/ioc/injection-tokens';
import { AnalyticsPort } from '../../../../../core/meta/port/analytics.port.ts';
import { useInjection } from '../../../ioc/use-injection';

type EditButtonProps = {
  id: string;
  campaignId: string;
  bidAdjustments?: BidApi;
  isReviewEdit?: boolean;
  disabled?: boolean;
};

export const EditBidButton = ({
  id,
  campaignId,
  bidAdjustments,
  isReviewEdit = false,
  disabled = false,
}: EditButtonProps) => {
  const analytics = useInjection<AnalyticsPort>(INJECTION_TOKENS.ANALYTICS_REPOSITORY);

  const setSelectedAdsets = useMetaStore((state) => state.setSelectedAdsetsPerCampaign);
  const openDrawer = useBidAdjustmentStore((state) => state.openDrawer);
  const resetBidForm = useBidAdjustmentStore((state) => state.resetBidForm);
  const setBidFormsFromBid = useBidAdjustmentStore((state) => state.setBidFormsFromBid);
  const nextStep = useBidAdjustmentStore((state) => state.nextStep);
  const getBidHistory = useBidAdjustmentStore((state) => state.getBidHistory);
  const setBidFormStatus = useBidAdjustmentStore((state) => state.setBidFormStatus);

  const sendAnalytics = () => {
    const name = isReviewEdit ? 'edit_draft' : bidAdjustments ? 'edit_bid' : 'create_bid';
    analytics.send({ name, payload: { adset_id: id } });
  };

  return (
    <Button
      size="small"
      disabled={disabled}
      onClick={async (e) => {
        e.stopPropagation();
        setSelectedAdsets([id], campaignId);
        resetBidForm();
        sendAnalytics();
        if (isReviewEdit) {
          setBidFormStatus(BidFormStatusType.REVIEW);
          await getBidHistory(id);
        }
        openDrawer();
        if (bidAdjustments && !isReviewEdit) {
          setBidFormStatus(BidFormStatusType.EDIT);
          setBidFormsFromBid(bidAdjustments);
        }
        nextStep();
      }}
    >
      {isReviewEdit
        ? 'Review draft'
        : bidAdjustments
          ? 'Edit Bid Multiplier'
          : 'Create Bit Multiplier'}
    </Button>
  );
};
