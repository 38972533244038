import { GetStartedCardProps } from '../components/GetStartedCard';

type HomeConfig = {
  analysis: GetStartedCardProps[];
  resources: GetStartedCardProps[];
};
export const homePageConfig: HomeConfig = {
  analysis: [
    {
      title: 'Go to META clients',
      description: 'Simplify your bid adjustments.',
      buttonLabel: 'Get Started',
      link: '/meta',
      icons: ['meta'],
    },
    {
      title: 'Go to DV360 clients',
      description: 'Simplify your scoring and reporting.',
      buttonLabel: 'Get Started',
      link: '/dv360',
      icons: ['dv360'],
    },
  ],
  resources: [
    {
      title: 'Meta in Action',
      description:
        "Connect with a community passionate about Meta by joining the Jellyfish Meta Slack Channel. Here, you'll find a platform for sharing ideas, insights, and the latest in eco-friendly initiatives within the industry. Take part in the conversation!",
      buttonLabel: 'Join the slack channel',
      // link: null,
    },
  ],
};
