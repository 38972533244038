import { CodeiumEditor } from '@codeium/react-code-editor';
import { debounce, FormHelperText, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { useCallback, useState } from 'react';
import { SCRIPT_DEFAULT_VALUE } from '../../../../../core/dv360/model/constant.ts';
import { useCustomBidStore } from '../../../../../core/dv360/store/custom-bid-store';

export const AlgorithmScriptEditor = () => {
  // STORE
  const scriptValue = useCustomBidStore((state) => state.scriptValue);
  const setScriptValue = useCustomBidStore((state) => state.setScriptValue);
  const isInCreationAlgorithmMode = useCustomBidStore((state) => state.isInCreationAlgorithmMode);
  const isInitEditAlgorithmInProgress = useCustomBidStore(
    (state) => state.isInitEditAlgorithmInProgress
  );

  // LOCAL STATE
  const [isScriptValid, setIsScriptValid] = useState<boolean>(true);

  const containerSx: SxProps = {
    height: '500px',
  };

  const editorContainerSx: SxProps = {
    height: '500px',
    margin: '16px',
    padding: '5px',
    border: isScriptValid ? '' : '1px solid #f44336',
    borderRadius: '5px',
  };

  const helperTextSx: SxProps = {
    marginLeft: '16px',
  };

  const footerSx: SxProps = {
    height: '48px',
    minHeight: '48px',
    maxHeight: '48px',
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(
    debounce((value) => {
      console.log('on change after debounced', value);
      setScriptValue(value);
    }, 500),
    []
  );

  const handleChange = (value: string = '') => {
    setIsScriptValid(!!value);
    debouncedChangeHandler(value);
  };

  const isEditorInReaderOnly = () => {
    // In modification mode and when screen initialization is in progress
    return !isInCreationAlgorithmMode && isInitEditAlgorithmInProgress;
  };

  return (
    <Stack sx={containerSx}>
      <Stack sx={editorContainerSx}>
        <CodeiumEditor
          language="python"
          theme="light"
          height={'95%'}
          value={isInCreationAlgorithmMode ? SCRIPT_DEFAULT_VALUE : scriptValue}
          onChange={handleChange}
          options={{
            readOnly: isEditorInReaderOnly(),
          }}
        />
      </Stack>
      <Stack sx={helperTextSx}>
        <FormHelperText error>{isScriptValid ? '' : 'Script is required'}</FormHelperText>
      </Stack>
      <Stack sx={footerSx} />
    </Stack>
  );
};
