import { injectable } from 'inversify';
import { Advertiser } from '../../../../core/dv360/model/advertiser.ts';
import { AlgorithmType, EntityStatus } from '../../../../core/dv360/model/constant.ts';
import { Algorithm } from '../../../../core/dv360/model/algorithm.ts';

export type Dv360AlgorithmProps = {
  id: string;
  name: string;
  display_name: string;
  advertiser_id: string;
  type: string;
  status: string;
};

export type Dv360AdvertiserProps = {
  id: string;
  name: string;
  display_name: string;
  status: string;
  partner_id: string;
  updated_at: string;
  algorithms: Dv360AlgorithmProps[];
};

@injectable()
export class Dv360AdvertiserMapper {
  toDeserialized(props: Dv360AdvertiserProps): Advertiser {
    return {
      id: props.id,
      name: props.display_name,
      partnerId: props.partner_id,
      status: props.status as EntityStatus,
      updatedAt: new Date(props.updated_at),
      algorithms: props.algorithms.map(
        (algorithm: Dv360AlgorithmProps): Algorithm => ({
          id: algorithm.id,
          name: algorithm.display_name,
          advertiserId: algorithm.advertiser_id,
          type: algorithm.type as AlgorithmType,
          status: algorithm.status as EntityStatus,
        })
      ),
    };
  }
}
