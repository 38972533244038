import { inject, injectable } from 'inversify';
import { Advertiser } from '../../../../core/dv360/model/advertiser.ts';
import { Dv360AdvertiserPort } from '../../../../core/dv360/port/dv360-advertiser.port.ts';
import INJECTION_TOKENS from '../../../../core/generic/ioc/injection-tokens.ts';
import type SimpleHttpService from '../../../../core/generic/simple-http-service.port.ts';
import { Dv360AdvertiserMapper, Dv360AdvertiserProps } from './dv360-advertiser.mapper.ts';

@injectable()
export class Dv360AdvertiserRepository implements Dv360AdvertiserPort {
  constructor(
    @inject(INJECTION_TOKENS.DV360_API_HTTP_SERVICE)
    private readonly httpService: SimpleHttpService,
    @inject(INJECTION_TOKENS.DV360_ADVERTISER_MAPPER) private readonly mapper: Dv360AdvertiserMapper
  ) {}

  async getAdvertisers(clientId: string, partnerId: string): Promise<Advertiser[]> {
    const advertisersProps = await this.httpService.get<Dv360AdvertiserProps[]>(
      `/v1/clients/${clientId}/partners/${partnerId}/advertisers`,
      { params: { with_algorithms: true } }
    );
    return advertisersProps.map(this.mapper.toDeserialized);
  }
}
