import { PromotedObject, PromotedObjectToDisplay } from '../model/campaign.ts';

export class PromotedObjectService {
  getPromotedObjectToDisplay(promotedObject: PromotedObject | undefined): PromotedObjectToDisplay {
    if (!promotedObject) {
      return { valueToDisplay: '', value: '' };
    }
    const labels = {
      page_id: 'Page ID',
      app_id: 'App ID',
      pixel_id: 'Pixel ID',
      event_id: 'Event ID',
      mcme_conversion_id: 'MCME Conversion ID',
    };

    return (Object.keys(labels) as (keyof PromotedObject)[]).reduce((acc, key) => {
      const value = promotedObject[key] as string;
      if (value) {
        acc = {
          valueToDisplay: `${labels[key]}: ${value}`,
          value: value,
        };
      }
      return acc;
    }, {} as PromotedObjectToDisplay);
  }
}
