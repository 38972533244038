import { injectable } from 'inversify';
import { AnalyticsEvent, AnalyticsPort } from '../../../../core/meta/port/analytics.port.ts';
import ReactGA from 'react-ga4';

@injectable()
export class GoogleAnalyticsRepository implements AnalyticsPort {
  send({ name, payload }: AnalyticsEvent): void {
    ReactGA.event(name, payload);
  }
}
