import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingButton } from '@mui/lab';
import { AppBar, Button, Container, Divider, IconButton, Toolbar, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { Box, SxProps } from '@mui/system';
import { useCustomBidStore } from '../../../../../core/dv360/store/custom-bid-store.ts';

const linearProgressSx: SxProps = {
  width: '100%',
};

const headerContainerSx: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flex: 1,
  justifyContent: 'stretch',
};
const containerStyle = {
  display: 'contents',
  height: '100%',
  alignItems: 'center',
};

const textSx: SxProps = { marginLeft: '0.875rem', fontWeight: 600 };
const appBarSx: SxProps = { height: '63px', backgroundColor: 'white' };

const cancelButtonSx: SxProps = {
  marginRight: '16px',
};

type CustomBidTopBarProps = {
  clientName: string;
  partnerName: string;
  onClickSendButton: () => void;
  onClickCancelButton: () => void;
};

export const AlgorithmTopBar = ({
  clientName,
  partnerName,
  onClickSendButton,
  onClickCancelButton,
}: CustomBidTopBarProps) => {
  const closeDrawer = useCustomBidStore((state) => state.closeDrawer);
  const loading = useCustomBidStore((state) => state.isLoading);
  const setDefaultScriptValue = useCustomBidStore((state) => state.setDefaultScriptValue);
  const resetEditAlgorithmValues = useCustomBidStore((state) => state.resetEditAlgorithmValues);
  const isInCreationAlgorithmMode = useCustomBidStore((state) => state.isInCreationAlgorithmMode);
  const isInitEditAlgorithmInProgress = useCustomBidStore(
    (state) => state.isInitEditAlgorithmInProgress
  );

  const handleCloseDrawer = () => {
    setDefaultScriptValue();
    resetEditAlgorithmValues();
    closeDrawer();
  };

  const handelCancel = () => {
    onClickCancelButton();
  };

  return (
    <AppBar position="static" color="transparent" sx={appBarSx}>
      <Toolbar variant="dense">
        <Box sx={headerContainerSx}>
          <IconButton color="inherit" aria-label="delete" onClick={handleCloseDrawer}>
            <ArrowBackIcon></ArrowBackIcon>
          </IconButton>
          <Typography sx={textSx} variant="h6">
            {isInCreationAlgorithmMode ? 'Create new algorithm' : 'Modify algorithm'}
          </Typography>
          <Divider orientation="vertical" flexItem sx={{ margin: '0px 16px' }} />
          <div>
            <Typography>{clientName}</Typography>
            <Typography color="secondary" variant="caption">
              {partnerName}
            </Typography>
          </div>
        </Box>
        <Container sx={containerStyle}>
          <Button
            size="small"
            color="secondary"
            variant="text"
            sx={cancelButtonSx}
            onClick={handelCancel}
            disabled={isInitEditAlgorithmInProgress}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => onClickSendButton()}
            loading={loading}
            color="primary"
            variant="contained"
            disabled={isInitEditAlgorithmInProgress}
          >
            Send
          </LoadingButton>
        </Container>
      </Toolbar>
      {isInitEditAlgorithmInProgress && <LinearProgress sx={linearProgressSx} />}
    </AppBar>
  );
};
