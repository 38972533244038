import { Container } from 'inversify';
import { AuthRepository } from '../../../adapters/driven/auth/auth.repository.ts';
import { Dv360AdvertiserMapper } from '../../../adapters/driven/dv360/advertiser/dv360-advertiser.mapper.ts';
import { Dv360AdvertiserRepository } from '../../../adapters/driven/dv360/advertiser/dv360-advertiser.repository.ts';
import { Dv360WeightScriptGenerationMapper } from '../../../adapters/driven/dv360/algorithm/dv360-weight-script-generation.mapper.ts';
import { Dv360ChangelogMapper } from '../../../adapters/driven/dv360/changelog/dv360-changelog.mapper.ts';
import { Dv360ChangelogRepository } from '../../../adapters/driven/dv360/changelog/dv360-changelog.repository.ts';
import { Dv360FloodlightMapper } from '../../../adapters/driven/dv360/floodlight/dv360-floodlight.mapper.ts';
import { Dv360FloodlightRepository } from '../../../adapters/driven/dv360/floodlight/dv360-floodlight.repository.ts';
import { Dv360IoRepository } from '../../../adapters/driven/dv360/io/dv360-io.repository.ts';
import { AxiosDv360HttpService } from '../../../adapters/driven/generic/http/axios-dv360-http-service.ts';
import { AxiosMetaHttpService } from '../../../adapters/driven/generic/http/axios-meta-http-service.ts';
import { GoogleAnalyticsRepository } from '../../../adapters/driven/meta/analytics/google-analytics-repository.ts';
import { AudienceRepository } from '../../../adapters/driven/meta/audience/audience-repository.ts';
import { BidRepository } from '../../../adapters/driven/meta/bid/bid-repository.ts';
import { CampaignRepository } from '../../../adapters/driven/meta/campaign/campaign-repository.ts';
import { ClientRepository } from '../../../adapters/driven/meta/client/client-repository.ts';
import { LocationRepository } from '../../../adapters/driven/meta/location/location-repository.ts';
import { UserRepository } from '../../../adapters/driven/user/user-repository.ts';
import { Dv360AdvertiserPort } from '../../dv360/port/dv360-advertiser.port.ts';
import { ChangelogPort } from '../../dv360/port/changelog.port.ts';
import { Dv360FloodlightPort } from '../../dv360/port/dv360-floodlight.port.ts';
import { Dv360IoPort } from '../../dv360/port/dv360-io.port.ts';
import { CreateAlgorithmUsecase } from '../../dv360/usecase/create-algorithm.usecase.ts';
import { AnalyticsPort } from '../../meta/port/analytics.port.ts';
import { AudiencePort } from '../../meta/port/audience.port.ts';
import { BidPort } from '../../meta/port/bid.port.ts';
import { CampaignPort } from '../../meta/port/campaign.port.ts';
import { ClientPort } from '../../meta/port/client.port.ts';
import { LocationPort } from '../../meta/port/location.port.ts';
import { GetAudiencesUseCase } from '../../meta/usecase/get-audiences-usecase.ts';
import { GetCitiesByIdsUseCase } from '../../meta/usecase/get-cities-by-ids-usecase.ts';
import { GetCitiesUseCase } from '../../meta/usecase/get-cities-usecase.ts';
import { GetClientsUseCase } from '../../meta/usecase/get-clients-usecase.ts';
import { GetCountriesUseCase } from '../../meta/usecase/get-countries-usecase.ts';
import { GetRegionsUseCase } from '../../meta/usecase/get-regions-usecase.ts';
import { AuthPort } from '../../port/auth.port.ts';
import { UserPort } from '../../port/user.port.ts';
import { FileService } from '../../service/file.service.ts';
import { CanUserAccessUsecase } from '../../usecase/can-user-access-usecase.ts';
import { CreateUserUsecase } from '../../usecase/create-user-usecase.ts';
import HttpService from '../http-service.port.ts';
import SimpleHttpService from '../simple-http-service.port.ts';
import INJECTION_TOKENS from './injection-tokens.ts';
import { containerTest } from './test-container.ts';
import { Dv360ClientPort } from '../../dv360/port/dv360-client.port.ts';
import { Dv360ClientRepository } from '../../../adapters/driven/dv360/client/dv360-client-repository.ts';
import { Dv360AlgorithmPort } from '../../dv360/port/dv360-algorithm.port.ts';
import { Dv360AlgorithmRepository } from '../../../adapters/driven/dv360/algorithm/dv360-algorithm.repository.ts';

const containerApp = new Container();
/** SERVICES */
containerApp
  .bind<HttpService>(INJECTION_TOKENS.META_API_HTTP_SERVICE)
  .to(AxiosMetaHttpService)
  .inSingletonScope();

containerApp
  .bind<SimpleHttpService>(INJECTION_TOKENS.DV360_API_HTTP_SERVICE)
  .to(AxiosDv360HttpService)
  .inSingletonScope();

containerApp
  .bind<Dv360AdvertiserMapper>(INJECTION_TOKENS.DV360_ADVERTISER_MAPPER)
  .to(Dv360AdvertiserMapper)
  .inSingletonScope();

containerApp.bind<FileService>(INJECTION_TOKENS.FILE_SERVICE).to(FileService).inSingletonScope();

/** REPOSITORIES */
containerApp.bind<AuthPort>(INJECTION_TOKENS.AUTH_REPOSITORY).to(AuthRepository).inSingletonScope();
containerApp
  .bind<ClientPort>(INJECTION_TOKENS.CLIENT_REPOSITORY)
  .to(ClientRepository)
  .inSingletonScope();
containerApp
  .bind<CampaignPort>(INJECTION_TOKENS.CAMPAIGN_REPOSITORY)
  .to(CampaignRepository)
  .inSingletonScope();
containerApp.bind<UserPort>(INJECTION_TOKENS.USER_REPOSITORY).to(UserRepository).inSingletonScope();
containerApp.bind<BidPort>(INJECTION_TOKENS.BID_REPOSITORY).to(BidRepository).inSingletonScope();
containerApp
  .bind<AudiencePort>(INJECTION_TOKENS.AUDIENCE_REPOSITORY)
  .to(AudienceRepository)
  .inSingletonScope();
containerApp
  .bind<LocationPort>(INJECTION_TOKENS.LOCATION_REPOSITORY)
  .to(LocationRepository)
  .inSingletonScope();
containerApp
  .bind<AnalyticsPort>(INJECTION_TOKENS.ANALYTICS_REPOSITORY)
  .to(GoogleAnalyticsRepository)
  .inSingletonScope();
containerApp
  .bind<Dv360ClientPort>(INJECTION_TOKENS.DV360_CLIENT_REPOSITORY)
  .to(Dv360ClientRepository)
  .inSingletonScope();
containerApp
  .bind<Dv360AdvertiserPort>(INJECTION_TOKENS.DV360_ADVERTISER_REPOSITORY)
  .to(Dv360AdvertiserRepository)
  .inSingletonScope();
containerApp
  .bind<Dv360FloodlightPort>(INJECTION_TOKENS.DV360_FLOODLIGHT_REPOSITORY)
  .to(Dv360FloodlightRepository)
  .inSingletonScope();
containerApp
  .bind<Dv360FloodlightMapper>(INJECTION_TOKENS.DV360_FLOODLIGHT_MAPPER)
  .to(Dv360FloodlightMapper)
  .inSingletonScope();
containerApp
  .bind<Dv360IoPort>(INJECTION_TOKENS.DV360_IO_REPOSITORY)
  .to(Dv360IoRepository)
  .inSingletonScope();
containerApp
  .bind<Dv360AlgorithmPort>(INJECTION_TOKENS.DV360_ALGORITHM_REPOSITORY)
  .to(Dv360AlgorithmRepository)
  .inSingletonScope();
containerApp
  .bind<Dv360WeightScriptGenerationMapper>(INJECTION_TOKENS.DV360_WEIGHT_SCRIPT_GENERATION_MAPPER)
  .to(Dv360WeightScriptGenerationMapper)
  .inSingletonScope();
containerApp
  .bind<ChangelogPort>(INJECTION_TOKENS.DV360_CHANGELOG_REPOSITORY)
  .to(Dv360ChangelogRepository)
  .inSingletonScope();
containerApp
  .bind<Dv360ChangelogMapper>(INJECTION_TOKENS.DV360_CHANGELOG_MAPPER)
  .to(Dv360ChangelogMapper)
  .inSingletonScope();

/** USECASES */
containerApp
  .bind(INJECTION_TOKENS.CAN_USER_ACCESS_USECASE)
  .to(CanUserAccessUsecase)
  .inSingletonScope();
containerApp.bind(INJECTION_TOKENS.GET_CLIENTS_USECASE).to(GetClientsUseCase).inSingletonScope();
containerApp.bind(INJECTION_TOKENS.CREATE_USER_USECASE).to(CreateUserUsecase).inSingletonScope();
containerApp
  .bind(INJECTION_TOKENS.GET_COUNTRIES_USECASE)
  .to(GetCountriesUseCase)
  .inSingletonScope();
containerApp.bind(INJECTION_TOKENS.GET_CITIES_USECASE).to(GetCitiesUseCase).inSingletonScope();
containerApp
  .bind(INJECTION_TOKENS.GET_CITIES_BY_IDS_USECASE)
  .to(GetCitiesByIdsUseCase)
  .inSingletonScope();
containerApp.bind(INJECTION_TOKENS.GET_REGIONS_USECASE).to(GetRegionsUseCase).inSingletonScope();
containerApp
  .bind(INJECTION_TOKENS.GET_AUDIENCES_USECASE)
  .to(GetAudiencesUseCase)
  .inSingletonScope();
containerApp
  .bind(INJECTION_TOKENS.DV360_CREATE_ALGO_USECASE)
  .to(CreateAlgorithmUsecase)
  .inSingletonScope();

const container = process.env.NODE_ENV === 'test' ? containerTest : containerApp;
export { container };
