import { injectable } from 'inversify';
import { CampaignPort } from '../../../../core/meta/port/campaign.port.ts';
import { Campaign } from '../../../../core/meta/model/campaign.ts';

@injectable()
export class InMemoryCampaignRepository implements CampaignPort {
  campaigns: Campaign[] = [];
  accountId: string = '';

  setCampaigns(campaigns: Campaign[]): void {
    this.campaigns = campaigns;
  }

  async getCampaigns(accountId: string): Promise<Campaign[]> {
    this.accountId = accountId;
    return this.campaigns;
  }
}
