import { useEffect, useState } from 'react';

interface ItemProps {
  onClick: () => void;
  paused: boolean;
  setHealth: () => void;
}

const peon = {
  width: '20px',
  height: '20px',
  backgroundColor: 'red',
  health: 1,
  speed: 10,
};

const grunt = {
  width: '40px',
  height: '40px',
  backgroundColor: 'orange',
  health: 2,
  speed: 20,
};

const boss = {
  width: '60px',
  height: '60px',
  backgroundColor: 'yellow',
  health: 3,
  speed: 30,
};

const itemsAvailable = [peon, grunt, boss];

const Item = ({ onClick, paused, setHealth }: ItemProps) => {
  const left = Math.random() * (window.screen.width - 20);
  const randomIndex = Math.floor(Math.random() * 3);
  const itemSelected = itemsAvailable[randomIndex];
  const [item, setItem] = useState({ id: Date.now(), top: 0, left, ...itemSelected });

  useEffect(() => {
    if (paused) return;
    const interval = setInterval(() => {
      const newTop = item.top + 0.5;
      const itemHeight = item.height === '20px' ? 20 : item.height === '40px' ? 40 : 60;
      if (newTop >= window.innerHeight - itemHeight) {
        // Item reached the bottom
        setHealth();
        return;
      }

      setItem((item) => {
        return { ...item, top: newTop };
      });
    }, item.speed);

    return () => {
      clearInterval(interval);
    };
  }, [item, setItem, paused, setHealth]);

  const handleOnClick = () => {
    if (paused) return;
    item.health -= 1;
    if (item.health === 0) {
      onClick();
    }
    setItem({ ...item, ...itemsAvailable[item.health - 1], left: item.left + 10 });
  };

  return (
    <div
      className="falling-item"
      key={item.id}
      style={{
        top: item.top,
        left: item.left,
        width: item.width,
        height: item.height,
        backgroundColor: item.backgroundColor,
      }}
      onClick={handleOnClick}
    ></div>
  );
};

export default Item;
