import { inject, injectable } from 'inversify';
import { InsertionOrdersPerAdvertiser } from '../../../../core/dv360/model/insertion-order.ts';
import { Dv360IoPort } from '../../../../core/dv360/port/dv360-io.port.ts';
import INJECTION_TOKENS from '../../../../core/generic/ioc/injection-tokens.ts';
import type SimpleHttpService from '../../../../core/generic/simple-http-service.port.ts';

export type Dv360IoPerAdvertiserProps = {
  advertisers: InsertionOrdersPerAdvertiser;
};

@injectable()
export class Dv360IoRepository implements Dv360IoPort {
  constructor(
    @inject(INJECTION_TOKENS.DV360_API_HTTP_SERVICE) private readonly httpService: SimpleHttpService
  ) {}

  async getIOs(clientId: string, partnerId: string): Promise<InsertionOrdersPerAdvertiser> {
    const response = await this.httpService.get<Dv360IoPerAdvertiserProps>(
      `/v1/clients/${clientId}/partners/${partnerId}/insertion_orders`
    );
    return response.advertisers;
  }
}
