import React, { ChangeEvent } from 'react';
import { Box } from '@mui/system';
import { NumberStep } from '../components/NumberStep';
import { AgeFormValues, FormProps } from '../../../../../../core/meta/model/bid-form.ts';
import { useAgeForm } from './hooks/ageFormHook.ts';
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { bidAdjustmentFieldStyle } from './styles.ts';

export const AgeForm: React.FC<FormProps<AgeFormValues>> = ({
  onChange,
  initialValues,
  disabled,
  excludeValues,
}) => {
  const {
    availableAgeRanges,
    ageFormValues,
    customAgeStart,
    customAgeEnd,
    error,
    handleCustomAgeStartChange,
    handleCustomAgeEndChange,
    addCustomAge,
    handleDelete,
    setAgeFormValues,
  } = useAgeForm(initialValues, onChange, excludeValues);

  const handleChange = (
    e: SelectChangeEvent<typeof ageFormValues.ageRanges> | ChangeEvent<HTMLInputElement>
  ) => {
    const newValue =
      e.target instanceof HTMLInputElement ? parseFloat(e.target.value) : e.target.value;
    const newFormValue = { ...ageFormValues, [e.target.name]: newValue };
    setAgeFormValues(newFormValue);
    onChange(newFormValue);
  };

  return (
    <>
      <FormControl sx={bidAdjustmentFieldStyle}>
        <InputLabel id="age-range-label">Age Range</InputLabel>
        <Select
          sx={{
            '.MuiSelect-select': {
              ...(ageFormValues.ageRanges.length > 0 && {
                padding: '12px 14px',
              }),
            },
          }}
          labelId="age-range-label"
          id="age-range-select"
          name="ageRanges"
          value={ageFormValues.ageRanges}
          label="Age Range"
          multiple
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected
                .filter((value) => {
                  if (value === 'default' || value === '65+') return true;
                  const [start, end] = value.split('-');
                  return (
                    start.trim() !== '' &&
                    end.trim() !== '' &&
                    start.trim() !== '0' &&
                    end.trim() !== '0'
                  );
                })
                .map((value) => (
                  <Chip
                    key={value}
                    label={value}
                    onMouseDown={(e) => e.stopPropagation()}
                    onDelete={handleDelete(value)}
                  />
                ))}
            </Box>
          )}
          onChange={handleChange}
        >
          {availableAgeRanges.map((range) => (
            <MenuItem key={range} value={range}>
              <Checkbox checked={ageFormValues.ageRanges.indexOf(range) > -1} />
              <ListItemText primary={range !== 'default' ? range : 'All other ages'} />
            </MenuItem>
          ))}
          <MenuItem
            value={`${customAgeStart}-${customAgeEnd}`}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <Typography id="custom-age-range" gutterBottom>
              Custom age range
            </Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}
            >
              <TextField
                id="custom-age-start"
                label="Start Age"
                type="number"
                value={customAgeStart}
                onChange={handleCustomAgeStartChange}
                onClick={(e) => e.stopPropagation()}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ width: '40%', pointerEvents: 'auto' }}
              />
              <Typography sx={{ margin: '0 10px' }}>-</Typography>
              <TextField
                id="custom-age-end"
                label="End Age"
                type="number"
                value={customAgeEnd}
                onChange={handleCustomAgeEndChange}
                onClick={(e) => e.stopPropagation()}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ width: '40%', pointerEvents: 'auto' }}
              />
              <Button onClick={addCustomAge} disabled={error != null} sx={{ marginLeft: '10px' }}>
                Add
              </Button>
            </Box>
            {error && (
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            )}
          </MenuItem>
        </Select>
      </FormControl>
      <NumberStep
        disabled={disabled}
        name="value"
        value={ageFormValues.value}
        onChange={handleChange}
      />
    </>
  );
};
