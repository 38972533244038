import { Container } from 'inversify';
import { createContext, FC, ReactElement, ReactNode } from 'react';

type IocProviderProps = {
  container: Container;
  children: ReactNode;
};

export const ProviderContext = createContext<Container | null>(null);

export const IocProvider: FC<IocProviderProps> = ({ container, children }): ReactElement => {
  return <ProviderContext.Provider value={container}>{children}</ProviderContext.Provider>;
};
