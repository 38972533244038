import { inject, injectable } from 'inversify';
import INJECTION_TOKENS from '../../../../core/generic/ioc/injection-tokens.ts';
import { Audience, AudienceAPI } from '../../../../core/meta/model/audience.ts';
import { AudiencePort } from '../../../../core/meta/port/audience.port.ts';
import type HttpService from '../../../../core/generic/http-service.port.ts';

@injectable()
export class AudienceRepository implements AudiencePort {
  url: string = '/v1/platforms/meta';
  constructor(
    @inject(INJECTION_TOKENS.META_API_HTTP_SERVICE) private readonly httpService: HttpService
  ) {}

  async getAudiences(account_id: string): Promise<Audience[]> {
    const response = await this.httpService.get<AudienceAPI[]>(
      `${this.url}/accounts/${account_id}/audiences`
    );

    return response.map((audience) => ({
      id: audience.id,
      name: audience.name,
      deliveryStatus: audience.delivery_status,
    }));
  }
}
