import { Box } from '@mui/system';
import { bidAdjustmentFieldStyle } from '../categories/styles.ts';
import React from 'react';

type AllSelectedAdSetIdsProps = {
  totalCountSelectedAdsets: number;
};

export const SelectedAdSetIdsWarning: React.FC<AllSelectedAdSetIdsProps> = ({
  totalCountSelectedAdsets,
}) => {
  return (
    <Box sx={bidAdjustmentFieldStyle}>
      {totalCountSelectedAdsets > 1
        ? 'Too many adSets selected, please select only one to set the booking window.'
        : 'Booking window not available for this adSet.'}
    </Box>
  );
};
