import { inject, injectable } from 'inversify';
import { Algorithm, AlgorithmScript } from '../../../../core/dv360/model/algorithm';
import { AlgorithmType, EntityStatus } from '../../../../core/dv360/model/constant.ts';
import { BiddingScriptGenerationData } from '../../../../core/dv360/model/script.ts';
import { Dv360AlgorithmPort } from '../../../../core/dv360/port/dv360-algorithm.port';
import INJECTION_TOKENS from '../../../../core/generic/ioc/injection-tokens';
import type SimpleHttpService from '../../../../core/generic/simple-http-service.port';
import { Dv360WeightScriptGenerationMapper } from './dv360-weight-script-generation.mapper.ts';

export type WeightScriptGenerationResponse = {
  request_id: string;
  script: string;
  dv360_script_id: string;
  weights: { [key: string]: unknown }[];
};

export type CreateAlgorithmResponse = {
  id: string;
  name: string;
  display_name: string;
  type: string;
};

export type GetAlgorithmResponse = {
  id: string;
  name: string;
  type: string;
  entity_status: string;
  advertiser_id: string;
  strategy: string;
  script: string;
};

@injectable()
export class Dv360AlgorithmRepository implements Dv360AlgorithmPort {
  constructor(
    @inject(INJECTION_TOKENS.DV360_API_HTTP_SERVICE)
    private readonly httpService: SimpleHttpService,
    @inject(INJECTION_TOKENS.DV360_WEIGHT_SCRIPT_GENERATION_MAPPER)
    private readonly weightScriptGenerationMapper: Dv360WeightScriptGenerationMapper
  ) {}

  async createCustomBiddingAlgorithm(
    clientId: string,
    partnerId: string,
    advertiserId: string,
    name: string,
    strategy: string
  ): Promise<Algorithm> {
    const response = await this.httpService.post<CreateAlgorithmResponse>(
      `/v1/clients/${clientId}/algorithms`,
      {
        partner_id: partnerId,
        advertiser_id: advertiserId,
        algorithm_name: name,
        strategy: strategy,
      }
    );
    return {
      id: response?.id ?? '',
      name: response?.display_name ?? '',
      advertiserId,
      type: response?.type
        ? (response.type as AlgorithmType)
        : 'CUSTOM_BIDDING_ALGORITHM_TYPE_UNSPECIFIED',
      status: 'ENTITY_STATUS_ACTIVE',
    };
  }

  async linkScriptToAlgorithm(
    clientId: string,
    partnerId: string,
    advertiserId: string,
    algorithmId: string,
    script: string
  ): Promise<AlgorithmScript> {
    return this.httpService.post<AlgorithmScript>(`/v1/link-script-to-algorithm`, {
      partner_id: partnerId,
      advertiser_id: advertiserId,
      client_id: clientId,
      algorithm_id: algorithmId,
      script,
    });
  }

  async generateCustomBiddingAlgorithmScript(
    data: BiddingScriptGenerationData,
    with_domain_scoring: boolean = false
  ): Promise<string> {
    const payload = this.weightScriptGenerationMapper.toSerialized(data);
    const response = await this.httpService.post<WeightScriptGenerationResponse>(
      `/v1/generate_custom_bidding`,
      payload,
      { params: { script_push_enabled: false, with_domain_scoring } }
    );
    return response?.script ?? '';
  }

  async getAlgorithm(
    clientId: string,
    partnerId: string,
    advertiserId: string,
    algorithmId: string
  ): Promise<Algorithm> {
    const params = {
      partner_id: partnerId,
      advertiser_id: advertiserId,
    };
    const response = await this.httpService.get<GetAlgorithmResponse>(
      `/v1/clients/${clientId}/algorithms/${algorithmId}`,
      { params }
    );

    return {
      id: response?.id ?? '',
      name: response?.name ?? '',
      status: response?.entity_status
        ? (response.entity_status as EntityStatus)
        : 'ENTITY_STATUS_UNSPECIFIED',
      type: response?.type
        ? (response.type as AlgorithmType)
        : 'CUSTOM_BIDDING_ALGORITHM_TYPE_UNSPECIFIED',
      advertiserId: response?.advertiser_id ?? '',
      strategy: response?.strategy ?? '',
      script: response?.script ?? '',
    };
  }

  async updateAlgorithm(
    clientId: string,
    partnerId: string,
    advertiserId: string,
    algorithmId: string,
    name: string,
    strategy: string
  ): Promise<void> {
    const body = {
      partner_id: partnerId,
      advertiser_id: advertiserId,
      name,
      strategy,
    };
    await this.httpService.patch<GetAlgorithmResponse>(
      `/v1/clients/${clientId}/algorithms/${algorithmId}`,
      { data: body }
    );
  }
}
