import {
  SelectChangeEvent,
  ListItemIcon,
  Tooltip,
  Autocomplete,
  Checkbox,
  TextField,
} from '@mui/material';
import { ChangeEvent, SyntheticEvent, useState } from 'react';
import { AudienceFormValues, FormProps } from '../../../../../../core/meta/model/bid-form.ts';
import { useMetaStore } from '../../../../../../core/meta/store/meta-store.ts';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Audience } from '../../../../../../core/meta/model/audience.ts';
import { GroupHeader, GroupItems } from './location/location-form-style.tsx';
import { NumberStep } from '../components/NumberStep.tsx';

type AudienceFormValueToSend = {
  values: string[] | undefined;
};

export const AudienceForm: React.FC<FormProps<AudienceFormValues>> = ({
  onChange,
  initialValues,
  disabled,
  excludeValues,
}) => {
  const audiences = useMetaStore((state) => state.audiences);

  const [audienceFormValue, setAudienceFormValues] = useState<AudienceFormValues>(
    initialValues || { customAudience: [], value: 1 }
  );

  const handleChange = (
    event:
      | SelectChangeEvent<string>
      | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | AudienceFormValueToSend
  ) => {
    let newFormValue: AudienceFormValues;
    if ('values' in event) {
      newFormValue = {
        value: audienceFormValue.value,
        customAudience: event.values,
      } as AudienceFormValues;
    } else {
      const newValue =
        event.target.name === 'value' ? parseFloat(event.target.value) : event.target.value;
      newFormValue = { ...audienceFormValue, [event.target.name]: newValue };
    }
    setAudienceFormValues(newFormValue);
    onChange(newFormValue);
  };

  const handleAutocompleteChange = (
    _event: SyntheticEvent<Element, Event>,
    values: Audience[] | null
  ) => {
    const valueToSend: AudienceFormValueToSend = {
      values: values?.filter((value) => value.deliveryStatus.code === 200).map((value) => value.id),
    };
    if (valueToSend) handleChange(valueToSend);
  };

  const filteredAudiences = audiences.filter((audience) => !excludeValues?.includes(audience.id));

  const initialAutoCompleteValues = filteredAudiences.filter((audience) => {
    return audienceFormValue.customAudience.find((data) => data === audience.id);
  });

  return (
    <>
      <Autocomplete
        sx={{ width: '100%' }}
        id="custom-audience"
        options={filteredAudiences.sort((a, b) =>
          b.deliveryStatus.description.localeCompare(a.deliveryStatus.description)
        )}
        value={initialAutoCompleteValues}
        limitTags={1}
        multiple
        disableCloseOnSelect
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        groupBy={(option) => option.deliveryStatus.description || ''}
        onChange={handleAutocompleteChange}
        getOptionDisabled={(option) => option.deliveryStatus.code !== 200}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.id}>
            <Tooltip title={option.deliveryStatus.description} key={props.id}>
              <ListItemIcon>
                {option.deliveryStatus.code === 200 ? <CheckCircleIcon /> : <CancelIcon />}
              </ListItemIcon>
            </Tooltip>
            <Checkbox
              name={option.name}
              key={option.id}
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </li>
        )}
        renderInput={(params) => <TextField {...params} label="Custom audience" />}
        renderGroup={(params) => (
          <li key={params.key}>
            <GroupHeader>{params.group}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
      />

      <NumberStep
        disabled={disabled}
        name="value"
        value={audienceFormValue.value}
        onChange={handleChange}
      ></NumberStep>
    </>
  );
};
