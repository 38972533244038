import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';
import { container } from '../../generic/ioc/container.ts';
import INJECTION_TOKENS from '../../generic/ioc/injection-tokens.ts';
import { SNACKBAR_DEFAULT_VALUE, SnackbarProps } from '../../model/snackbar-props.ts';
import { FileService } from '../../service/file.service.ts';
import { ChangeEntry, ChangeEntryTable } from '../model/change-entry.ts';
import { ChangelogPort } from '../port/changelog.port.ts';

type ChangelogState = {
  snackBarProps: SnackbarProps;
  isChangelogDialogOpen: boolean;
  changelog: ChangeEntry[];
  changelogTable: ChangeEntryTable[];
  isChangelogLoading: boolean;
  isChangelogFromAlgoEditionOpen: boolean;
};

type ChangelogActions = {
  reset: () => void;
  openChangelogDialog: () => void;
  closeChangelogDialog: () => void;
  openChangelogDialogFromAlgoEdition: () => void;
  closeChangelogDialogFromAlgoEdition: () => void;
  resetChangelog: () => void;
  setChangelog: (
    clientId: string,
    partnerId: string,
    advertiserId: string,
    algorithmId: string
  ) => Promise<void>;
  convertChangelogToText: (changes: ChangeEntryTable[]) => string;
  copyChangelogToClipboard: (changes: ChangeEntryTable[]) => void;
  convertChangelogToCsvContent: (changes: ChangeEntryTable[]) => string;
  exportChangelogToCsv: (
    changes: ChangeEntryTable[],
    clientId: string,
    partnerId: string,
    advertiserId: string,
    algorithmId: string
  ) => void;
  sendSuccessMsgForCopyScriptInClipboard: () => void;
  sendErrorMsgForCopyScriptInClipboard: () => void;
};

const initialState: ChangelogState = {
  snackBarProps: SNACKBAR_DEFAULT_VALUE,
  isChangelogDialogOpen: false,
  changelog: [],
  changelogTable: [],
  isChangelogLoading: false,
  isChangelogFromAlgoEditionOpen: false,
};

// ADAPTERS DRIVEN
const changelogRepository = container.get<ChangelogPort>(
  INJECTION_TOKENS.DV360_CHANGELOG_REPOSITORY
);

// SERVICE
const fileService = container.get<FileService>(INJECTION_TOKENS.FILE_SERVICE);

export const useChangelogStore = create<ChangelogState & ChangelogActions>((set, get) => ({
  ...initialState,
  reset: () => {
    set(initialState);
  },
  openChangelogDialog: () => set({ isChangelogDialogOpen: true }),
  closeChangelogDialog: () => set({ isChangelogDialogOpen: false }),
  openChangelogDialogFromAlgoEdition: () => set({ isChangelogFromAlgoEditionOpen: true }),
  closeChangelogDialogFromAlgoEdition: () => set({ isChangelogFromAlgoEditionOpen: false }),
  resetChangelog: () => set({ changelog: [], changelogTable: [] }),
  setChangelog: async (
    clientId: string,
    partnerId: string,
    advertiserId: string,
    algorithmId: string
  ) => {
    set({ isChangelogLoading: true });
    try {
      // Generate changelog
      const changelog = await changelogRepository.generateChangelog(
        clientId,
        partnerId,
        advertiserId,
        algorithmId
      );

      // Sort changelog by date asc
      const sortedChangelog = changelog.sort((a, b) => {
        const dateA = a.date?.getTime() ?? 0;
        const dateB = b.date?.getTime() ?? 0;
        return dateB - dateA;
      });

      // Generate changelog for DataTable
      const changelogTable = changelog.reduce((acc: ChangeEntryTable[], change: ChangeEntry) => {
        acc.push({
          ...change,
          id: uuidv4(), // build unique ID
        });
        return acc;
      }, []);

      set({ changelogTable });
      set({ changelog: sortedChangelog });
    } catch (e) {
      set({
        snackBarProps: {
          messages: ['Error generating changelog'],
          severity: 'error',
          visible: true,
        },
      });
    } finally {
      set({ isChangelogLoading: false });
    }
  },
  convertChangelogToText: (changelog: ChangeEntryTable[]) => {
    return changelog.reduce((accumulator, currentValue, index) => {
      const date = currentValue.date ? currentValue.date.toLocaleDateString() : 'No date';
      const entryText = `${date}:\n changes: ${currentValue.changes}\n description: ${currentValue.description}`;
      return index === changelog.length - 1
        ? accumulator + entryText
        : accumulator + entryText + '\n';
    }, '');
  },
  copyChangelogToClipboard: (changelog: ChangeEntryTable[]) => {
    try {
      // Build text
      const text = get().convertChangelogToText(changelog);

      // Copy to clipboard
      navigator.clipboard
        .writeText(text)
        .then(() => {
          set({
            snackBarProps: {
              messages: ['Changelog copied successfully'],
              severity: 'success',
              visible: true,
            },
          });
        })
        .catch(() => {
          set({
            snackBarProps: {
              messages: ['Error copying changelog'],
              severity: 'error',
              visible: true,
            },
          });
        });
    } catch (e) {
      set({
        snackBarProps: {
          messages: ['Error copying changelog'],
          severity: 'error',
          visible: true,
        },
      });
    }
  },
  convertChangelogToCsvContent: (changes: ChangeEntryTable[]) => {
    const rows: string[] = [];
    rows.push('date; changes; description');
    rows.push(
      ...changes.map(({ date, changes, description }) => {
        const csvDate = date ? date.toLocaleDateString() : '';
        return [csvDate, changes, description].join(';');
      })
    );
    return rows.join('\n');
  },
  exportChangelogToCsv: (
    changes: ChangeEntryTable[],
    clientId: string,
    partnerId: string,
    advertiserId: string,
    algorithmId: string
  ) => {
    try {
      // Generate file name
      const now = new Date();
      const fileName = `${clientId}_${partnerId}_${advertiserId}_${algorithmId}_${now.toISOString()}`;

      // Create file content
      const fileContent = get().convertChangelogToCsvContent(changes);

      // Create csv file and download it
      fileService.toCSV(fileContent, fileName);
    } catch (e) {
      set({
        snackBarProps: {
          messages: ['Error exporting changelog'],
          severity: 'error',
          visible: true,
        },
      });
    }
  },
  sendSuccessMsgForCopyScriptInClipboard: () => {
    set({
      snackBarProps: {
        messages: ['Paste the script into the console below'],
        severity: 'info',
        visible: true,
      },
    });
  },
  sendErrorMsgForCopyScriptInClipboard: () => {
    set({
      snackBarProps: {
        messages: ['Error copying script in clipboard'],
        severity: 'error',
        visible: true,
      },
    });
  },
}));
