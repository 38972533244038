import { inject, injectable } from 'inversify';
import INJECTION_TOKENS from '../../generic/ioc/injection-tokens.ts';
import type { AudiencePort } from '../port/audience.port.ts';
import { Audience } from '../model/audience.ts';

@injectable()
export class GetAudiencesUseCase {
  constructor(
    @inject(INJECTION_TOKENS.AUDIENCE_REPOSITORY)
    private readonly audienceRepository: AudiencePort
  ) {}

  async execute(account_id: string): Promise<Audience[]> {
    return this.audienceRepository.getAudiences(account_id);
  }
}
