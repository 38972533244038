import { useAuth0 } from '@auth0/auth0-react';
import { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Loading from '../public/loading/Loading.tsx';
import PublicLayoutPage from '../public/PublicLayoutPage.tsx';
import { useInjection } from '../ioc/use-injection.ts';
import INJECTION_TOKENS from '../../../core/generic/ioc/injection-tokens.ts';
import { CanUserAccessUsecase } from '../../../core/usecase/can-user-access-usecase.ts';
import HttpService from '../../../core/generic/http-service.port.ts';
import { CreateUserUsecase } from '../../../core/usecase/create-user-usecase.ts';

type Status = 'done' | 'pending' | 'undefined';

const PrivateRoute = ({ component: Component }: { component: FunctionComponent }): ReactElement => {
  const { isLoading, isAuthenticated, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [isAccessCheckProgressing, setIsAccessCheckProgressing] = useState<Status>('undefined');

  const usecase = useInjection<CanUserAccessUsecase>(INJECTION_TOKENS.CAN_USER_ACCESS_USECASE);
  const httpClient = useInjection<HttpService>(INJECTION_TOKENS.META_API_HTTP_SERVICE);
  const createUserUsecase = useInjection<CreateUserUsecase>(INJECTION_TOKENS.CREATE_USER_USECASE);

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;

  useEffect(() => {
    const check = async () => {
      if (isAuthenticated) {
        try {
          setIsAccessCheckProgressing('pending');
          if (!httpClient.checkAccessToken()) {
            const accessToken = await getAccessTokenSilently({
              authorizationParams: {
                issuer: `https://${domain}`,
                audience: 'https://bidding.jplus.io',
                scope: 'read:users',
              },
            });
            const idToken = await getIdTokenClaims();
            httpClient.initialize(accessToken, idToken?.__raw as unknown as string);
          }

          const hasAccess = await usecase.execute();
          await createUserUsecase.execute();

          setIsAuthorized(hasAccess);
        } finally {
          setIsAccessCheckProgressing('done');
        }
      }
    };

    check();
  }, [
    usecase,
    createUserUsecase,
    isAuthenticated,
    httpClient,
    getAccessTokenSilently,
    domain,
    getIdTokenClaims,
  ]);

  if (!isLoading) {
    if (!isAuthenticated) {
      return <Navigate to="sign-in" replace={true} />;
    } else if (isAccessCheckProgressing !== 'done') {
      return <PublicLayoutPage component={Loading} />;
    } else if (!isAuthorized) {
      return <Navigate to="not-authorized" replace={true} />;
    } else {
      return <Component />;
    }
  } else {
    return <PublicLayoutPage component={Loading} />;
  }
};

export default PrivateRoute;
