import { Stack, SxProps, Typography } from '@mui/material';
import GetStartedCard from '../components/GetStartedCard';
import { homePageConfig } from './home-page.config';

const StackSx: SxProps = { margin: '16px 0 20px 0' };
const H1Style = { fontSize: '48px', marginTop: '0px' };
const H2Style = { margin: '0' };
const H3Style = { margin: '40px 0 0 0' };

const HomePage = () => {
  return (
    <div>
      <h1 style={H1Style}>J+ Bidding</h1>
      <h2 style={H2Style}>Empower Your Advertising with Bid Adjustments: Introducing J+Bidding</h2>
      <Typography variant="caption">
        Transform your digital campaigns with our pioneering Bidding Adjustor. Developed in
        partnership with Meta, J+Bidding Meta is your gateway to adjust efficiently your AdSet.
      </Typography>

      <Stack spacing={2} direction="row" sx={StackSx}>
        {homePageConfig.analysis.map((analysis) => (
          <GetStartedCard
            key={analysis.title}
            title={analysis.title}
            description={analysis.description}
            buttonLabel={analysis.buttonLabel}
            link={analysis.link}
            icons={analysis.icons}
          />
        ))}
      </Stack>
      <h3 style={H3Style}>Additional Resources:</h3>
      <Stack spacing={2} direction="row" sx={StackSx}>
        {homePageConfig.resources.map((resource) => (
          <GetStartedCard
            key={resource.title}
            title={resource.title}
            description={resource.description}
            buttonLabel={resource.buttonLabel}
            link={resource.link}
          />
        ))}
      </Stack>
    </div>
  );
};

export default HomePage;
