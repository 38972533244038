import 'reflect-metadata';
import { injectable } from 'inversify';
import SimpleHttpService from '../../../../core/generic/simple-http-service.port.ts';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

@injectable()
export class AxiosDv360HttpService implements SimpleHttpService {
  api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_ENGINE_API_URL,
      timeout: 120000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    this.api.interceptors.request.use((config) => {
      config.headers['X-API-KEY'] = process.env.REACT_APP_ENGINE_API_KEY;
      return config;
    });
  }

  async get<TResponse>(url: string, config?: AxiosRequestConfig): Promise<TResponse> {
    const { data } = await this.api.get<TResponse>(url, config as AxiosRequestConfig);
    return data as unknown as TResponse;
  }

  async post<TResponse, TBody = Record<string, unknown>>(
    url: string,
    body?: TBody,
    config?: AxiosRequestConfig
  ): Promise<TResponse> {
    const { data } = await this.api.post<TResponse>(url, body, config as AxiosRequestConfig);
    return data as unknown as TResponse;
  }

  async delete<TResponse = unknown>(url: string, config?: AxiosRequestConfig): Promise<TResponse> {
    const { data } = await this.api.delete<TResponse>(url, config as AxiosRequestConfig);
    return data as unknown as TResponse;
  }

  async patch<TResponse, TBody = Record<string, unknown>>(
    url: string,
    body?: TBody,
    config?: AxiosRequestConfig
  ): Promise<TResponse> {
    const { data } = await this.api.patch<TResponse>(url, body, config as AxiosRequestConfig);
    return data as unknown as TResponse;
  }
}
