import { AppBar, Toolbar, Typography, Container, IconButton, Tooltip } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  BidFormStatusType,
  useBidAdjustmentStore,
} from '../../../../../core/meta/store/bid-adjustment-store.ts';
import { useMetaStore } from '../../../../../core/meta/store/meta-store.ts';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import { useBiddingUser } from '../hooks/bidding-user.ts';

const headerContainerSx: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flex: 1,
  justifyContent: 'stretch',
};
const containerStyle = {
  display: 'contents',
  height: '100%',
  alignItems: 'center',
};

const textSx: SxProps = { marginLeft: '0.875rem', fontWeight: 600 };
const appBarSx: SxProps = { height: '63px', backgroundColor: 'white' };
export const BidAdjustmentTopBar = () => {
  const { accountId } = useParams();
  const { isUserBiddingPublisher } = useBiddingUser();
  const closeDrawer = useBidAdjustmentStore((state) => state.closeDrawer);
  const saveBid = useBidAdjustmentStore((state) => state.saveBid);
  const allSelectedAdSets = useMetaStore((state) => state.getAllSelectedAdSets());
  const loading = useBidAdjustmentStore((state) => state.isLoading);
  const bidFormStatus = useBidAdjustmentStore((state) => state.bidFormStatus);

  const setCampaigns = useMetaStore((state) => state.setCampaigns);

  const sendToMeta = async () => {
    await saveBid(allSelectedAdSets);
    setCampaigns(accountId ?? '');
    closeDrawer();
  };

  return (
    <AppBar position="static" color="transparent" sx={appBarSx}>
      <Toolbar variant="dense">
        <Box sx={headerContainerSx}>
          <IconButton color="inherit" aria-label="delete" onClick={closeDrawer}>
            <ArrowBackIcon></ArrowBackIcon>
          </IconButton>

          <Typography sx={textSx} variant="h6">
            Bid Multiplier
          </Typography>
        </Box>
        <Container sx={containerStyle}>
          <Tooltip
            title={!isUserBiddingPublisher ? 'You do not have permission to publish a bid' : ''}
          >
            <span>
              <LoadingButton
                disabled={!isUserBiddingPublisher}
                onClick={() => sendToMeta()}
                loading={loading}
                color="primary"
                variant="contained"
              >
                {bidFormStatus === BidFormStatusType.REVIEW
                  ? 'Approve and publish to Meta'
                  : 'Publish to Meta'}
              </LoadingButton>
            </span>
          </Tooltip>
        </Container>
      </Toolbar>
    </AppBar>
  );
};
