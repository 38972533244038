import { inject, injectable } from 'inversify';
import INJECTION_TOKENS from '../../generic/ioc/injection-tokens.ts';
import type { LocationPort } from '../port/location.port.ts';
import { Region } from '../model/location.ts';

@injectable()
export class GetRegionsUseCase {
  constructor(
    @inject(INJECTION_TOKENS.LOCATION_REPOSITORY)
    private readonly locationRepository: LocationPort
  ) {}

  async execute(): Promise<Region[]> {
    const regions = await this.locationRepository.getRegions();
    return Array.from(new Set(regions));
  }
}
