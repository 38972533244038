import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import 'reflect-metadata';
import { injectable } from 'inversify';
import HttpService from '../../../../core/generic/http-service.port';

@injectable()
export class AxiosMetaHttpService implements HttpService {
  api: AxiosInstance;
  accessToken: string | undefined;
  idToken: string | undefined;

  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 120000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }
  checkAccessToken(): boolean {
    return !!this.accessToken && !!this.idToken;
  }

  initialize(accessToken: string, idToken: string) {
    this.accessToken = accessToken;
    this.idToken = idToken;
    this.api.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${accessToken}`;
      config.headers.idToken = idToken;
      return config;
    });
  }

  async get<TResponse>(url: string, config?: AxiosRequestConfig): Promise<TResponse> {
    const { data } = await this.api.get<TResponse>(url, config as AxiosRequestConfig);
    return data as unknown as TResponse;
  }

  async post<TResponse, TBody = Record<string, unknown>>(
    url: string,
    body?: TBody,
    config?: AxiosRequestConfig
  ): Promise<TResponse> {
    const { data } = await this.api.post<TResponse>(url, body, config as AxiosRequestConfig);
    return data as unknown as TResponse;
  }

  async delete<TResponse = unknown>(url: string, config?: AxiosRequestConfig): Promise<TResponse> {
    const { data } = await this.api.delete<TResponse>(url, config as AxiosRequestConfig);
    return data as unknown as TResponse;
  }
}
