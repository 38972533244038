import { create } from 'zustand';
import INJECTION_TOKENS from '../../generic/ioc/injection-tokens.ts';
import { GetClientsUseCase } from '../usecase/get-clients-usecase.ts';
import { container } from '../../generic/ioc/container.ts';
import { Client, Account } from '../model/client.ts';
import { AdSet, Campaign, PromotedObjectToDisplay } from '../model/campaign.ts';
import { CampaignPort } from '../port/campaign.port.ts';
import { GetCountriesUseCase } from '../usecase/get-countries-usecase.ts';
import { Country, Region } from '../model/location.ts';
import { GetRegionsUseCase } from '../usecase/get-regions-usecase.ts';
import { GetAudiencesUseCase } from '../usecase/get-audiences-usecase.ts';
import { Audience } from '../model/audience.ts';
import { PromotedObjectService } from '../service/promoted-object.service.ts';

export type MetaStore = {
  clientName: string;
  accountName: string;
  isLoading: boolean;
  isCampaignsLoading: boolean;
  clients: Client[];
  accountsByClient: Account[];
  adSets: AdsetsByAccount;
  selectedAdsetsPerCampaign: AdSetsIdByCampaign;
  selectedAdsets: string[];
  countSelectedAdsetsPerCampaign: number;
  countSelectedAdsets: number;
  totalCountSelectedAdsets: number;
  campaigns: CampaignsByAccount;
  countries: Country[];
  regions: Region[];
  audiences: Audience[];
  setCampaigns: (accountId: string) => void;
  setClients: () => Promise<void>;
  setLocation: () => Promise<void>;
  setAudiences: (account_id: string) => void;
  setSelectedAdsetsPerCampaign: (adSetId: string[], campaignId: string) => void;
  setSelectedAdsets: (adSetId: string[]) => void;
  clearSelectedAdsets: () => void;
  setAdSetAndPromotedObject: (accountId: string) => void;
  adSetsSelected: AdSet;
  promotedObjectToDisplay: PromotedObjectToDisplay;
  getAllSelectedAdSets: () => string[];
};

type CampaignsByAccount = {
  [key: string]: Campaign[];
};

type AdsetsByAccount = {
  [key: string]: AdSet[];
};

type AdSetsIdByCampaign = {
  [key: string]: string[];
};

const getClientsUsecase = container.get<GetClientsUseCase>(INJECTION_TOKENS.GET_CLIENTS_USECASE);
const getCountriesUsecase = container.get<GetCountriesUseCase>(
  INJECTION_TOKENS.GET_COUNTRIES_USECASE
);
const getAudiencesUsecase = container.get<GetAudiencesUseCase>(
  INJECTION_TOKENS.GET_AUDIENCES_USECASE
);
const getRegionsUsecase = container.get<GetRegionsUseCase>(INJECTION_TOKENS.GET_REGIONS_USECASE);
const campaignRepository = container.get<CampaignPort>(INJECTION_TOKENS.CAMPAIGN_REPOSITORY);

export const useMetaStore = create<MetaStore>((set, get) => ({
  isLoading: false,
  isCampaignsLoading: false,
  clients: [],
  accountsByClient: [],
  selectedAdsetsPerCampaign: {},
  countSelectedAdsetsPerCampaign: 0,
  countSelectedAdsets: 0,
  totalCountSelectedAdsets: 0,
  accountName: '',
  clientName: '',
  campaigns: {},
  countries: [],
  regions: [],
  audiences: [],
  adSets: {},
  selectedAdsets: [],
  adSetsSelected: {
    id: '',
    name: '',
    currentBid: '',
    bidMultiplier: '',
    status: '',
    bid_status: '',
    updatedAt: '',
    campaign_objective: '',
  },
  promotedObjectToDisplay: {
    valueToDisplay: '',
    value: '',
  },
  setCampaigns: async (accountId: string) => {
    const currentCampaigns = get().campaigns;
    const currentAdsets = get().adSets;
    const isLoading = !currentCampaigns[accountId];
    set({ isCampaignsLoading: isLoading });
    try {
      const campaigns = await campaignRepository.getCampaigns(accountId);
      const adSets = campaigns.map((campaign) => campaign.adsets).flat();
      set({
        isCampaignsLoading: false,
        campaigns: { ...currentCampaigns, [accountId]: campaigns },
        adSets: { ...currentAdsets, [accountId]: adSets },
      });
    } catch (e) {
      set({ isCampaignsLoading: false });
    }
  },
  setClients: async () => {
    set({ isLoading: true });
    if (get().clients.length) {
      set({ isLoading: false });
      return;
    }
    const clients = await getClientsUsecase.execute();
    const clientsAccountsWithClientId = clients
      .map((client) => ({
        ...client,
        accounts: client.accounts.map((account) => ({
          ...account,
          clientId: client.id,
        })),
      }))
      .sort((a, b) => -(b?.name ?? '').localeCompare(a?.name || ''));
    set({ isLoading: false, clients: clientsAccountsWithClientId });
  },
  setLocation: async () => {
    set({ isLoading: true });
    if (get().countries.length && get().regions.length) {
      set({ isLoading: false });
      return;
    }
    const countries = await getCountriesUsecase.execute();
    const regions = await getRegionsUsecase.execute();
    set({ isLoading: false, countries, regions });
  },
  setSelectedAdsetsPerCampaign: (adSetId: string[], campaignId: string) => {
    const currentSelectedAdsets = get().selectedAdsetsPerCampaign;
    const selectedAdsetsPerCampaign = { ...currentSelectedAdsets, [campaignId]: adSetId };
    const countSelectedAdsetsPerCampaign = Object.values(selectedAdsetsPerCampaign).reduce(
      (acc, curr) => acc + curr.length,
      0
    );
    set({ selectedAdsetsPerCampaign, countSelectedAdsetsPerCampaign });
  },
  setSelectedAdsets: (adSetIds: string[]) => {
    set({ countSelectedAdsets: adSetIds.length, selectedAdsets: adSetIds });
  },
  clearSelectedAdsets: () => {
    set({
      selectedAdsetsPerCampaign: {},
      selectedAdsets: [],
      countSelectedAdsets: 0,
      countSelectedAdsetsPerCampaign: 0,
    });
  },
  setAudiences: async (account_id: string) => {
    set({ isLoading: true });
    try {
      const audiences = await getAudiencesUsecase.execute(account_id);
      set({ isLoading: false, audiences });
    } catch (e) {
      set({ isLoading: false });
    }
  },
  setAdSetAndPromotedObject: (accountId: string) => {
    const adSets = get().adSets;
    const allSelectedAdSetIdsFromCampaigns = ([] as string[]).concat(
      ...Object.values(get().selectedAdsetsPerCampaign)
    );
    const allSelectedAdSetIds = get().selectedAdsets;
    const adSetsSelected = adSets[accountId].find((adSet) =>
      [...allSelectedAdSetIds, ...allSelectedAdSetIdsFromCampaigns].includes(adSet.id)
    ) as AdSet;

    const promotedObjectToDisplay = new PromotedObjectService().getPromotedObjectToDisplay(
      adSetsSelected.promoted_object
    );

    const totalCountSelectedAdsets =
      get().countSelectedAdsetsPerCampaign + get().countSelectedAdsets;

    set({ adSetsSelected, promotedObjectToDisplay, totalCountSelectedAdsets });
  },
  getAllSelectedAdSets: () => {
    const adSetPerCampaign = ([] as string[]).concat(
      ...Object.values(get().selectedAdsetsPerCampaign)
    );
    const adSetSelected = get().selectedAdsets;
    return [...adSetPerCampaign, ...adSetSelected];
  },
}));
