import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';

interface DeleteFormProps {
  onClick: () => void;
}

export const DeleteForm: React.FC<DeleteFormProps> = ({ onClick }) => {
  return (
    <IconButton aria-label="delete" onClick={onClick}>
      <DeleteIcon />
    </IconButton>
  );
};
