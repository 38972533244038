// src/hooks/useKonamiCode.ts
import { useEffect } from 'react';

const useKonamiCode = (callback: () => void) => {
  useEffect(() => {
    const sequence = [
      'ArrowUp',
      'ArrowUp',
      'ArrowDown',
      'ArrowDown',
      'ArrowLeft',
      'ArrowRight',
      'ArrowLeft',
      'ArrowRight',
      'KeyB',
      'KeyA',
    ];
    let currentIndex = 0;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === sequence[currentIndex]) {
        currentIndex += 1;
        if (currentIndex === sequence.length) {
          callback();
          currentIndex = 0;
        }
      } else {
        currentIndex = 0;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [callback]);
};

export default useKonamiCode;
