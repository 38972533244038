import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { bidAdjustmentFieldStyle } from './styles';
import { NumberStep } from '../components/NumberStep';
import { ChangeEvent, useState } from 'react';
import { FormProps, DevicePlatformFormValues } from '../../../../../../core/meta/model/bid-form.ts';

export const DevicePlatformForm: React.FC<FormProps<DevicePlatformFormValues>> = ({
  onChange,
  initialValues,
  disabled,
  excludeValues,
}) => {
  const [genderFormValues, setGenderFormValues] = useState<DevicePlatformFormValues>(
    initialValues || { device: '', value: 1 }
  );
  const handleChange = (e: SelectChangeEvent<string> | ChangeEvent<HTMLInputElement>) => {
    const newValue =
      e.target instanceof HTMLInputElement ? parseFloat(e.target.value) : e.target.value;
    const newFormValue = { ...genderFormValues, [e.target.name]: newValue };
    setGenderFormValues(newFormValue);
    onChange(newFormValue);
  };

  const filteredDevicePlatform = ['mobile', 'desktop', 'default'].filter(
    (device) => !excludeValues?.includes(device)
  );

  return (
    <>
      <FormControl sx={bidAdjustmentFieldStyle}>
        <InputLabel>Device Platform</InputLabel>
        <Select
          name="device"
          label="Device Platform"
          onChange={handleChange}
          value={genderFormValues.device}
        >
          {filteredDevicePlatform.map((device) => (
            <MenuItem key={device} value={device}>
              {device.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <NumberStep
        disabled={disabled}
        name="value"
        value={genderFormValues.value}
        onChange={handleChange}
      ></NumberStep>
    </>
  );
};
