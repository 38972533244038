import CheckBoxIcon from '@mui/icons-material/CheckBox';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Checkbox, createFilterOptions, FormControl, TextField } from '@mui/material';
import { FC, SyntheticEvent } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { ConversionWeightFormValues } from '../../../../../core/dv360/model/bid-form.ts';
import { InsertionOrder } from '../../../../../core/dv360/model/insertion-order.ts';

type InsertionOrderFormInputProps = {
  initialValues: InsertionOrder[];
  form: UseFormReturn<ConversionWeightFormValues>;
};

type OnChangeController = (ids: string[]) => void;

export const InsertionOrderAutoCompleteForm: FC<InsertionOrderFormInputProps> = ({
  initialValues,
  form,
}) => {
  const { control, formState, trigger } = form;
  const { errors } = formState;

  const handleOnChange = (values: InsertionOrder[], onChange: OnChangeController) => {
    const ioIds = values.map((item) => item.id);
    onChange(ioIds);
    trigger('insertionOrderIds');
  };

  const isFieldInError = (): boolean => {
    return !!errors?.insertionOrderIds;
  };

  const getHelperText = (): string => {
    return errors?.insertionOrderIds ? errors.insertionOrderIds.message ?? '' : '';
  };

  const filterOptions = createFilterOptions({
    stringify: (option: InsertionOrder) => `${option.id} - ${option.name}`,
  });

  return (
    <FormControl required={true}>
      <Controller
        name="insertionOrderIds"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'At least 1 insertion order is required.',
          },
        }}
        render={({ field: { name, onChange, value, ...field } }) => (
          <Autocomplete
            {...field}
            id={name}
            value={initialValues.filter((item) => value.includes(item.id))}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={initialValues}
            multiple
            filterOptions={filterOptions}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.id}>
                <Checkbox
                  name={`${option.id} - ${option.name}`}
                  key={option.id}
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.id} - {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Select insertion orders"
                error={isFieldInError()}
                helperText={getHelperText()}
                InputLabelProps={{ required: true }}
              />
            )}
            onChange={(_event: SyntheticEvent<Element, Event>, values: InsertionOrder[]) => {
              handleOnChange(values, onChange);
            }}
          ></Autocomplete>
        )}
      />
    </FormControl>
  );
};
