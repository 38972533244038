/* eslint-disable @typescript-eslint/no-unused-vars */
import { injectable } from 'inversify';
import { Algorithm, AlgorithmScript } from '../../../../core/dv360/model/algorithm';
import { Dv360AlgorithmPort } from '../../../../core/dv360/port/dv360-algorithm.port';

@injectable()
export class InMemoryDv360Algorithm implements Dv360AlgorithmPort {
  script = '';
  generateConversionWeightScriptError = false;
  algorithm: Algorithm = {
    id: '',
    name: '',
    status: 'ENTITY_STATUS_UNSPECIFIED',
    type: 'CUSTOM_BIDDING_ALGORITHM_TYPE_UNSPECIFIED',
    advertiserId: '',
    strategy: '',
    script: '',
  };
  createAlgorithmError = false;
  algorithmScript: AlgorithmScript = {
    id: '',
    algorithmId: '',
  };
  linkScriptToAlgorithmError = false;
  getAlgorithmError = false;
  updateAlgorithmError = false;

  clearData() {
    this.script = '';
    this.algorithm = {
      id: '',
      name: '',
      status: 'ENTITY_STATUS_UNSPECIFIED',
      type: 'CUSTOM_BIDDING_ALGORITHM_TYPE_UNSPECIFIED',
      advertiserId: '',
      strategy: '',
      script: '',
    };
    this.algorithmScript = {
      id: '',
      algorithmId: '',
    };
    this.generateConversionWeightScriptError = false;
    this.createAlgorithmError = false;
    this.linkScriptToAlgorithmError = false;
    this.getAlgorithmError = false;
    this.updateAlgorithmError = false;
  }

  setScript(value: string) {
    this.script = value;
  }

  setGenerateConversionWeightScriptError(value: boolean) {
    this.generateConversionWeightScriptError = value;
  }

  setAlgorithm(value: Algorithm) {
    this.algorithm = value;
  }

  setCreateAlgorithmError(value: boolean) {
    this.createAlgorithmError = value;
  }

  setAlgorithmScript(value: AlgorithmScript) {
    this.algorithmScript = value;
  }

  setLinkScriptToAlgorithmError(value: boolean) {
    this.linkScriptToAlgorithmError = value;
  }

  setGetAlgorithmError(value: boolean) {
    this.getAlgorithmError = value;
  }

  setUpdateAlgorithmError(value: boolean) {
    this.updateAlgorithmError = value;
  }

  async createCustomBiddingAlgorithm(): Promise<Algorithm> {
    if (this.createAlgorithmError) {
      throw new Error('Error obtained during algorithm creation');
    }
    return this.algorithm;
  }

  async linkScriptToAlgorithm(
    _clientId: string,
    _partnerId: string,
    _advertiserId: string,
    _algorithmId: string,
    _script: string
  ): Promise<AlgorithmScript> {
    if (this.linkScriptToAlgorithmError) {
      throw new Error('Error obtained when linking the script to its algorithm');
    }
    return this.algorithmScript;
  }

  async generateCustomBiddingAlgorithmScript(): Promise<string> {
    if (this.generateConversionWeightScriptError) {
      throw new Error('Error obtained during script generation');
    }
    return this.script;
  }

  async getAlgorithm(): Promise<Algorithm> {
    if (this.getAlgorithmError) {
      throw new Error('Error obtained when getting the algorithm');
    }
    return this.algorithm;
  }

  async updateAlgorithm(): Promise<void> {
    if (this.updateAlgorithmError) {
      throw new Error('Error obtained when updating the algorithm');
    }
  }
}
