import { injectable } from 'inversify';
import { ChangeEntry } from '../../../../core/dv360/model/change-entry.ts';
import { ChangelogPort } from '../../../../core/dv360/port/changelog.port.ts';

@injectable()
export class InMemoryChangelogRepository implements ChangelogPort {
  changelog: ChangeEntry[] = [];
  generateChangelogError: boolean = false;

  clearData() {
    this.changelog = [];
    this.generateChangelogError = false;
  }

  setChangelog(changelog: ChangeEntry[]) {
    this.changelog = changelog;
  }

  async generateChangelog(
    clientId: string,
    partnerId: string,
    advertiserId: string,
    algorithmId: string
  ): Promise<ChangeEntry[]> {
    if (this.generateChangelogError) {
      throw new Error(
        `Error generating changelog for client ${clientId}, partner ${partnerId}, advertiser ${advertiserId}, algorithm ${algorithmId}`
      );
    }
    return this.changelog;
  }
}
