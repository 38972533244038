import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { StackSx } from '../../styles/elements';
import {
  META_CLIENT_ACCOUNT_LIST_COLUMNS,
  META_CLIENT_LIST_COLUMNS,
  META_CLIENT_LIST_INITIAL_STATE,
} from './ClientListConfig';
import { BreadCrumb } from '../../components/BreadCrumb';
import { DataTable } from './components/DataTable.tsx';
import { useMetaStore } from '../../../../core/meta/store/meta-store.ts';
import { Client } from '../../../../core/meta/model/client.ts';
import React from 'react';
import { DataGridProProps, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';

const MetaClientListPage = () => {
  const clients = useMetaStore((state) => state.clients);
  const isLoading = useMetaStore((state) => state.isLoading);

  const getDetailPanelContent = React.useCallback<
    NonNullable<DataGridProProps['getDetailPanelContent']>
  >(({ row }) => {
    return (
      <Stack sx={{ marginLeft: '105px' }}>
        <DataTable
          rows={row.accounts}
          columns={META_CLIENT_ACCOUNT_LIST_COLUMNS}
          pinnedColumns={{ right: ['actions'] }}
          hasBorder={false}
        />
      </Stack>
    );
  }, []);

  const getDetailPanelHeight = React.useCallback(() => 'auto', []);

  return (
    <div>
      <BreadCrumb path="/" title="Home"></BreadCrumb>
      <h2 style={{ margin: '0', display: 'flex', alignItems: 'center' }}>
        <img
          key="meta-all-campaigns"
          src="/assets/icons/meta.svg"
          alt="meta icon"
          width={'35px'}
          height={'35px'}
          style={{ margin: '8px' }}
        />{' '}
        Client List
      </h2>
      <Typography variant="caption">
        Choose a client to adjust the bid multiplier for their campaigns and ad sets.{' '}
      </Typography>

      <Stack spacing={2} direction="row" sx={StackSx}>
        <DataTable<Client>
          initialState={META_CLIENT_LIST_INITIAL_STATE}
          rows={clients}
          columns={META_CLIENT_LIST_COLUMNS}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
          loading={isLoading}
          pinnedColumns={{ right: [GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field] }}
        ></DataTable>
      </Stack>
    </div>
  );
};

export default MetaClientListPage;
