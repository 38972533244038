import { create } from 'zustand';
import { container } from '../../generic/ioc/container.ts';
import INJECTION_TOKENS from '../../generic/ioc/injection-tokens.ts';
import { SNACKBAR_DEFAULT_VALUE, SnackbarProps } from '../../model/snackbar-props.ts';
import {
  AlgorithmFormValues,
  AlgorithmModificationInitValues,
  ConversionWeightFormValues,
  EditAlgorithmValues,
} from '../model/bid-form.ts';
import {
  EDIT_ALGO_DEFAULT_VALUES,
  EDIT_ALGO_FORM_DEFAULT_VALUES,
  SCRIPT_DEFAULT_VALUE,
} from '../model/constant.ts';
import { Floodlight } from '../model/floodlight.ts';
import { InsertionOrder } from '../model/insertion-order.ts';
import { Dv360AlgorithmPort } from '../port/dv360-algorithm.port.ts';
import { CreateAlgorithmCommand } from '../usecase/create-algorithm.command.ts';
import { CreateAlgorithmUsecase } from '../usecase/create-algorithm.usecase.ts';

type CustomBidStoreState = {
  clientId: string;
  partnerId: string;
  advertiserId: string;
  algorithmId: string;
  isLoading: boolean;
  isDrawerOpen: boolean;
  snackBarProps: SnackbarProps;
  scriptValue: string;
  advertiserIos: InsertionOrder[];
  advertiserFloodLights: Floodlight[];
  editAlgorithmValues: EditAlgorithmValues;
  isCreationAlgoReadyToSend: boolean;
  isInCreationAlgorithmMode: boolean;
  algorithmFormDefaultValues: AlgorithmFormValues;
  isInitEditAlgorithmInProgress: boolean;
  isModificationAlgoReadyToSend: boolean;
};

type CustomBidStoreActions = {
  reset: () => void;
  setClientId: (id: string) => void;
  setPartnerId: (id: string) => void;
  setAdvertiserId: (advertiserId: string) => void;
  setAlgorithmId: (algorithmId: string) => void;
  openCreationAlgoDrawer: () => void;
  openModificationAlgoDrawer: () => void;
  closeDrawer: () => void;
  setScriptValue: (value: string) => void;
  setDefaultScriptValue: () => void;
  setAdvertiserIos: (ios: InsertionOrder[]) => void;
  setAdvertiserFloodlights: (floodlights: Floodlight[]) => void;
  setNewAlgorithmType: (type: string) => void;
  setNewAlgorithmName: (name: string) => void;
  setNewAlgorithmConversionWeightData: (formValues: ConversionWeightFormValues) => void;
  createAlgorithm: () => Promise<boolean>;
  initModificationAlgoFormDefaultValues: (
    clientId: string,
    partnerId: string,
    advertiserId: string,
    algorithmId: string
  ) => Promise<AlgorithmModificationInitValues>;
  modifyAlgorithm: () => Promise<boolean>;
  resetEditAlgorithmValues: () => void;
};

const initialState = {
  clientId: '',
  partnerId: '',
  advertiserId: '',
  algorithmId: '',
  isLoading: false,
  isDrawerOpen: false,
  isAlgoCreationManual: false,
  snackBarProps: SNACKBAR_DEFAULT_VALUE,
  scriptValue: SCRIPT_DEFAULT_VALUE,
  advertiserIos: [],
  advertiserFloodLights: [],
  editAlgorithmValues: EDIT_ALGO_DEFAULT_VALUES,
  isCreationAlgoReadyToSend: false,
  isInCreationAlgorithmMode: false,
  algorithmFormDefaultValues: EDIT_ALGO_FORM_DEFAULT_VALUES,
  isInitEditAlgorithmInProgress: false,
  isModificationAlgoReadyToSend: true,
};

// ADAPTERS DRIVEN
const algorithmRepository = container.get<Dv360AlgorithmPort>(
  INJECTION_TOKENS.DV360_ALGORITHM_REPOSITORY
);
const createAlgorithmUseCase = container.get<CreateAlgorithmUsecase>(
  INJECTION_TOKENS.DV360_CREATE_ALGO_USECASE
);

const defineIfCreationIsReady = (name: string, type: string, scriptValue: string) => {
  const isNameFilled = !!name;
  const isScriptValid = !!scriptValue;
  const isTypeValid = !!type;
  return isNameFilled && isTypeValid && isScriptValid;
};

const defineIfModificationIsReady = (scriptValue: string) => {
  return !!scriptValue;
};

export const useCustomBidStore = create<CustomBidStoreState & CustomBidStoreActions>(
  (set, get) => ({
    ...initialState,
    reset: () => {
      set(initialState);
    },
    setClientId: (clientId: string) => set({ clientId }),
    setPartnerId: (partnerId: string) => set({ partnerId }),
    setAdvertiserId: (advertiserId: string) => set({ advertiserId }),
    setAlgorithmId: (algorithmId: string) => set({ algorithmId }),
    openCreationAlgoDrawer: () => set({ isDrawerOpen: true, isInCreationAlgorithmMode: true }),
    openModificationAlgoDrawer: () => set({ isDrawerOpen: true, isInCreationAlgorithmMode: false }),
    closeDrawer: () => set({ isDrawerOpen: false }),
    setScriptValue: (scriptValue: string) => {
      // Update value
      set({ scriptValue });

      if (get().isInCreationAlgorithmMode) {
        // Check if creation is ready
        const name = get().editAlgorithmValues?.name;
        const type = get().editAlgorithmValues?.type;
        set({ isCreationAlgoReadyToSend: defineIfCreationIsReady(name, type, scriptValue) });
      } else {
        // Check if modification is ready
        set({ isModificationAlgoReadyToSend: defineIfModificationIsReady(scriptValue) });
      }
    },
    setDefaultScriptValue: () => set({ scriptValue: SCRIPT_DEFAULT_VALUE }),
    setAdvertiserIos: (ios: InsertionOrder[]) => set({ advertiserIos: ios }),
    setAdvertiserFloodlights: (floodlights: Floodlight[]) =>
      set({ advertiserFloodLights: floodlights }),
    setNewAlgorithmType: (type: string) => {
      // Update type value
      set({ editAlgorithmValues: { ...get().editAlgorithmValues, type } });

      // Check if send is ready
      const scriptValue = get().scriptValue;
      const name = get().editAlgorithmValues?.name;
      set({ isCreationAlgoReadyToSend: defineIfCreationIsReady(name, type, scriptValue) });
    },
    setNewAlgorithmName: (name: string) => {
      // Update name value
      set({ editAlgorithmValues: { ...get().editAlgorithmValues, name } });

      // Check if send is ready
      const scriptValue = get().scriptValue;
      const type = get().editAlgorithmValues?.type;
      set({ isCreationAlgoReadyToSend: defineIfCreationIsReady(name, type, scriptValue) });
    },
    setNewAlgorithmConversionWeightData: ({
      insertionOrderIds,
      floodlightIds,
      isFloodlightPriority,
      floodlightPriorities,
      kValue,
      period,
      isDomainScoringActivated,
      goal,
      maxCoefficient,
      aggressivity,
    }: ConversionWeightFormValues) =>
      set({
        editAlgorithmValues: {
          ...get().editAlgorithmValues,
          scriptGeneration: {
            insertionOrderIds,
            period,
            conversionWeight: {
              floodlightIds,
              isFloodlightPriority,
              floodlightPriorities,
              kValue,
            },
            domainScoring: {
              isDomainScoringActivated,
              goal,
              maxCoefficient,
              aggressivity,
            },
          },
        },
      }),
    createAlgorithm: async (): Promise<boolean> => {
      let creationInSuccess = true;
      set({ isLoading: true });
      try {
        const command: CreateAlgorithmCommand = {
          clientId: get().clientId ?? '',
          partnerId: get().partnerId ?? '',
          advertiserId: get().advertiserId ?? '',
          name: get().editAlgorithmValues?.name ?? '',
          type: get().editAlgorithmValues?.type ?? '',
          script: get().scriptValue ?? '',
        };
        await createAlgorithmUseCase.execute(command);
        set({
          snackBarProps: {
            messages: [
              'Your algorithm has been successfully created within the defined advertiser in DV360',
            ],
            severity: 'success',
            visible: true,
          },
        });
      } catch (e) {
        set({
          snackBarProps: {
            messages: ['Error creating algorithm'],
            severity: 'error',
            visible: true,
          },
        });
        creationInSuccess = false;
      } finally {
        set({ isLoading: false });
      }
      return creationInSuccess;
    },
    initModificationAlgoFormDefaultValues: async (
      clientId: string,
      partnerId: string,
      advertiserId: string,
      algorithmId: string
    ): Promise<AlgorithmModificationInitValues> => {
      let initData: AlgorithmModificationInitValues = {
        name: '',
        type: '',
        script: SCRIPT_DEFAULT_VALUE,
      };
      set({ isInitEditAlgorithmInProgress: true });
      try {
        // Get algorithm
        const algorithm = await algorithmRepository.getAlgorithm(
          clientId,
          partnerId,
          advertiserId,
          algorithmId
        );

        if (!algorithm) {
          set({
            snackBarProps: {
              messages: ['Error in retrieving the algorithm'],
              severity: 'error',
              visible: true,
            },
          });
        } else {
          // Init script form with retrieved algorithm
          if (!algorithm?.script) {
            set({
              snackBarProps: {
                messages: [`No script found for algorithm ${algorithmId}`],
                severity: 'warning',
                visible: true,
              },
            });
          }

          // Init algorithm init data with retrieved algorithm
          initData = {
            type: algorithm?.strategy ?? '',
            name: algorithm?.name ?? '',
            script: algorithm?.script || SCRIPT_DEFAULT_VALUE,
          };
        }
      } catch (e) {
        set({
          snackBarProps: {
            messages: ['Error in retrieving the algorithm'],
            severity: 'error',
            visible: true,
          },
        });
      } finally {
        set({ isInitEditAlgorithmInProgress: false });
      }
      return initData;
    },
    modifyAlgorithm: async (): Promise<boolean> => {
      let modificationInSuccess = true;
      set({ isLoading: true });
      try {
        const clientId = get().clientId ?? '';
        const partnerId = get().partnerId ?? '';
        const advertiserId = get().advertiserId ?? '';
        const algorithmId = get().algorithmId ?? '';
        const script = get().scriptValue ?? '';
        await algorithmRepository.linkScriptToAlgorithm(
          clientId,
          partnerId,
          advertiserId,
          algorithmId,
          script
        );
        set({
          snackBarProps: {
            messages: ['Your script has been successfully created in a new version in DV360'],
            severity: 'success',
            visible: true,
          },
        });
      } catch (e) {
        set({
          snackBarProps: {
            messages: ['Error creating a new version of the script on DV360'],
            severity: 'error',
            visible: true,
          },
        });
        modificationInSuccess = false;
      } finally {
        set({ isLoading: false });
      }
      return modificationInSuccess;
    },
    resetEditAlgorithmValues: () => {
      set({
        editAlgorithmValues: EDIT_ALGO_DEFAULT_VALUES,
        algorithmFormDefaultValues: EDIT_ALGO_FORM_DEFAULT_VALUES,
      });
    },
  })
);
