import {
  AlgorithmFormValues,
  ConversionWeightFormValues,
  EditAlgorithmValues,
} from './bid-form.ts';

export type EntityStatus =
  | 'ENTITY_STATUS_UNSPECIFIED'
  | 'ENTITY_STATUS_ACTIVE'
  | 'ENTITY_STATUS_ARCHIVED'
  | 'ENTITY_STATUS_DRAFT'
  | 'ENTITY_STATUS_PAUSED'
  | 'ENTITY_STATUS_SCHEDULED_FOR_DELETION';

export type AlgorithmType =
  | 'CUSTOM_BIDDING_ALGORITHM_TYPE_UNSPECIFIED'
  | 'SCRIPT_BASED'
  | 'ADS_DATA_HUB_BASED'
  | 'GOAL_BUILDER_BASED'
  | 'RULE_BASED';

export enum ALGORITHM_STRATEGY {
  ATTENTION = 'Attention',
  PERFORMANCE = 'Performance',
  CARBON_REDUCTION = 'Carbon reduction',
}

export enum WEIGHT_PERIOD {
  PERIOD_90_DAYS = '90 days',
  PERIOD_30_DAYS = '30 days',
}

export const SCRIPT_DEFAULT_VALUE = '# write some python code here';

export const EDIT_ALGO_DEFAULT_VALUES: EditAlgorithmValues = {
  type: '',
  name: '',
  scriptGeneration: {
    insertionOrderIds: [],
    period: '',
    conversionWeight: {
      floodlightIds: [],
      isFloodlightPriority: false,
      floodlightPriorities: [],
      kValue: 0,
    },
    domainScoring: {
      isDomainScoringActivated: false,
      goal: '',
      maxCoefficient: undefined,
      aggressivity: undefined,
    },
  },
};

export const EDIT_ALGO_FORM_DEFAULT_VALUES: AlgorithmFormValues = {
  type: '',
  name: '',
};

export const CONVERSION_WEIGHT_FORM_DEFAULT_VALUES: ConversionWeightFormValues = {
  insertionOrderIds: [],
  floodlightIds: [],
  isFloodlightPriority: false,
  floodlightPriorities: [],
  kValue: 1.3,
  period: WEIGHT_PERIOD.PERIOD_90_DAYS,
  isDomainScoringActivated: false,
  goal: '',
  maxCoefficient: undefined,
  aggressivity: undefined,
};

export enum DOMAIN_SCORING_GOAL {
  MAXIMIZE = 'maximize',
  MINIMIZE = 'minimize',
}
