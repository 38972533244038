import { create } from 'zustand';
import { container } from '../../generic/ioc/container.ts';
import INJECTION_TOKENS from '../../generic/ioc/injection-tokens.ts';
import { BidPort } from '../port/bid.port.ts';
import {
  ValuesForm,
  setValuesForm,
  mappingOptions,
  LocationFormValues,
  travelMappingCategories,
} from '../model/bid-form.ts';
import { Bid, BidApi } from '../model/bid.ts';
import { temporal } from 'zundo';
import { isEqual } from 'lodash';
import { AxiosError } from 'axios';
import { AnalyticsPort } from '../port/analytics.port.ts';

export type BidAdjustmentSteps = 'landing' | 'form';

export type BidForm = {
  category: string;
  values?: ValuesForm;
  subBidForm: BidForm[];
};

type SnackBarSeverity = 'success' | 'error' | undefined;

type SnackbarProps = {
  messages: string[];
  severity: SnackBarSeverity;
  visible: boolean;
};

export enum BidFormStatusType {
  CREATE = 'Create',
  REVIEW = 'Review',
  EDIT = 'Edit',
}

type BidFormStatus = BidFormStatusType.CREATE | BidFormStatusType.REVIEW | BidFormStatusType.EDIT;

export type BidAdjustmentStore = {
  isLoading: boolean;
  isDrawerOpen: boolean;
  isModalOpen: boolean;
  isEditMode: boolean;
  snackBarProps: SnackbarProps;
  currentStep: BidAdjustmentSteps;
  bidForms: BidForm[];
  openDrawer: () => void;
  closeDrawer: () => void;
  openModal: () => void;
  closeModal: () => void;
  nextStep: () => void;
  setIsLoading: () => void;
  setSnackBarProps: (snackBarProps: SnackbarProps) => void;
  setBidForms: (bidForms: BidForm[]) => void;
  setNewBidForm: (bidForm: BidForm) => void;
  updateBidForm: (indexes: number[], bidForm: BidForm) => void;
  deleteBidForm: (indexes: number[]) => void;
  deleteAllExceptFirstBidForm: () => void;
  resetBidForm: () => void;
  saveBid: (adsetIds: string[], isDraft?: boolean) => Promise<void>;
  setBidFormsFromBid: (bid: BidApi) => void;
  notEditMode: () => void;
  getBidHistory: (adSetId: string) => Promise<void>;
  bidFormStatus: BidFormStatus;
  setBidFormStatus: (status: BidFormStatus) => void;
};

const deleteBidForm = (indexes: number[], array: BidForm[]): BidForm[] => {
  // Base case: If indexes array is empty, return the array as is
  if (indexes.length === 0) {
    return array;
  }

  const index = indexes[0]; // Get the first index from the array
  const newArray = [...array]; // Create a shallow copy of the array to avoid mutation

  if (indexes.length === 1) {
    // If it's the last index, delete the bidForm
    newArray.splice(index, 1);
  } else {
    // If there are more indexes, recursively delete the subBidForm
    newArray[index] = {
      ...newArray[index],
      subBidForm: deleteBidForm(indexes.slice(1), newArray[index].subBidForm),
    };
  }

  return newArray;
};

const updateBidForm = (indexes: number[], bidForm: BidForm, array: BidForm[]): BidForm[] => {
  // Base case: If indexes array is empty, return the updated array
  if (indexes.length === 0) {
    return array;
  }

  const index = indexes[0]; // Get the first index from the array
  const newArray = [...array]; // Create a shallow copy of the array to avoid mutation

  if (indexes.length === 1) {
    // If it's the last index, update the bidForm
    newArray[index] = bidForm;
  } else {
    // If there are more indexes, recursively update the subBidForm
    newArray[index] = {
      ...newArray[index],
      subBidForm: updateBidForm(indexes.slice(1), bidForm, newArray[index].subBidForm),
    };
  }

  return newArray;
};

const generateLocationFormValuesFromBid = (bid: Bid): BidForm[] => {
  const result: BidForm[] = [];

  ['countries', 'cities', 'regions'].forEach((subcategory: string) => {
    const options = (bid.home_location as unknown as { [key: string]: Bid })[subcategory];
    for (const option in options) {
      if (typeof options[option] === 'number') {
        const bidFormFound = result.find(
          (form) =>
            form?.values?.value === options[option] &&
            (form?.values as LocationFormValues)?.subcategory === subcategory
        );
        if (bidFormFound) {
          (bidFormFound.values as unknown as { [key: string]: string[] })[subcategory].push(option);
        } else {
          result.push({
            category: 'home_location',
            values: {
              [subcategory]: [option],
              value: options[option] as number,
              subcategory,
            },
            subBidForm: [],
          });
        }
      } else {
        const bidFormFound = result.find(
          (form) =>
            (form?.values as LocationFormValues)?.subcategory === subcategory &&
            isEqual(form.subBidForm, generateBidFormsFromBid(options[option] as Bid))
        );
        if (bidFormFound?.values) {
          (bidFormFound.values as unknown as { [key: string]: string[] })[subcategory].push(option);
        } else {
          const subBidForm = generateBidFormsFromBid(options[option] as Bid);
          result.push({
            category: 'home_location',
            subBidForm,
            values: {
              [subcategory]: [option],
              value: 1,
              subcategory,
            },
          });
        }
      }
    }
  });

  return result;
};

const generateBidFormsTravel = (category: string, bid: Bid): BidForm[] => {
  const result: BidForm[] = [];

  const options = bid[category] as Bid;

  for (const option in options) {
    if (typeof options[option] === 'number') {
      const bidFormFound = result.find(
        (form) =>
          form?.values?.value === options[option] &&
          form?.category === category &&
          category !== 'travel_start_date'
      );
      if (bidFormFound) {
        (bidFormFound.values as unknown as { [key: string]: string[] })['options'].push(option);
      } else if (option !== 'event_sources') {
        result.push({
          category,
          subBidForm: [],
          values: {
            customEvents: options['event_sources'] as string[],
            options: [option],
            value: options[option] as number,
          },
        });
      }
    } else {
      const bidFormFound = result.find(
        (form) =>
          travelMappingCategories.includes(form.category) &&
          isEqual(form.subBidForm, generateBidFormsFromBid(options[option] as Bid))
      );
      if (bidFormFound?.values && option !== 'event_sources') {
        (bidFormFound.values as unknown as { [key: string]: string[] })['options'].push(option);
      } else if (option !== 'event_sources') {
        const subBidForm = generateBidFormsFromBid(options[option] as Bid);
        result.push({
          category,
          subBidForm,
          values: {
            options: [option],
            customEvents: options['event_sources'] as string[],
            value: 1,
          },
        });
      }
    }
  }

  return result;
};

const generateBidFormsFromBid = (bid: Bid): BidForm[] => {
  const bidForms: BidForm[] = [];

  for (const category in bid) {
    const options = bid[category] as Bid;

    if (category === 'home_location') {
      const bidLocationForms = generateLocationFormValuesFromBid(bid);
      bidForms.push(...bidLocationForms);
      return bidForms;
    }

    if (travelMappingCategories.includes(category)) {
      const bidTravelForms = generateBidFormsTravel(category, bid);
      bidForms.push(...bidTravelForms);
      return bidForms;
    }

    for (const option in options) {
      if (typeof options[option] === 'number' && category !== 'travel_start_day_of_week') {
        handleNumberOption(bidForms, category, option, options[option] as number);
      } else {
        handleObjectOption(bidForms, category, option, options[option] as Bid);
      }
    }
  }

  return bidForms;
};

const handleNumberOption = (
  bidForms: BidForm[],
  category: string,
  option: string,
  value: number
) => {
  const bidFormFound = findBidForm(bidForms, value);
  if (bidFormFound) {
    const optionKey = mappingOptions[category];
    (bidFormFound.values as unknown as { [key: string]: string[] })[optionKey].push(option);
  } else {
    bidForms.push({
      category,
      values: setValuesForm(category, option, value),
      subBidForm: [],
    });
  }
};

const handleObjectOption = (bidForms: BidForm[], category: string, option: string, value: Bid) => {
  const bidFormFound = findBidFormBySubBidForm(bidForms, value);
  if (bidFormFound) {
    const optionKey = mappingOptions[category];
    (bidFormFound.values as unknown as { [key: string]: string[] })[optionKey].push(option);
  } else {
    const subBidForm = generateBidFormsFromBid(value);
    bidForms.push({
      category,
      subBidForm,
      values: setValuesForm(category, option, 1),
    });
  }
};

const findBidForm = (bidForms: BidForm[], value: number): BidForm | undefined => {
  return bidForms.find(
    (form) =>
      ['age', 'locale', 'custom_audience'].includes(form.category) && form?.values?.value === value
  );
};

const findBidFormBySubBidForm = (bidForms: BidForm[], value: Bid): BidForm | undefined => {
  return bidForms.find(
    (form) =>
      ['age', 'locale', 'custom_audience'].includes(form.category) &&
      isEqual(form.subBidForm, generateBidFormsFromBid(value))
  );
};

const generateBid = (bidForms: BidForm[]): Bid => {
  const bid: Bid = {};

  for (const bidForm of bidForms) {
    const { category, values, subBidForm } = bidForm;

    if (values && 'ageRanges' in values) {
      const ageRanges: string[] = values.ageRanges;

      for (const ageRange of ageRanges) {
        setValue(category, ageRange, subBidForm, values);
      }
    } else if (values && 'locales' in values) {
      const locales: string[] = values.locales;

      for (const locale of locales) {
        setValue(category, locale, subBidForm, values);
      }
    } else if (values && 'gender' in values) {
      const gender: string = values.gender;
      setValue(category, gender, subBidForm, values);
    } else if (values && 'device' in values) {
      const device: string = values.device;
      setValue(category, device, subBidForm, values);
    } else if (values && 'publisher' in values) {
      const publisher: string = values.publisher;
      setValue(category, publisher, subBidForm, values);
    } else if (values && 'userOS' in values) {
      const userOS: string = values.userOS;
      setValue(category, userOS, subBidForm, values);
    } else if (values && 'userDevice' in values) {
      const userDevice: string = values.userDevice;
      setValue(category, userDevice, subBidForm, values);
    } else if (values && 'positionType' in values) {
      const positionType: string = values.positionType;
      setValue(category, positionType, subBidForm, values);
    } else if (values && 'customAudience' in values) {
      const customAudience: string[] = values.customAudience;
      for (const audience of customAudience) {
        setValue(category, audience, subBidForm, values);
      }
    } else if (values && 'customEvents' in values && travelMappingCategories.includes(category)) {
      const customEvents: string[] = values.customEvents;
      bid[category] = bid[category] || {};
      (bid[category] as Bid)['event_sources'] = customEvents;
      const options: string[] = values.options;
      for (const option of options) {
        setValue(category, option, subBidForm, values);
      }
    } else if (values && 'subcategory' in values) {
      const countries = values.countries;
      const regions = values.regions;
      const cities = values.cities;
      const subcategory = values.subcategory as string;
      bid[category] = bid[category] || {};
      (bid[category] as Bid)[subcategory] = (bid[category] as Bid)[subcategory] || {};
      setCountriesValues(countries, bid, category, subcategory, subBidForm, values);
      setRegionsValues(regions, bid, category, subcategory, subBidForm, values);
      setCitiesValues(cities, bid, category, subcategory, subBidForm, values);
    } else if (subBidForm.length > 0) {
      bid[category] = generateBid(subBidForm);
    }
  }

  return bid;

  function setCountriesValues(
    countries: string[] | undefined,
    bid: Bid,
    category: string,
    subcategory: string,
    subBidForm: BidForm[],
    values: ValuesForm
  ) {
    for (const country of countries ?? []) {
      ((bid[category] as Bid)[subcategory] as Bid)[country] =
        subBidForm.length === 0 ? values?.value : generateBid(subBidForm);
    }
  }

  function setRegionsValues(
    regions: string[] | undefined,
    bid: Bid,
    category: string,
    subcategory: string,
    subBidForm: BidForm[],
    values: ValuesForm
  ) {
    for (const region of regions ?? []) {
      ((bid[category] as Bid)[subcategory] as Bid)[region] =
        subBidForm.length === 0 ? values.value : generateBid(subBidForm);
    }
  }

  function setCitiesValues(
    cities: string[] | undefined,
    bid: Bid,
    category: string,
    subcategory: string,
    subBidForm: BidForm[],
    values: ValuesForm
  ) {
    for (const city of cities ?? []) {
      ((bid[category] as Bid)[subcategory] as Bid)[city] =
        subBidForm.length === 0 ? values.value : generateBid(subBidForm);
    }
  }

  function setValue(category: string, key: string, subBidForm: BidForm[], values: ValuesForm) {
    bid[category] = bid[category] || {};
    (bid[category] as Bid)[key] = subBidForm.length === 0 ? values.value : generateBid(subBidForm);
  }
};

const bidRepository = container.get<BidPort>(INJECTION_TOKENS.BID_REPOSITORY);
const analytics = container.get<AnalyticsPort>(INJECTION_TOKENS.ANALYTICS_REPOSITORY);

export const useBidAdjustmentStore = create<BidAdjustmentStore>()(
  temporal((set, get) => ({
    bidFormStatus: BidFormStatusType.CREATE,
    isLoading: false,
    isDrawerOpen: false,
    isModalOpen: false,
    snackBarProps: {
      messages: [],
      severity: undefined,
      visible: false,
    },
    isEditMode: false,
    currentStep: 'landing',
    bidForms: [
      {
        category: '',
        subBidForm: [],
      },
    ],
    setBidForms: (bidForms: BidForm[]) => {
      set({ bidForms });
    },
    deleteAllExceptFirstBidForm: () => {
      set({ bidForms: [get().bidForms[0]] });
    },
    setNewBidForm: (bidForm: BidForm) => {
      const currentBidForms = get().bidForms;
      set({ bidForms: [...currentBidForms, bidForm] });
    },
    updateBidForm: (indexes: number[], bidForm: BidForm) => {
      const currentBidForms = get().bidForms;
      const newBidForms = updateBidForm(indexes, bidForm, currentBidForms);
      set({ bidForms: newBidForms });
    },
    deleteBidForm: (indexes: number[]) => {
      const currentBidForms = get().bidForms;
      const deletedBidForms = deleteBidForm(indexes, currentBidForms);
      const newBidForms = deletedBidForms.length
        ? deletedBidForms
        : [{ category: '', subBidForm: [] }];

      set({ bidForms: newBidForms });
    },
    resetBidForm: () => {
      set({ bidForms: [{ category: '', subBidForm: [] }] });
    },
    openDrawer: () => set({ isDrawerOpen: true }),
    closeDrawer: () => set({ isDrawerOpen: false, currentStep: 'landing', isEditMode: false }),
    openModal: () => set({ isModalOpen: true }),
    closeModal: () => set({ isModalOpen: false, currentStep: 'landing', isEditMode: false }),
    setIsLoading: () => set({ isLoading: !get().isLoading }),
    nextStep: () => set({ currentStep: 'form' }),
    setSnackBarProps: (snackBarProps) => {
      set({ snackBarProps });
    },
    saveBid: async (adsetIds, isDraft: boolean = false) => {
      const bidForms = get().bidForms;
      const bid = generateBid(bidForms);
      set({ isLoading: true });
      try {
        const response = isDraft
          ? await bidRepository.saveDraftBidAdjustment(adsetIds, bid)
          : await bidRepository.setBids(adsetIds, bid);

        const eventName = isDraft ? 'create_draft' : 'publish_bid';
        analytics.send({ name: eventName, payload: { adset_id: adsetIds } });

        if (response.error_messages.length > 0) {
          const snackBarProps: SnackbarProps = {
            messages: [],
            severity: 'error',
            visible: true,
          };
          for (const message of response.error_messages) {
            snackBarProps.messages.push(`Error setting ${isDraft ? 'draft' : ''} bid:\n${message}`);
          }
          set({
            snackBarProps,
          });
        } else {
          set({
            snackBarProps: {
              messages: [`${isDraft ? 'draft' : ''}Bid saved successfully`],
              severity: 'success',
              visible: true,
            },
          });
        }
      } catch (e) {
        set({
          snackBarProps: {
            messages: [(e as AxiosError)?.message as string],
            severity: 'error',
            visible: true,
          },
        });
      } finally {
        set({ isLoading: false });
      }
    },
    getBidHistory: async (adSetId: string) => {
      set({ isLoading: true });
      try {
        const response = await bidRepository.getBidHistory(adSetId);
        set({ isEditMode: true });
        const bidForms = generateBidFormsFromBid(response.user_groups);
        set({ bidForms });
      } catch (e) {
        set({
          snackBarProps: {
            messages: [(e as AxiosError)?.message as string],
            severity: 'error',
            visible: true,
          },
        });
      } finally {
        set({ isLoading: false });
      }
    },
    notEditMode: () => {
      set({ isEditMode: false });
    },
    setBidFormsFromBid: (bid: BidApi) => {
      set({ isEditMode: true });
      const bidForms = generateBidFormsFromBid(bid.user_groups);
      set({ bidForms });
    },
    setBidFormStatus: (bidFormStatus: BidFormStatus) => {
      set({ bidFormStatus });
    },
  }))
);
