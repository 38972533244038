import { injectable } from 'inversify';
import { UserPort } from '../../../core/port/user.port';

@injectable()
export class InMemoryUserRepository implements UserPort {
  shouldFailWithError = false;
  async createUser(): Promise<void> {
    if (this.shouldFailWithError) {
      throw new Error('Unknown error given by createUser of InMemoryUserRepository');
    }
    return Promise.resolve();
  }
}
