import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import { bidAdjustmentFieldStyle } from './styles';
import { NumberStep } from '../components/NumberStep';
import {
  BOOKING_WINDOW,
  BookingWindowFormValues,
  FormProps,
} from '../../../../../../core/meta/model/bid-form.ts';
import { useMetaStore } from '../../../../../../core/meta/store/meta-store.ts';
import { SelectedAdSetIdsWarning } from '../components/SelectedAdSetIdsWarning.tsx';
import usePromotedObject from './hooks/usePromotedObject.ts';

export const BookingWindowForm: React.FC<FormProps<BookingWindowFormValues>> = ({
  onChange,
  initialValues,
  disabled,
  excludeValues,
}) => {
  const adSetsSelected = useMetaStore((state) => state.adSetsSelected);
  const promotedObjectToDisplay = useMetaStore((state) => state.promotedObjectToDisplay);
  const totalCountSelectedAdsets = useMetaStore((state) => state.totalCountSelectedAdsets);

  const [bookingWindowValues, setBookingWindowFormValues] = useState<BookingWindowFormValues>(
    initialValues || { customEvents: [], options: [], value: 1 }
  );

  usePromotedObject();

  const handleChange = (
    e:
      | SelectChangeEvent<
          typeof bookingWindowValues.customEvents | typeof bookingWindowValues.options
        >
      | ChangeEvent<HTMLInputElement>
  ) => {
    const newValue =
      e.target instanceof HTMLInputElement ? parseFloat(e.target.value) : e.target.value;
    const newFormValue = { ...bookingWindowValues, [e.target.name]: newValue };
    setBookingWindowFormValues(newFormValue);
    onChange(newFormValue);
  };

  const bookingWindowRanges = BOOKING_WINDOW.filter((range) => !excludeValues?.includes(range));

  return (
    <>
      {!adSetsSelected?.promoted_object || totalCountSelectedAdsets > 1 ? (
        <SelectedAdSetIdsWarning totalCountSelectedAdsets={totalCountSelectedAdsets} />
      ) : (
        <>
          <FormControl sx={bidAdjustmentFieldStyle}>
            <InputLabel id="custom-event-label">Custom event</InputLabel>
            <Select
              labelId="custom-event-label"
              id="custom-event-select"
              name="customEvents"
              value={bookingWindowValues.customEvents}
              label="Custom event"
              multiple
              renderValue={(selected) => selected.join(', ')}
              onChange={handleChange}
            >
              <MenuItem value={promotedObjectToDisplay?.value}>
                <ListItemText primary={promotedObjectToDisplay?.valueToDisplay} />
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={bidAdjustmentFieldStyle}>
            <InputLabel id="booking-window-label">Booking window</InputLabel>
            <Select
              labelId="booking-window-label"
              id="booking-window-select"
              name="options"
              value={bookingWindowValues.options}
              label="Booking window"
              multiple
              renderValue={(selected) => selected.join(', ')}
              onChange={handleChange}
            >
              {bookingWindowRanges.map((range) => (
                <MenuItem key={range} value={range}>
                  <Checkbox checked={bookingWindowValues.options.indexOf(range) > -1} />
                  <ListItemText primary={range} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <NumberStep
            disabled={disabled}
            name="value"
            value={bookingWindowValues.value}
            onChange={handleChange}
          ></NumberStep>
        </>
      )}
    </>
  );
};
