export const AGE_RANGES = ['18-24', '25-34', '35-44', '45-54', '55-64', '65-100', 'default'];
export const SUB_CATEGORIES = ['cities', 'regions', 'countries'];
export const BOOKING_WINDOW = ['1-3', '5-9', '10-15', '16-20', '21-30', 'default'];
export const LENGTH_OF_STAY = ['1-3', '5-9', '10-15', '16-20', '21-30', 'default'];
export const TRAVEL_START_DATE_DAYS = [
  { display: 'Monday', value: '0' },
  { display: 'Tuesday', value: '1' },
  { display: 'Wednesday', value: '2' },
  { display: 'Thursday', value: '3' },
  { display: 'Friday', value: '4' },
  { display: 'Saturday', value: '5' },
  { display: 'Sunday', value: '6' },
  { display: 'Default', value: 'default' },
];
export const USER_RECENCY = [
  '0-86400',
  '86401-172800',
  '172801-259200',
  '259201-345600',
  '345601-432000',
  '432001-518400',
  '518401-604800',
  'default',
];

export const USER_DEVICES = [
  'iPad',
  'iPhone',
  'Desktop',
  'default',
  'Galaxy A54',
  'Galaxy A73 5G',
  'Galaxy Note20 Ultra 5G',
  'Galaxy S20 FE 5G',
  'Galaxy S21 5G',
  'Galaxy S21 FE 5G',
  'Galaxy S21 Ultra 5G',
  'Galaxy S22',
  'Galaxy S22+',
  'Galaxy S23+',
  'iPhone 11',
  'iPhone 12',
  'iPhone 12 Mini',
  'iPhone 12 Pro',
  'iPhone 12 Pro Max',
  'iPhone 13',
  'iPhone 13 Pro',
  'iPhone 13 Pro Max',
  'iPhone 14',
  'iPhone 14 Plus',
  'iPhone 15 Pro',
  'iPhone 15 Pro Max',
  'iPhone 6s',
  'iPhone 8',
  'iPhone 8 Plus',
  'iPhone SE 2nd Gen',
  'iPhone SE 3rd Gen',
  'iPhone X GSM',
  'iPhone XR',
  'iPhone XS',
  'iPhone XS Max',
  'galaxy z flip5',
];
export type AgeFormValues = {
  ageRanges: string[];
  value: number;
};

export type GenderFormValues = {
  gender: string;
  value: number;
};

export type DevicePlatformFormValues = {
  device: string;
  value: number;
};

export type LocationFormValues = {
  countries?: string[];
  regions?: string[];
  cities?: string[];
  value: number;
  subcategory?: string;
};

export type PublisherPlatformFormValues = {
  publisher: string;
  value: number;
};

export type UserOSFormValues = {
  userOS: string;
  value: number;
};

export type UserDeviceFormValues = {
  userDevice: string;
  value: number;
};

export type PositionTypeFormValues = {
  positionType: string;
  value: number;
};

export type LocaleFormValues = {
  locales: string[];
  value: number;
};

export type AudienceFormValues = {
  customAudience: string[];
  value: number;
};

export type BookingWindowFormValues = {
  customEvents: string[];
  options: string[];
  value: number;
};

export type LengthOfStayFormValues = {
  customEvents: string[];
  options: string[];
  value: number;
};

export type TravelStartDateValues = {
  customEvents: string[];
  options: string[];
  value: number;
};

export type TravelStartDayOfWeekValues = {
  customEvents: string[];
  options: string[];
  value: number;
};

export type UserRecencyValues = {
  customEvents: string[];
  options: string[];
  value: number;
};

export type ValuesForm =
  | AgeFormValues
  | GenderFormValues
  | LocationFormValues
  | DevicePlatformFormValues
  | PublisherPlatformFormValues
  | UserOSFormValues
  | UserDeviceFormValues
  | PositionTypeFormValues
  | AudienceFormValues
  | LocaleFormValues
  | BookingWindowFormValues
  | LengthOfStayFormValues
  | TravelStartDateValues
  | TravelStartDayOfWeekValues
  | UserRecencyValues;

export interface FormProps<T> {
  onChange: (values: ValuesForm) => void;
  initialValues?: T;
  disabled: boolean;
  excludeValues?: string[];
}

export const mappingOptions: { [key: string]: string } = {
  device_platform: 'device',
  publisher_platform: 'publisher',
  user_os: 'userOS',
  user_device: 'userDevice',
  position_type: 'positionType',
  age: 'ageRanges',
  locale: 'locales',
  custom_audience: 'customAudience',
};

export const travelMappingCategories: string[] = [
  'booking_window',
  'length_of_stay',
  'travel_start_date',
  'travel_start_day_of_week',
  'user_recency',
];

export const setValuesForm = (
  category: string,
  option: string,
  value: number
): ValuesForm | undefined => {
  return {
    [mappingOptions[category] || category]: ['age', 'locale', 'custom_audience'].includes(category)
      ? [option]
      : option,
    value,
  } as ValuesForm;
};
