import * as Sentry from "@sentry/react";
import { mediaTheme } from '@design-system/jts-design-system-react/media-theme';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.PROJECT_ENV,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
});

const element = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(element);

const theme = {
  ...mediaTheme,
  typography: {
    ...mediaTheme.typography,
    button: {
      textTransform: 'none',
    },
  },
};

const Main: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ThemeProvider>
  );
};

root.render(<Main />);

export default Main;
