const INJECTION_TOKENS = {
  META_API_HTTP_SERVICE: Symbol.for('META_API_HTTP_SERVICE'),
  DV360_API_HTTP_SERVICE: Symbol.for('DV360_API_HTTP_SERVICE'),
  FILE_SERVICE: Symbol.for('FILE_SERVICE'),
  AUTH_REPOSITORY: Symbol.for('AUTH_REPOSITORY'),
  CLIENT_REPOSITORY: Symbol.for('CLIENT_REPOSITORY'),
  CAMPAIGN_REPOSITORY: Symbol.for('CAMPAIGN_REPOSITORY'),
  LOCATION_REPOSITORY: Symbol.for('LOCATION_REPOSITORY'),
  USER_REPOSITORY: Symbol.for('USER_REPOSITORY'),
  BID_REPOSITORY: Symbol.for('BID_REPOSITORY'),
  AUDIENCE_REPOSITORY: Symbol.for('AUDIENCE_REPOSITORY'),
  ANALYTICS_REPOSITORY: Symbol.for('ANALYTICS_REPOSITORY'),
  CAN_USER_ACCESS_USECASE: Symbol.for('CAN_USER_ACCESS_USECASE'),
  GET_CLIENTS_USECASE: Symbol.for('GET_CLIENTS_USECASE'),
  CREATE_USER_USECASE: Symbol.for('CREATE_USER_USECASE'),
  GET_COUNTRIES_USECASE: Symbol.for('GET_COUNTRIES_USECASE'),
  GET_CITIES_USECASE: Symbol.for('GET_CITIES_USECASE'),
  GET_CITIES_BY_IDS_USECASE: Symbol.for('GET_CITIES_BY_IDS_USECASE'),
  GET_REGIONS_USECASE: Symbol.for('GET_REGIONS_USECASE'),
  GET_AUDIENCES_USECASE: Symbol.for('GET_AUDIENCES_USECASE'),
  DV360_CLIENT_REPOSITORY: Symbol.for('DV360_CLIENT_REPOSITORY'),
  DV360_ADVERTISER_REPOSITORY: Symbol.for('DV360_ADVERTISER_REPOSITORY'),
  DV360_ADVERTISER_MAPPER: Symbol.for('DV360_ADVERTISER_MAPPER'),
  DV360_FLOODLIGHT_REPOSITORY: Symbol.for('DV360_FLOODLIGHT_REPOSITORY'),
  DV360_FLOODLIGHT_MAPPER: Symbol.for('DV360_FLOODLIGHT_MAPPER'),
  DV360_IO_REPOSITORY: Symbol.for('DV360_IO_REPOSITORY'),
  DV360_IO_MAPPER: Symbol.for('DV360_IO_MAPPER'),
  DV360_ALGORITHM_REPOSITORY: Symbol.for('DV360_ALGORITHM_REPOSITORY'),
  DV360_WEIGHT_SCRIPT_GENERATION_MAPPER: Symbol.for('DV360_WEIGHT_SCRIPT_GENERATION_MAPPER'),
  DV360_CREATE_ALGO_USECASE: Symbol.for('DV360_CREATE_ALGO_USECASE'),
  DV360_CHANGELOG_REPOSITORY: Symbol.for('DV360_CHANGELOG_REPOSITORY'),
  DV360_CHANGELOG_MAPPER: Symbol.for('DV360_CHANGELOG_MAPPER'),
  DV360_EXPORT_CHANGELOG_TO_CSV_USECASE: Symbol.for('DV360_EXPORT_CHANGELOG_TO_CSV_USECASE'),
};

export default INJECTION_TOKENS;
