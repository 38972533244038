import { BidForm } from '../../../../../../core/meta/store/bid-adjustment-store.ts';

export interface Card {
  name: string;
  description: string;
  chips: string[];
  bidForms?: BidForm[];
  isDeactivated?: boolean;
}
export const cardsArray: Card[] = [
  {
    name: 'Custom',
    description:
      'Use the Custom Bid option to tailor your selections according to your needs. Select from various categories—such as location, age, gender, and others—and group them as desired. This feature allows you to create personalised combinations for your bids.',
    chips: [],
    bidForms: [],
  },
  {
    name: 'Socio-demographic targeting',
    description:
      'Group users by age and gender to prioritize audience targeting. Maximize bids for priority audiences and allocate resources wisely.',
    chips: ['Age', 'Gender'],
    bidForms: [
      {
        category: 'age',
        subBidForm: [
          {
            category: 'gender',
            subBidForm: [],
            values: { gender: '', value: 1 },
          },
        ],
        values: { ageRanges: [], value: 1 },
      },
    ],
  },
  {
    name: 'Advanced Geographic Targeting',
    description:
      'Optimise ad spend on geographically targeted users by visit recency. Enhance reach with audiences while minimising waste on non-priority areas and infrequent visitors.',
    chips: ['Location', 'Language', 'Recency'],
    bidForms: [
      {
        category: 'age',
        subBidForm: [],
        values: { ageRanges: ['18-24'], value: 0.8 },
      },
    ],
    isDeactivated: true,
  },
  {
    name: 'Behavioural Targeting',
    description:
      'Optimise spending per audience within ad sets. Tailor personas to campaign objectives. Capitalise exposure on high-performing devices.',
    chips: ['Custom audience', 'Device platform'],
    bidForms: [
      {
        category: 'custom_audience',
        subBidForm: [
          {
            category: 'device_platform',
            subBidForm: [],
            values: { userDevice: 'iPhone', value: 1 },
          },
          {
            category: 'device_platform',
            subBidForm: [],
            values: { userDevice: 'Desktop', value: 1 },
          },
        ],
        values: { customAudience: [], value: 1 },
      },
    ],
    isDeactivated: false,
  },
  {
    name: 'Travel Industry Targeting',
    description:
      'Optimise ad spend by focusing efforts where conversion chances are highest, considering platform-specific booking behaviours.',
    chips: ['Booking window', 'Travel start date', 'Device Platform', 'Travel start day of week'],
    bidForms: [
      {
        category: 'age',
        subBidForm: [],
        values: { ageRanges: ['18-24'], value: 0.8 },
      },
      {
        category: 'age',
        subBidForm: [],
        values: { ageRanges: ['25-34'], value: 0.9 },
      },
    ],
    isDeactivated: true,
  },
  {
    name: 'Multiplatform and Position Targeting',
    description:
      'Leverage past performance insights to focus investments on high-performing distribution frameworks, enhancing and repeating successes.',
    chips: ['Publisher platform', 'Position type'],
    bidForms: [
      {
        category: 'publisher_platform',
        subBidForm: [
          {
            category: 'position_type',
            subBidForm: [],
            values: { positionType: 'facebook_feed', value: 1 },
          },
          {
            category: 'position_type',
            subBidForm: [],
            values: { positionType: 'facebook_feed', value: 1 },
          },
        ],
        values: { publisher: 'facebook', value: 1 },
      },
      {
        category: 'publisher_platform',
        subBidForm: [
          {
            category: 'position_type',
            subBidForm: [],
            values: { positionType: 'instagram_profile_feed', value: 1 },
          },
          {
            category: 'position_type',
            subBidForm: [],
            values: { positionType: 'instagram_reels', value: 1 },
          },
          {
            category: 'position_type',
            subBidForm: [],
            values: { positionType: 'instagram_story', value: 1 },
          },
        ],
        values: { publisher: 'instagram', value: 1 },
      },
      {
        category: 'publisher_platform',
        subBidForm: [
          {
            category: 'position_type',
            subBidForm: [],
            values: { positionType: 'default', value: 1 },
          },
          {
            category: 'position_type',
            subBidForm: [],
            values: { positionType: 'default', value: 1 },
          },
        ],
        values: { publisher: 'audience_network', value: 1 },
      },
    ],
    isDeactivated: false,
  },
];
