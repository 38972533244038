import { Typography } from '@mui/material';
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import { CustomDetailPanelToggle } from '../components/CustomDetailPanel.tsx';
import { SelectPartnerButton } from './components/SelectPartnerButton.tsx';

export const DV360_CLIENT_PARTNER_LIST_COLUMNS: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Partner Name',
    minWidth: 300,
  },
  {
    field: 'id',
    headerName: 'Partner id',
    minWidth: 300,
  },
  {
    field: 'actions',
    headerName: '',
    sortable: false,
    minWidth: 160,
    renderCell: (params) => {
      return (
        <SelectPartnerButton
          clientId={`${params.row.clientId}`}
          partnerId={`${params.id}`}
        ></SelectPartnerButton>
      );
    },
  },
];

export const DV360_CLIENT_LIST_COLUMNS: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Id',
  },
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: (params) => <CustomDetailPanelToggle id={params.id} value={params.value} />,
  },
  {
    field: 'name',
    headerName: 'Clients',
    minWidth: 300,
    renderCell: (params) => (
      <div>
        <Typography>{params.row.name}</Typography>
        <Typography color="secondary" variant="caption">
          id: {params.row.id}
        </Typography>
      </div>
    ),
  },
  {
    field: 'partners',
    headerName: 'N. Partners',
    minWidth: 260,
    valueGetter: (params) => params?.row?.partners?.length,
    valueFormatter: (params: GridValueFormatterParams<number>) => `${params.value || 0} Partners`,
  },
];

export const DV360_CLIENT_LIST_INITIAL_STATE = {
  columns: {
    columnVisibilityModel: {
      id: false,
    },
  },
};
