import { injectable } from 'inversify';
import { Floodlight } from '../../../../core/dv360/model/floodlight.ts';

export type Dv360FloodlightProps = {
  id: string;
  name: string;
  insertion_order_id: string;
};

export type Dv360FloodlightsPerAdvertiserProps = {
  advertisers: {
    [advertiserId: string]: Dv360FloodlightProps[];
  };
};

@injectable()
export class Dv360FloodlightMapper {
  toDeserialized(props: Dv360FloodlightProps): Floodlight {
    return {
      id: props.id,
      name: props.name,
      insertionOrderId: props.insertion_order_id,
    };
  }
}
