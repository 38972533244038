import { Box, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import { CustomDetailPanelToggle } from '../components/CustomDetailPanel.tsx';
import { AlgoStatusChip } from './components/AlgoStatusChip.tsx';
import { AlgoStatusCircleIcon } from './components/AlgoStatusCircleIcon.tsx';
import { AlgoViewChangelogButton } from './components/AlgoViewChangelogButton.tsx';
import { CreateNewAlgoButton } from './components/CreateNewAlgoButton.tsx';
import { ModifyAlgoButton } from './components/ModifyAlgoButton.tsx';

export const DV360_ADVERTISER_ALGO_LIST_COLUMNS: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Algorithm',
    minWidth: 300,
    flex: 2,
    maxWidth: 450,
  },
  {
    field: 'id',
    headerName: 'Algorithm id',
    minWidth: 100,
    flex: 1,
    maxWidth: 150,
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 85,
    flex: 0.5,
    renderCell: (params) => {
      return <AlgoStatusChip status={params.value}></AlgoStatusChip>;
    },
  },
  {
    field: 'actions',
    headerName: '',
    sortable: false,
    minWidth: 300,
    renderCell: (params) => {
      return (
        <div style={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <ModifyAlgoButton
              advertiserId={params.row.advertiserId}
              algorithmId={`${params.id}`}
            ></ModifyAlgoButton>
            <AlgoViewChangelogButton
              advertiserId={params.row.advertiserId}
              algorithmId={`${params.id}`}
            ></AlgoViewChangelogButton>
          </Box>
        </div>
      );
    },
  },
];

export const DV360_ADVERTISER_LIST_COLUMNS: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Id',
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 100,
    renderCell: (params) => {
      return <AlgoStatusCircleIcon algorithms={params.row.algorithms} />;
    },
    align: 'center',
    headerAlign: 'center',
  },
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: (params) => <CustomDetailPanelToggle id={params.id} value={params.value} />,
  },
  {
    field: 'name',
    headerName: 'Advertiser',
    minWidth: 300,
    maxWidth: 500,
    renderCell: (params) => (
      <div>
        <Typography>{params.row.name}</Typography>
        <Typography color="secondary" variant="caption">
          id: {params.row.id}
        </Typography>
      </div>
    ),
  },
  {
    field: 'algorithms',
    headerName: 'N. Algorithms',
    minWidth: 260,
    valueGetter: (params) => params?.row?.algorithms?.length || 0,
  },
  {
    field: 'actions',
    headerName: '',
    sortable: false,
    minWidth: 260,
    renderCell: (params) => {
      return (
        <CreateNewAlgoButton
          clientId={`${params.row.clientId}`}
          partnerId={`${params.row.partnerId}`}
          advertiserId={`${params.id}`}
        />
      );
    },
  },
];

export const DV360_ADVERTISER_LIST_INITIAL_STATE = {
  columns: {
    columnVisibilityModel: {
      id: false,
    },
  },
};
