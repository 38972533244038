import { BidApi } from './bid.ts';

export enum CampaignObjective {
  OUTCOME_SALES = 'OUTCOME_SALES',
}

export type PromotedObject = {
  pixel_id?: string;
  page_id?: string;
  app_id?: string;
  event_id?: string;
};

export type PromotedObjectToDisplay = {
  valueToDisplay: string;
  value: string;
};

export type Campaign = {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  adsets: AdSet[];
  status: string;
  objective: string;
  accountId?: string;
};

export type AdSet = {
  id: string;
  name: string;
  currentBid: string;
  bidMultiplier: string;
  status: string;
  bid_status: string;
  updatedAt: string;
  reviewNeeded?: string;
  bid_adjustments?: BidApi;
  promoted_object?: PromotedObject;
  campaign_objective: string;
  accountId?: string;
};
