import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { DataGridProProps, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import React from 'react';
import { Client } from '../../../../core/dv360/model/client.ts';
import { useDV360Store } from '../../../../core/dv360/store/dv360-store.ts';
import { BreadCrumb } from '../../components/BreadCrumb.tsx';
import { StackSx } from '../../styles/elements.tsx';
import { DataTable } from '../components/DataTable.tsx';
import {
  DV360_CLIENT_LIST_COLUMNS,
  DV360_CLIENT_LIST_INITIAL_STATE,
  DV360_CLIENT_PARTNER_LIST_COLUMNS,
} from './DV360ClientListConfig.tsx';

export const DV360ClientListPage = () => {
  const clients = useDV360Store((state) => state.clients);
  const isLoading = useDV360Store((state) => state.isLoading);

  const getDetailPanelContent = React.useCallback<
    NonNullable<DataGridProProps['getDetailPanelContent']>
  >(({ row }) => {
    return (
      <Stack sx={{ marginLeft: '105px' }}>
        <DataTable
          rows={row.partners}
          columns={DV360_CLIENT_PARTNER_LIST_COLUMNS}
          pinnedColumns={{ right: ['actions'] }}
          hasBorder={false}
          searchLabel={'Search partners'}
        />
      </Stack>
    );
  }, []);

  const getDetailPanelHeight = React.useCallback(() => 'auto', []);

  return (
    <div>
      <BreadCrumb path="/" title="Home"></BreadCrumb>
      <h2 style={{ margin: '0', display: 'flex', alignItems: 'center' }}>
        <img
          key="dv360"
          src="/assets/icons/dv360.svg"
          alt="dv360 icon"
          width={'35px'}
          height={'35px'}
          style={{ margin: '8px' }}
        />{' '}
        Client List
      </h2>
      <Typography variant="caption">
        Choose a client to apply algorithms for their advertisers.{' '}
      </Typography>

      <Stack spacing={2} direction="row" sx={StackSx}>
        <DataTable<Client>
          initialState={DV360_CLIENT_LIST_INITIAL_STATE}
          rows={clients}
          columns={DV360_CLIENT_LIST_COLUMNS}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
          loading={isLoading}
          pinnedColumns={{ right: [GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field] }}
          searchLabel={'Search clients'}
        ></DataTable>
      </Stack>
    </div>
  );
};

export default DV360ClientListPage;
