import { Chip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import { CustomDetailPanelToggle } from '../components/CustomDetailPanel.tsx';
import { SelectAccountButton } from './components/SelectAccountButton.tsx';
import { ACCOUNTS_WHITE_LIST } from './hooks/accountsWhiteList.ts';

export const META_CLIENT_ACCOUNT_LIST_COLUMNS: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Account Name',
    minWidth: 300,
  },
  {
    field: 'id',
    headerName: 'Account id',
    minWidth: 300,
  },
  {
    field: 'asc',
    headerName: 'Bid Adjustment on ASC activated',
    minWidth: 400,
    renderCell: (params) => {
      return ACCOUNTS_WHITE_LIST.includes(`${params.id}`) ? (
        <Chip
          label="Activated"
          variant="outlined"
          color={'success'}
          sx={{ marginRight: '5px' }}
        ></Chip>
      ) : null;
    },
  },
  {
    field: 'actions',
    headerName: '',
    sortable: false,
    minWidth: 160,
    renderCell: (params) => {
      return (
        <SelectAccountButton
          clientId={`${params.row.clientId}`}
          accountId={`${params.id}`}
        ></SelectAccountButton>
      );
    },
  },
];

export const META_CLIENT_LIST_COLUMNS: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Id',
  },
  {
    field: 'name',
    headerName: 'Clients',
    minWidth: 300,
    renderCell: (params) => (
      <div>
        <Typography>{params.row.name}</Typography>
        <Typography color="secondary" variant="caption">
          id: {params.row.id}
        </Typography>
      </div>
    ),
  },
  {
    field: 'accounts',
    headerName: 'Campaigns & Ad Accounts',
    minWidth: 260,
    valueGetter: (params) => `${params?.row?.accounts?.length || 0} Accounts`,
  },
  { field: 'bids', headerName: 'Number of Bid Multipliers', minWidth: 240 },
  {
    field: 'updatedAt',
    headerName: 'Last Updated',
    minWidth: 150,
  },
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: (params) => <CustomDetailPanelToggle id={params.id} value={params.value} />,
  },
];

export const META_CLIENT_LIST_INITIAL_STATE = {
  columns: {
    columnVisibilityModel: {
      id: false,
    },
  },
};
