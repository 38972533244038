import { Button, Stack } from '@mui/material';
import {
  BidForm,
  useBidAdjustmentStore,
} from '../../../../../core/meta/store/bid-adjustment-store.ts';
import { CategoryForm } from './categories/CategoryForm';
import { CATEGORIES } from '../../../../../core/meta/model/bid.ts';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import { LoadingButton } from '@mui/lab';
import { useMetaStore } from '../../../../../core/meta/store/meta-store.ts';
import { useParams } from 'react-router-dom';

export const BidAdjustmentForm = () => {
  const { accountId } = useParams();
  const bidForms = useBidAdjustmentStore((state) => state.bidForms);
  const { undo, redo } = useBidAdjustmentStore.temporal.getState();
  const closeDrawer = useBidAdjustmentStore((state) => state.closeDrawer);
  const saveBid = useBidAdjustmentStore((state) => state.saveBid);
  const allSelectedAdSets = useMetaStore((state) => state.getAllSelectedAdSets());
  const setCampaigns = useMetaStore((state) => state.setCampaigns);

  const loading = useBidAdjustmentStore((state) => state.isLoading);

  const saveDraftBid = async () => {
    await saveBid(allSelectedAdSets, true);
    setCampaigns(accountId ?? '');
    closeDrawer();
  };

  return (
    <Stack>
      <Stack
        sx={{
          minWidth: '1000px',
          padding: '16px 16px 0 16px',
          minHeight: '400px',
          height: 'calc(100% - 72px)',
        }}
      >
        {bidForms.map((bidForm: BidForm, index: number) => {
          const indexes = [index];
          return (
            <CategoryForm
              key={bidForm.category + index}
              indexes={indexes}
              bidForm={bidForm}
              shouldDisplayAddButton={index === bidForms.length - 1}
              remainingCategories={CATEGORIES}
              disableCategorySelect={index !== 0}
            ></CategoryForm>
          );
        })}
      </Stack>
      <Stack
        direction="row"
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          minHeight: '16px',
          padding: '16px',
          borderTop: '1px solid #e0e0e0',
          zIndex: 10,
        }}
      >
        <Stack
          direction="row"
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ width: '200px' }}
        >
          <Button
            sx={{ width: '100px' }}
            size="small"
            onClick={() => undo()}
            startIcon={<UndoIcon />}
            color={'secondary'}
          >
            Undo
          </Button>
          <Button
            sx={{ width: '100px' }}
            size="small"
            onClick={() => redo()}
            startIcon={<RedoIcon />}
            color={'secondary'}
          >
            Redo
          </Button>
        </Stack>
        <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
          <Button
            size="small"
            sx={{ marginRight: '10px' }}
            onClick={() => closeDrawer()}
            color="primary"
            variant="text"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => saveDraftBid()}
            loading={loading}
            color="primary"
            variant="contained"
          >
            Save as draft
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  );
};
