import { injectable } from 'inversify';
import { LocationPort } from '../../../../core/meta/port/location.port.ts';
import { City, Country, Region } from '../../../../core/meta/model/location.ts';

@injectable()
export class InMemoryLocationRepository implements LocationPort {
  countries: Country[] = [];
  cities: City[] = [];
  regions: Region[] = [];

  setCountries(countries: Country[]): void {
    this.countries = countries;
  }
  setCities(cities: City[]): void {
    this.cities = cities;
  }
  setRegions(regions: Region[]): void {
    this.regions = regions;
  }
  async getCountries(): Promise<Country[]> {
    return this.countries;
  }
  async getCities(): Promise<City[]> {
    return this.cities;
  }
  async getCitiesByIds(): Promise<City[]> {
    return this.cities;
  }
  async getRegions(): Promise<Region[]> {
    return this.regions;
  }
}
