export type SnackBarSeverity = 'success' | 'error' | 'warning' | 'info' | undefined;

export type SnackbarProps = {
  messages: string[];
  severity: SnackBarSeverity;
  visible: boolean;
};

export const SNACKBAR_DEFAULT_VALUE = {
  messages: [],
  severity: undefined,
  visible: false,
};
