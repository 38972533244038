import 'reflect-metadata';
import { /*inject, */ injectable } from 'inversify';
import { AuthPort } from '../../../core/port/auth.port.ts';
// import INJECTION_TOKENS from '../../../core/generic/ioc/injection-tokens.ts';
// import type HttpService from '../../../core/generic/http-service.port.ts';

@injectable()
export class AuthRepository implements AuthPort {
  constructor() {} // @inject(INJECTION_TOKENS.HTTP_SERVICE) private readonly httpService: HttpService

  async canAccess(): Promise<boolean> {
    // this.httpService
    //   .post('/can_access')
    //   .then(() => true)
    //   .catch(() => false);
    return true;

    //
  }
}
