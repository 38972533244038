import { useState, useCallback, useEffect } from 'react';
import { AGE_RANGES, AgeFormValues } from '../../../../../../../core/meta/model/bid-form.ts';

export const useAgeForm = (
  initialValues: AgeFormValues | undefined,
  onChange: (values: AgeFormValues) => void,
  excludeValues?: string[]
) => {
  const [ageFormValues, setAgeFormValues] = useState<AgeFormValues>(
    initialValues || { ageRanges: [], value: 1 }
  );
  const [customAgeStart, setCustomAgeStart] = useState<number>(0);
  const [customAgeEnd, setCustomAgeEnd] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);

  const handleCustomAgeStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') setCustomAgeStart(0);
    setCustomAgeStart(parseInt(e.target.value));
  };

  const handleCustomAgeEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') setCustomAgeEnd(0);
    setCustomAgeEnd(parseInt(e.target.value));
  };

  const validateCustomAge = useCallback(() => {
    if (isNaN(customAgeStart) || isNaN(customAgeEnd)) {
      setError('Both start and end ages must be filled.');
      return false;
    }
    if (customAgeStart > customAgeEnd) {
      setError('The start age must be less than or equal to the end age.');
      return false;
    }
    if (customAgeStart < 18 || customAgeEnd > 100) {
      setError('The age range must be between 18 and 100.');
      return false;
    }
    const selectedAges = ageFormValues.ageRanges.map((range) =>
      range.split('-').map((age) => parseInt(age, 10))
    );

    for (const selectedRange of selectedAges) {
      const lastSelectedAge = selectedRange[1];
      if (customAgeStart <= lastSelectedAge) {
        setError(`The start age must be greater than ${lastSelectedAge}`);
        return false;
      }
    }
    setError(null);
    return true;
  }, [customAgeStart, customAgeEnd, ageFormValues.ageRanges]);

  useEffect(() => {
    if (customAgeStart !== 0 || customAgeEnd !== 0) validateCustomAge();
  }, [customAgeStart, customAgeEnd, validateCustomAge]);

  const addCustomAge = () => {
    const customAge = `${customAgeStart}-${customAgeEnd}`;
    if (customAge && validateCustomAge() && !ageFormValues.ageRanges.includes(customAge)) {
      const newFormValue = { ...ageFormValues, ageRanges: [...ageFormValues.ageRanges, customAge] };
      setAgeFormValues(newFormValue);
      onChange(newFormValue);
      setCustomAgeStart(0);
      setCustomAgeEnd(0);
    }
  };

  const handleDelete = (chipToDelete: string) => () => {
    const newAgeRanges = ageFormValues.ageRanges.filter((range) => range !== chipToDelete);
    const newFormValue = { ...ageFormValues, ageRanges: newAgeRanges };
    setAgeFormValues(newFormValue);
    onChange(newFormValue);
  };

  // Filter available age ranges based on excludeValues
  const filterAvailableAgeRanges = () => {
    const excludeRanges =
      excludeValues?.map((range) => range.split('-').map((age) => parseInt(age, 10))) || [];

    const selectedCustomRanges = ageFormValues.ageRanges.map((range) =>
      range.split('-').map((age) => parseInt(age, 10))
    );

    const isRangeExcluded = (rangeAges: number[]) => {
      return (
        excludeRanges.some((excludedRange) => {
          return rangeAges[0] <= excludedRange[1] && rangeAges[1] >= excludedRange[0];
        }) ||
        selectedCustomRanges.some((selectedRange) => {
          return rangeAges[0] <= selectedRange[1] && rangeAges[1] >= selectedRange[0];
        })
      );
    };

    const customAgeRange = [customAgeStart, customAgeEnd];
    return AGE_RANGES.filter((range) => {
      if (range === 'default') return true;
      const rangeAges =
        range === '65+' ? [65, 100] : range.split('-').map((age) => parseInt(age, 10));
      const doesOverlap = customAgeRange[0] <= rangeAges[1] && customAgeRange[1] >= rangeAges[0];
      return !doesOverlap && !isRangeExcluded(rangeAges);
    });
  };

  const availableAgeRanges = filterAvailableAgeRanges();

  return {
    availableAgeRanges,
    ageFormValues,
    customAgeStart,
    customAgeEnd,
    error,
    handleCustomAgeStartChange,
    handleCustomAgeEndChange,
    addCustomAge,
    handleDelete,
    setAgeFormValues,
  };
};
