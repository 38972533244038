import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import { bidAdjustmentFieldStyle } from './styles';
import { NumberStep } from '../components/NumberStep';
import {
  TravelStartDayOfWeekValues,
  FormProps,
  TRAVEL_START_DATE_DAYS,
} from '../../../../../../core/meta/model/bid-form.ts';
import { useMetaStore } from '../../../../../../core/meta/store/meta-store.ts';
import { SelectedAdSetIdsWarning } from '../components/SelectedAdSetIdsWarning.tsx';
import usePromotedObject from './hooks/usePromotedObject.ts';

export const TravelStartDayOfWeekForm: React.FC<FormProps<TravelStartDayOfWeekValues>> = ({
  onChange,
  initialValues,
  disabled,
  excludeValues,
}) => {
  const adSetsSelected = useMetaStore((state) => state.adSetsSelected);
  const promotedObjectToDisplay = useMetaStore((state) => state.promotedObjectToDisplay);
  const totalCountSelectedAdsets = useMetaStore((state) => state.totalCountSelectedAdsets);

  usePromotedObject();

  const [travelStartDayOfWeekValues, setTravelStartDayOfWeekValues] =
    useState<TravelStartDayOfWeekValues>(
      initialValues || { customEvents: [], options: [], value: 1 }
    );

  const handleChange = (
    e:
      | SelectChangeEvent<
          typeof travelStartDayOfWeekValues.customEvents | typeof travelStartDayOfWeekValues.options
        >
      | ChangeEvent<HTMLInputElement>
  ) => {
    const newValue =
      e.target instanceof HTMLInputElement ? parseFloat(e.target.value) : e.target.value;
    const newFormValue = { ...travelStartDayOfWeekValues, [e.target.name]: newValue };
    setTravelStartDayOfWeekValues(newFormValue);
    onChange(newFormValue);
  };

  const travelStartDateOfTheWeek = TRAVEL_START_DATE_DAYS.filter(
    (range) => !excludeValues?.includes(range.value)
  );

  return (
    <>
      {!adSetsSelected?.promoted_object || totalCountSelectedAdsets > 1 ? (
        <SelectedAdSetIdsWarning totalCountSelectedAdsets={totalCountSelectedAdsets} />
      ) : (
        <>
          <FormControl sx={bidAdjustmentFieldStyle}>
            <InputLabel id="custom-event-label">Custom event</InputLabel>
            <Select
              labelId="custom-event-label"
              id="custom-event-select"
              name="customEvents"
              value={travelStartDayOfWeekValues.customEvents}
              label="Custom event"
              multiple
              renderValue={(selected) => selected.join(', ')}
              onChange={handleChange}
            >
              <MenuItem value={promotedObjectToDisplay?.value}>
                <ListItemText primary={promotedObjectToDisplay?.valueToDisplay} />
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={bidAdjustmentFieldStyle}>
            <InputLabel id="travel-start-date-of-the-week-label">
              Travel start date of the week
            </InputLabel>
            <Select
              labelId="travel-start-date-of-the-week-label"
              id="travel-start-date-of-the-week-select"
              name="options"
              value={travelStartDayOfWeekValues.options}
              label="Travel start date of the week"
              multiple
              renderValue={(selected) => selected.join(', ')}
              onChange={handleChange}
            >
              {travelStartDateOfTheWeek.map((range) => (
                <MenuItem key={range.value} value={range.value}>
                  <Checkbox
                    checked={travelStartDayOfWeekValues.options.indexOf(range.value) > -1}
                  />
                  <ListItemText primary={range.display} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <NumberStep
            disabled={disabled}
            name="value"
            value={travelStartDayOfWeekValues.value}
            onChange={handleChange}
          ></NumberStep>
        </>
      )}
    </>
  );
};
