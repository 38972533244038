import { injectable } from "inversify";

@injectable()
export class FileService {
    toCSV(content: string, fileName: string) {
        const encodedUri = encodeURI('data:text/csv;charset=utf-8,' + content);

        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `${fileName}-export.csv`);

        document.body.appendChild(link);
        link.click();
    }
}