import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { bidAdjustmentFieldStyle, bidAdjustmentFieldStyleHalf } from '../styles.ts';
import { NumberStep } from '../../components/NumberStep.tsx';
import {
  FormProps,
  LocationFormValues,
  SUB_CATEGORIES,
} from '../../../../../../../core/meta/model/bid-form.ts';
import { RenderLocationForm } from './RenderLocationForm.tsx';
import { LocationFormValueToSend } from './location-form-props.ts';

export const LocationForm: React.FC<FormProps<LocationFormValues>> = ({
  onChange,
  initialValues,
  disabled,
  excludeValues,
}) => {
  const currentInitialValues = initialValues || { value: 1, countries: [], subcategory: '' };
  const [locationFormValues, setLocationFormValues] =
    useState<LocationFormValues>(currentInitialValues);
  const handleChange = (
    e:
      | SelectChangeEvent<string>
      | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | LocationFormValueToSend
  ) => {
    let newFormValue: LocationFormValues;
    if ('name' in e && 'values' in e) {
      newFormValue = {
        value: locationFormValues.value,
        [e.name]: e.values,
        subcategory: e.name,
      } as LocationFormValues;
    } else {
      const newValue = e.target.name === 'value' ? parseFloat(e.target.value) : e.target.value;
      newFormValue = { ...locationFormValues, [e.target.name]: newValue };
    }
    setLocationFormValues(newFormValue);
    onChange(newFormValue);
  };

  const setFieldStyle = () => {
    return locationFormValues.subcategory === 'default'
      ? bidAdjustmentFieldStyle
      : bidAdjustmentFieldStyleHalf;
  };

  return (
    <>
      <FormControl sx={setFieldStyle()}>
        <InputLabel id="location-subcategory-label">Sub Category</InputLabel>
        <Select
          labelId="location-subcategory-label"
          id="location-subcategory-select"
          value={locationFormValues.subcategory}
          label="Sub Category"
          name="subcategory"
          onChange={handleChange}
          sx={{ minWidth: '250px' }}
        >
          {SUB_CATEGORIES.map((range) => (
            <MenuItem key={range} value={range}>
              {range}
            </MenuItem>
          ))}
          <MenuItem key="default" value="default">
            Default
          </MenuItem>
        </Select>
      </FormControl>
      {locationFormValues.subcategory !== 'default' && (
        <FormControl sx={{ ...bidAdjustmentFieldStyleHalf }}>
          <RenderLocationForm
            subcategory={initialValues?.subcategory}
            onChange={handleChange}
            options={[]}
            excludeValues={excludeValues}
            locationFormValues={currentInitialValues}
          />
        </FormControl>
      )}
      <NumberStep
        disabled={disabled}
        name="value"
        value={locationFormValues.value}
        onChange={handleChange}
      />
    </>
  );
};
