import { Button } from '@mui/material';
import INJECTION_TOKENS from '../../../../../core/generic/ioc/injection-tokens.ts';
import { AnalyticsPort } from '../../../../../core/meta/port/analytics.port.ts';
import { useInjection } from '../../../ioc/use-injection.ts';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useCustomBidStore } from '../../../../../core/dv360/store/custom-bid-store.ts';

export const CreateNewAlgoButton = ({
  clientId,
  partnerId,
  advertiserId,
}: {
  clientId: string;
  partnerId: string;
  advertiserId: string;
}) => {
  const analytics = useInjection<AnalyticsPort>(INJECTION_TOKENS.ANALYTICS_REPOSITORY);
  const openCreationAlgoDrawer = useCustomBidStore((state) => state.openCreationAlgoDrawer);
  const setAdvertiserId = useCustomBidStore((state) => state.setAdvertiserId);
  return (
    <Button
      sx={{ width: 231, height: 42 }}
      variant="outlined"
      onClick={(e) => {
        e.stopPropagation();
        analytics.send({
          name: 'navigate_to_create_new_algo',
          payload: { clientId: clientId, partnerId: partnerId, advertiserId: advertiserId },
        });
        setAdvertiserId(advertiserId);
        openCreationAlgoDrawer();
      }}
      endIcon={<ArrowForwardIcon fontSize="large" />}
    >
      Create new algorithm
    </Button>
  );
};
