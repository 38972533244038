import { Box, Button, Divider, Grid, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useBidAdjustmentStore } from '../../../../../../core/meta/store/bid-adjustment-store.ts';
import { cardsArray } from './template-card-config.ts';
import { TemplateCard } from './TemplateCard.tsx';
import { Stack } from '@mui/system';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '1200px',
  bgcolor: 'background.paper',
  borderRadius: '12px',
  boxShadow: 24,
};

interface TemplateModalProps {
  isModalOpen: boolean;
}

export const TemplateModal = ({ isModalOpen }: TemplateModalProps) => {
  const closeModal = useBidAdjustmentStore((state) => state.closeModal);

  return (
    <Modal
      open={isModalOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ p: 4 }}>
          <Typography sx={{ fontSize: '24px' }} component="h1">
            Templates
          </Typography>
          <Button onClick={closeModal}>
            <CloseIcon />
          </Button>
        </Stack>
        <Divider />
        <Box sx={{ flexGrow: 1, p: 4 }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {cardsArray.map((item, index) => (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <TemplateCard card={item} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};
