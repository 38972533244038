import { Box, Button, Card, CardActions, CardContent, Divider, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fullWidthSx, justifyContentCenterSx } from '../styles/elements';
import INJECTION_TOKENS from '../../../core/generic/ioc/injection-tokens';
import { AnalyticsPort } from '../../../core/meta/port/analytics.port.ts';
import { useInjection } from '../ioc/use-injection';

export type GetStartedCardProps = {
  title: string;
  description: string;
  buttonLabel: string;
  link?: string;
  icons?: string[];
};

const mappingLink: { [key: string]: string } = {
  '/meta': 'go_to_meta',
  '/dv360': 'go_to_dv360',
};

const GetStartedCard = ({ title, description, buttonLabel, link, icons }: GetStartedCardProps) => {
  const navigate = useNavigate();
  const analytics = useInjection<AnalyticsPort>(INJECTION_TOKENS.ANALYTICS_REPOSITORY);

  const sendAnalytics = (link: string) => {
    const name = mappingLink[link];
    analytics.send({ name, payload: {} });
  };

  const navigateTo = () => {
    if (link) {
      sendAnalytics(link);
      navigate(link);
    }
  };
  return (
    <Box sx={fullWidthSx}>
      <Card
        variant="outlined"
        sx={{
          height: '100%',
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CardContent sx={{ height: 'calc(100% - 56px);' }}>
          <h5 style={{ margin: '0px 0 16px 0' }}>{title}</h5>
          <Typography variant="caption">{description}</Typography>
          {icons && (
            <Box>
              {icons.map((icon) => (
                <img
                  key={icon}
                  src={`/assets/icons/${icon}.svg`}
                  alt={`${icon} icon`}
                  width={'35px'}
                  height={'35px'}
                  style={{ margin: '8px' }}
                />
              ))}
            </Box>
          )}
        </CardContent>
        <Divider />
        <CardActions sx={justifyContentCenterSx}>
          <Button disabled={!link} onClick={navigateTo}>
            {buttonLabel}
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default GetStartedCard;
