import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import { bidAdjustmentFieldStyle } from './styles';
import { NumberStep } from '../components/NumberStep';
import {
  LENGTH_OF_STAY,
  LengthOfStayFormValues,
  FormProps,
} from '../../../../../../core/meta/model/bid-form.ts';
import { useMetaStore } from '../../../../../../core/meta/store/meta-store.ts';
import { SelectedAdSetIdsWarning } from '../components/SelectedAdSetIdsWarning.tsx';
import usePromotedObject from './hooks/usePromotedObject.ts';

export const LengthOfStayForm: React.FC<FormProps<LengthOfStayFormValues>> = ({
  onChange,
  initialValues,
  disabled,
  excludeValues,
}) => {
  const adSetsSelected = useMetaStore((state) => state.adSetsSelected);
  const promotedObjectToDisplay = useMetaStore((state) => state.promotedObjectToDisplay);
  const totalCountSelectedAdsets = useMetaStore((state) => state.totalCountSelectedAdsets);

  const [lengthOfStayValues, setLengthOfStayFormValues] = useState<LengthOfStayFormValues>(
    initialValues || { customEvents: [], options: [], value: 1 }
  );

  usePromotedObject();

  const handleChange = (
    e:
      | SelectChangeEvent<
          typeof lengthOfStayValues.customEvents | typeof lengthOfStayValues.options
        >
      | ChangeEvent<HTMLInputElement>
  ) => {
    const newValue =
      e.target instanceof HTMLInputElement ? parseFloat(e.target.value) : e.target.value;
    const newFormValue = { ...lengthOfStayValues, [e.target.name]: newValue };
    setLengthOfStayFormValues(newFormValue);
    onChange(newFormValue);
  };

  const lengthOfStayRanges = LENGTH_OF_STAY.filter((range) => !excludeValues?.includes(range));

  return (
    <>
      {!adSetsSelected?.promoted_object || totalCountSelectedAdsets > 1 ? (
        <SelectedAdSetIdsWarning totalCountSelectedAdsets={totalCountSelectedAdsets} />
      ) : (
        <>
          <FormControl sx={bidAdjustmentFieldStyle}>
            <InputLabel id="custom-event-label">Custom event</InputLabel>
            <Select
              labelId="custom-event-label"
              id="custom-event-select"
              name="customEvents"
              value={lengthOfStayValues.customEvents}
              label="Custom event"
              multiple
              renderValue={(selected) => selected.join(', ')}
              onChange={handleChange}
            >
              <MenuItem value={promotedObjectToDisplay?.value}>
                <ListItemText primary={promotedObjectToDisplay?.valueToDisplay} />
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={bidAdjustmentFieldStyle}>
            <InputLabel id="length-of-stay-label">Length of stay</InputLabel>
            <Select
              labelId="length-of-stay-label"
              id="length-of-stay-select"
              name="options"
              value={lengthOfStayValues.options}
              label="Length of stay"
              multiple
              renderValue={(selected) => selected.join(', ')}
              onChange={handleChange}
            >
              {lengthOfStayRanges.map((range) => (
                <MenuItem key={range} value={range}>
                  <Checkbox checked={lengthOfStayValues.options.indexOf(range) > -1} />
                  <ListItemText primary={range} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <NumberStep
            disabled={disabled}
            name="value"
            value={lengthOfStayValues.value}
            onChange={handleChange}
          ></NumberStep>
        </>
      )}
    </>
  );
};
