import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import { bidAdjustmentFieldStyle } from './styles';
import { NumberStep } from '../components/NumberStep';
import { UserRecencyValues, FormProps, USER_RECENCY } from '../../../../../../core/meta/model/bid-form.ts';
import { useMetaStore } from '../../../../../../core/meta/store/meta-store.ts';
import { SelectedAdSetIdsWarning } from '../components/SelectedAdSetIdsWarning.tsx';
import usePromotedObject from './hooks/usePromotedObject.ts';

export const UserRecencyForm: React.FC<FormProps<UserRecencyValues>> = ({
  onChange,
  initialValues,
  disabled,
  excludeValues,
}) => {
  const adSetsSelected = useMetaStore((state) => state.adSetsSelected);
  const promotedObjectToDisplay = useMetaStore((state) => state.promotedObjectToDisplay);
  const totalCountSelectedAdsets = useMetaStore((state) => state.totalCountSelectedAdsets);

  const [userRecencyValues, setUserRecencyFormValues] = useState<UserRecencyValues>(
    initialValues || { customEvents: [], options: [], value: 1 }
  );

  usePromotedObject();

  const handleChange = (
    e:
      | SelectChangeEvent<typeof userRecencyValues.customEvents | typeof userRecencyValues.options>
      | ChangeEvent<HTMLInputElement>
  ) => {
    const newValue =
      e.target instanceof HTMLInputElement ? parseFloat(e.target.value) : e.target.value;
    const newFormValue = { ...userRecencyValues, [e.target.name]: newValue };
    setUserRecencyFormValues(newFormValue);
    onChange(newFormValue);
  };

  const userRecencyRanges = USER_RECENCY.filter((range) => !excludeValues?.includes(range));

  return (
    <>
      {!adSetsSelected?.promoted_object || totalCountSelectedAdsets > 1 ? (
        <SelectedAdSetIdsWarning totalCountSelectedAdsets={totalCountSelectedAdsets} />
      ) : (
        <>
          <FormControl sx={bidAdjustmentFieldStyle}>
            <InputLabel id="custom-event-label">Custom event</InputLabel>
            <Select
              labelId="custom-event-label"
              id="custom-event-select"
              name="customEvents"
              value={userRecencyValues.customEvents}
              label="Custom event"
              multiple
              renderValue={(selected) => selected.join(', ')}
              onChange={handleChange}
            >
              <MenuItem value={promotedObjectToDisplay?.value}>
                <ListItemText primary={promotedObjectToDisplay?.valueToDisplay} />
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={bidAdjustmentFieldStyle}>
            <InputLabel id="user-recency-label">User recency</InputLabel>
            <Select
              labelId="user-recency-label"
              id="user-recency-select"
              name="options"
              value={userRecencyValues.options}
              label="User recency"
              multiple
              renderValue={(selected) => selected.join(', ')}
              onChange={handleChange}
            >
              {userRecencyRanges.map((range) => (
                <MenuItem key={range} value={range}>
                  <Checkbox checked={userRecencyValues.options.indexOf(range) > -1} />
                  <ListItemText primary={range} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <NumberStep
            disabled={disabled}
            name="value"
            value={userRecencyValues.value}
            onChange={handleChange}
          ></NumberStep>
        </>
      )}
    </>
  );
};
