import { injectable } from 'inversify/lib/annotation/injectable';
import { Advertiser } from '../../../../core/dv360/model/advertiser.ts';
import { Dv360AdvertiserPort } from '../../../../core/dv360/port/dv360-advertiser.port.ts';

@injectable()
export class InMemoryDv360AdvertiserRepository implements Dv360AdvertiserPort {
  advertisers: Advertiser[] = [];
  getAdvertisersError: boolean = false;

  clearData() {
    this.advertisers = [];
    this.getAdvertisersError = false;
  }

  setAdvertisers(advertisers: Advertiser[]) {
    this.advertisers = advertisers;
  }

  setGetAdvertisersError(error: boolean) {
    this.getAdvertisersError = error;
  }

  async getAdvertisers(clientId: string, partnerId: string) {
    if (this.getAdvertisersError) {
      throw new Error(`Error fetching advertisers for client ${clientId} and partner ${partnerId}`);
    }
    return this.advertisers;
  }
}
