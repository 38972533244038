import { create } from 'zustand';
import { container } from '../../generic/ioc/container.ts';
import INJECTION_TOKENS from '../../generic/ioc/injection-tokens.ts';
import { SNACKBAR_DEFAULT_VALUE, SnackbarProps } from '../../model/snackbar-props.ts';
import { convertToFloodlightPriorities, giveStartDateFromPeriod } from '../model/algorithm.ts';
import {
  ConversionWeightFormValues,
  ConversionWeightValues,
  DomainScoringValues,
} from '../model/bid-form.ts';
import { CONVERSION_WEIGHT_FORM_DEFAULT_VALUES, WEIGHT_PERIOD } from '../model/constant.ts';
import { Floodlight, FloodlightValue } from '../model/floodlight.ts';
import { BiddingScriptGenerationData } from '../model/script.ts';
import { Dv360AlgorithmPort } from '../port/dv360-algorithm.port.ts';

type ScriptState = {
  snackBarProps: SnackbarProps;
  previewScript: string;
  isGenerationLoading: boolean;
  isScriptPreview: boolean;
  floodlightAutoSelectValues: FloodlightValue[];
  floodlightIdsValues: string[];
  isFloodlightPriorityActive: boolean;
  formDefaultValues: ConversionWeightFormValues;
};

type ScriptActions = {
  reset: () => void;
  showScriptPreview: () => void;
  hideScriptPreview: () => void;
  generateScript: (
    clientId: string,
    insertionOrderIds: string[],
    period: string,
    conversionWeightValues: ConversionWeightValues,
    domainScoringValues: DomainScoringValues
  ) => Promise<boolean>;
  setFloodlightAutoSelectValues: (floodlights: Floodlight[]) => void;
  setFloodlightIdsValues: (value: string[]) => void;
  setFormDefaultValues: (values: ConversionWeightFormValues) => void;
  resetValues: () => void;
};

const initialState: ScriptState = {
  snackBarProps: SNACKBAR_DEFAULT_VALUE,
  previewScript: '',
  isGenerationLoading: false,
  isScriptPreview: false,
  floodlightAutoSelectValues: [],
  floodlightIdsValues: [],
  isFloodlightPriorityActive: false,
  formDefaultValues: CONVERSION_WEIGHT_FORM_DEFAULT_VALUES,
};

// ADAPTERS DRIVEN
const algorithmRepository = container.get<Dv360AlgorithmPort>(
  INJECTION_TOKENS.DV360_ALGORITHM_REPOSITORY
);

export const useScriptStore = create<ScriptState & ScriptActions>((set) => ({
  ...initialState,
  reset: () => {
    set(initialState);
  },
  showScriptPreview: () => set({ isScriptPreview: true }),
  hideScriptPreview: () => set({ isScriptPreview: false }),
  generateScript: async (
    clientId: string,
    insertionOrderIds: string[],
    period: string,
    conversionWeightValues: ConversionWeightValues,
    domainScoringValues: DomainScoringValues
  ): Promise<boolean> => {
    let generationInSuccess = true;
    set({ isGenerationLoading: true });
    try {
      // Range date
      const endDate = new Date();
      const startDate = giveStartDateFromPeriod(period ?? WEIGHT_PERIOD.PERIOD_90_DAYS);

      // Convertion of floodlight priorities form value
      const priorities = convertToFloodlightPriorities(
        conversionWeightValues?.floodlightPriorities ?? []
      );

      // Initialize data
      let data: BiddingScriptGenerationData = {
        clientId,
        insertionOrderIds,
        startDate: startDate,
        endDate,
        conversionWeightData: {
          floodLightIds: conversionWeightValues?.floodlightIds,
          kValue: conversionWeightValues?.kValue ?? 0,
          isFloodlightPriority: conversionWeightValues?.isFloodlightPriority ?? false,
          floodlightPriorities: priorities,
        },
      };

      // Initialize domain scoring data if option is activated
      if (domainScoringValues.isDomainScoringActivated) {
        data = {
          ...data,
          domainScoringData: {
            goal: domainScoringValues?.goal ?? '',
            maxCoef: domainScoringValues?.maxCoefficient ?? 0,
            aggressivity: domainScoringValues?.aggressivity ?? 0,
          },
        };
      }

      // Generate script
      const script = await algorithmRepository.generateCustomBiddingAlgorithmScript(
        data,
        domainScoringValues.isDomainScoringActivated
      );

      if (script) {
        set({ previewScript: script });
      } else {
        set({
          snackBarProps: {
            messages: ['Generation returns an empty script'],
            severity: 'warning',
            visible: true,
          },
        });
        generationInSuccess = false;
      }
    } catch (e) {
      set({
        snackBarProps: {
          messages: ['Error generating conversion weight script'],
          severity: 'error',
          visible: true,
        },
      });
      generationInSuccess = false;
    } finally {
      set({ isGenerationLoading: false });
    }
    return generationInSuccess;
  },
  setFloodlightAutoSelectValues: (floodlights: Floodlight[]) => {
    // Remove duplicated floodlights
    const cleanedFloodlights = floodlights.reduce((acc: FloodlightValue[], floodlight) => {
      const existingItem = acc.find((i) => i.id === floodlight.id && i.name === floodlight.name);
      if (!existingItem) {
        acc.push({ id: floodlight.id, name: floodlight.name });
      }
      return acc;
    }, []);
    set({ floodlightAutoSelectValues: cleanedFloodlights });
  },
  setFloodlightIdsValues: (floodlightIdsValues: string[]) => {
    set({ floodlightIdsValues });
    set({ isFloodlightPriorityActive: !!floodlightIdsValues.length });
  },
  setFormDefaultValues: (formDefaultValues: ConversionWeightFormValues) =>
    set({ formDefaultValues }),
  resetValues: () =>
    set({
      formDefaultValues: CONVERSION_WEIGHT_FORM_DEFAULT_VALUES,
      floodlightIdsValues: [],
      isFloodlightPriorityActive: false,
    }),
}));
