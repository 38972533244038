import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { bidAdjustmentFieldStyle } from './styles';
import { NumberStep } from '../components/NumberStep';
import { ChangeEvent, useState } from 'react';
import {
  FormProps,
  USER_DEVICES,
  UserDeviceFormValues,
} from '../../../../../../core/meta/model/bid-form.ts';

export const UserDeviceForm: React.FC<FormProps<UserDeviceFormValues>> = ({
  onChange,
  initialValues,
  disabled,
  excludeValues,
}) => {
  const [genderFormValues, setGenderFormValues] = useState<UserDeviceFormValues>(
    initialValues || { userDevice: '', value: 1 }
  );
  const handleChange = (e: SelectChangeEvent<string> | ChangeEvent<HTMLInputElement>) => {
    const newValue =
      e.target instanceof HTMLInputElement ? parseFloat(e.target.value) : e.target.value;
    const newFormValue = { ...genderFormValues, [e.target.name]: newValue };
    setGenderFormValues(newFormValue);
    onChange(newFormValue);
  };

  const filteredUserDevice = USER_DEVICES.filter((device) => !excludeValues?.includes(device));

  return (
    <>
      <FormControl sx={bidAdjustmentFieldStyle}>
        <InputLabel>user Device</InputLabel>
        <Select
          name="userDevice"
          label="user Device"
          onChange={handleChange}
          value={genderFormValues.userDevice}
        >
          {filteredUserDevice.map((device) => (
            <MenuItem key={device} value={device}>
              {device.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <NumberStep
        disabled={disabled}
        name="value"
        value={genderFormValues.value}
        onChange={handleChange}
      ></NumberStep>
    </>
  );
};
