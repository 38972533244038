import { inject, injectable } from 'inversify';
import INJECTION_TOKENS from '../../../../core/generic/ioc/injection-tokens.ts';
import type HttpService from '../../../../core/generic/http-service.port.ts';
import { LocationPort } from '../../../../core/meta/port/location.port.ts';
import { City, Country, Region } from '../../../../core/meta/model/location.ts';

@injectable()
export class LocationRepository implements LocationPort {
  url: string = '/v1/platforms/meta';
  constructor(
    @inject(INJECTION_TOKENS.META_API_HTTP_SERVICE) private readonly httpService: HttpService
  ) {}
  async getCountries(): Promise<Country[]> {
    return this.httpService.get<Country[]>(`${this.url}/geodata/countries`);
  }
  async getCities(cityToSearch: string): Promise<City[]> {
    return this.httpService.get<City[]>(`${this.url}/geodata/cities?name=${cityToSearch}`);
  }
  async getCitiesByIds(cityIdsToSearch: string[]): Promise<City[]> {
    return this.httpService.get<City[]>(`${this.url}/geodata/cities?ids=${cityIdsToSearch}`);
  }
  async getRegions(): Promise<Region[]> {
    return this.httpService.get<Region[]>(`${this.url}/geodata/regions`);
  }
}
