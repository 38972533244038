import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useDV360Store } from '../../../../core/dv360/store/dv360-store';

export const DV360Layout = () => {
  const setClients = useDV360Store((state) => state.setClients);

  useEffect(() => {
    setClients();
  }, [setClients]);

  return <Outlet />;
};
