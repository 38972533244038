import { SelectChangeEvent, Checkbox, TextField, Autocomplete } from '@mui/material';
import { ChangeEvent, SyntheticEvent, useState } from 'react';
import { FormProps, LocaleFormValues } from '../../../../../../../core/meta/model/bid-form.ts';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { LocaleData } from './locale-data.ts';
import { NumberStep } from '../../components/NumberStep.tsx';

type LocaleFormValueToSend = {
  values: string[] | null;
};

export type Locale = {
  key: number;
  name: string;
};

export const LocaleForm: React.FC<FormProps<LocaleFormValues>> = ({
  onChange,
  initialValues,
  disabled,
  excludeValues,
}) => {
  const [localeFormValues, setLocalFormValues] = useState<LocaleFormValues>(
    initialValues || { locales: [], value: 1 }
  );

  const handleChange = (
    event:
      | SelectChangeEvent<string>
      | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | LocaleFormValueToSend
  ) => {
    let newFormValue: LocaleFormValues;
    if ('values' in event) {
      newFormValue = {
        value: localeFormValues.value,
        locales: event.values,
      } as LocaleFormValues;
    } else {
      const newValue =
        event.target.name === 'value' ? parseFloat(event.target.value) : event.target.value;
      newFormValue = { ...localeFormValues, [event.target.name]: newValue };
    }
    setLocalFormValues(newFormValue);
    onChange(newFormValue);
  };

  const handleAutocompleteChange = (
    _event: SyntheticEvent<Element, Event>,
    locales: Locale[] | null
  ) => {
    const valueToSend: LocaleFormValueToSend = {
      values: locales?.map((locale) => String(locale.key)) || null,
    };
    if (valueToSend) handleChange(valueToSend);
  };

  const filteredLocaleData = LocaleData.filter(
    (locale) => !excludeValues?.includes(String(locale.key))
  );

  const initialAutoCompleteValues = filteredLocaleData.filter((locale) => {
    return localeFormValues.locales.find((data) => Number(data) === locale.key);
  });

  return (
    <>
      <Autocomplete
        sx={{ width: '100%' }}
        id="custom-audience"
        options={filteredLocaleData.sort((a, b) => a.name.localeCompare(b.name))}
        defaultValue={initialAutoCompleteValues}
        limitTags={1}
        multiple
        disableCloseOnSelect
        isOptionEqualToValue={(option, value) => option.key === value.key}
        getOptionLabel={(option) => option.name}
        onChange={handleAutocompleteChange}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.key}>
            <Checkbox
              name={option.name}
              key={option.key}
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </li>
        )}
        renderInput={(params) => <TextField {...params} label="Locale" />}
      />
      <NumberStep
        disabled={disabled}
        name="value"
        value={localeFormValues.value}
        onChange={handleChange}
      ></NumberStep>
    </>
  );
};
